<template>
    <section class="section section_type_list is_new-design"
             :class="{'section_status_open': showMainList}"
    >

        <SectionUserHead v-if="hasList" />

        <!--- Section body --->
        <keep-alive>
            <component :is="list" />
        </keep-alive>
    </section>
</template>

<script>
    import SectionUserHead from "@/components/app/SectionUserHead.vue";
    import emptyList from "@/components/app/emptyList.vue";
    import {mapGetters} from "vuex";

    export default {
        name: "SectionList",
        props: [],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "showMainList",
            ]),
            list() {
                return (this.$route.meta.list || 'emptyList')
            },
            hasList(){
                return !!this.$route.meta.list
            }
        },
        components: {
            SectionUserHead,
            emptyList,
            'UnitsList':  () => import('@/components/units/UnitsList.vue'),
            'TracksList':  () => import('@/components/tracks/TracksList.vue'),
            'ReportsList':  () => import('@/components/reports/ReportsList.vue'),
            'GeoitemsList':  () => import('@/components/geoitems/GeoitemsList.vue'),
            'NotificationsList':  () => import('@/components/notifications/NotificationsList.vue'),
            'RoutesList':  () => import('@/components/routes/RoutesList.vue'),
            'PersonnelList':  () => import('@/components/personnel/PersonnelList.vue'),
            'PassengersList':  () => import('@/components/passengers/PassengersList.vue'),
            'FuelList':  () => import('@/components/fuel/FuelList.vue'),
            'MaintenanceList':  () => import('@/components/maintenance/MaintenanceList.vue'),
            'AlarmsList':  () => import('@/components/alarms/AlarmsList.vue'),
            'VideoList':  () => import('@/components/video/VideoList.vue'),
            'FuelCardsList':  () => import('@/components/fuel_cards/FuelCardsList.vue'),

            //lite
            'UnitsList_lite':  () => import('@/components/units/UnitsList.lite.vue'),
            // TracksList_lite,
            // GeoitemsList_lite,
            // NotificationsEventsList,
            'TracksList_lite':  () => import('@/components/tracks/TracksList.lite.vue'),
            'GeoitemsList_lite':  () => import('@/components/geoitems/GeoitemsList.lite.vue'),
            'NotificationsEventsList':  () => import('@/components/notifications_events_history/NotificationsEventsList.vue'),
            // ActivationsList,
        },
    }
</script>

<style scoped>

</style>