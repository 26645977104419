export default {

    state: {
        index: [],
        trips: [],
        trips_filter: {unitId: '', date: ''}
    },
    actions: {
        async fetchTrips4Date({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            let trips = getters.getTrips.find(t => {
                return t.unit_id == args.unitId && t.date == args.date
            })
            if(trips){
                return
            }
            this.$api.trips.get4Date(args.unitId, args.date, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTrips', response.data)
                        commit('setIndex', {...args, count: response.data.length})
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchTrips4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let trips = getters.getTrips.find(t => {
                return t.unit_id == args.unitId && t.time_start.utc==args.from && t.time_end.utc==args.to
            })
            if(trips){
                return
            }
            this.$api.trips.get4Period(args.unitId, args.from, args.to, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTrips', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setIndex(state, params) {
            state.index.push({...params})
        },
        storeTrips(state, trips) {
            // state.trips = trips
            trips.forEach(t => {
                let trip = state.trips.find(st => {
                    return st.unit_id == t.unit_id
                        && st.time_start.utc == t.time_start.utc
                        && st.time_end.utc == t.time_end.utc
                })
                if(!trip) state.trips.push(t)
            })
        },
        clearTrips(state){
            state.trips = [];
            state.index = [];
        },
        setTripsFilter(state, filter) {
            state.trips_filter = filter
        },
    },
    getters: {
        getTripsFilter(state){
            return state.trips_filter
        },
        getTripsIndex(state){
            return state.index
        },
        getTripsIndexFiltered(state){
            let filter = state.trips_filter
            let index = state.index
            if(filter.date > '') {
                index = index.filter(i => {
                    return i.date == filter.date
                })
            }
            if(filter.unitId > '') {
                index = index.filter(i => {
                    return i.unitId == filter.unitId
                })
            }
            return !!(index.length)
        },
        getTrips(state){
            return state.trips
        },
        getTripsFiltered(state){
            let filter = state.trips_filter
            let trips = state.trips
            if(filter.date > '') {
                trips = trips.filter(t => {
                    return t.time_start.date == filter.date
                        //&& t.time_end.date == filter.date
                })
            }
            if(filter.unitId > '') {
                trips = trips.filter(t => {
                    return t.unit_id == filter.unitId
                })
            }
            return trips
        },
    }
}
