import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import map from './modules/map'
import security from './modules/security'

import users from './modules/users'
import units from './modules/units'
import units_keys from './modules/units_keys'
import units_keys_orders from './modules/units_keys_orders'
import geozones from './modules/geozones'
import geopoints from './modules/geopoints'
import notifications from './modules/notifications'
import notifications_history from './modules/notifications_history'

import trips from './modules/trips'
import parkings from './modules/parkings'
import products from "./modules/products";
import units_hwtypes from "./modules/units_hwtypes";
import events from './modules/events'
import stops from './modules/stops'
import tracks from './modules/tracks'
import weather from "./modules/weather";

// import geoitems from './modules/geoitems'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    actions: {
    },
    mutations: {
    },
    getters: {
    },
    modules: {
        app,
        map,
        security,
        users,
        units,
        units_keys,
        units_keys_orders,
        //geoitems,
        geozones,
        geopoints,
        notifications,
        notifications_history,

        trips,
        parkings,
        products,
        units_hwtypes,
        events,
        stops,
        tracks,
        weather,
    }
})
