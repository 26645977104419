// import Vue from 'vue'
var moment = require('moment-timezone');
import {calcTimeToday, calcDateToday} from "@/lib/lib";

//const userExpand = ['units_of_measure','settings','notifications_settings','is_admin','is_super_admin','parents','dealer','dealer_apikeys','hide_menu','dealers_hide_menu','head__logo','dealer_login_logo']
const userExpand_ = 'units_of_measure,settings,notifications_settings,is_admin,is_super_admin,parents,dealer,dealer_apikeys,hide_menu,dealers_hide_menu,head__logo,dealer_login_logo'

export default {
    state: {
        langs: [
            {id:'en',name:'English'},
            {id:'es',name:'Español'},
            {id:'pt',name:'Português'},
        ],
        menus: [
            // {id: "units",               url: "/units",              title: ("menu.Units")},
            // {id: "tracks",              url: "/tracks",             title: ("menu.Tracks")},
            // {id: "geoitems",            url: "/geoitems",           title: ("menu.Geoitems")},
            // {id: "notificationsevents", url: "/notificationsevents", title: ("menu.Notice")},
            // {id: "dealer",              url: "/dealer",             title: ("menu.Dealer")},
            // {id: "help",                url: "/help",               title: ('menu.Help')},
            // {id: "test",                url: "/test",               title: ('menu.TestPage')},

            //menus_full
            {id: "units",        url: "/units",         title: ("menu.Prometheus"), icon: "prometheus"},
            {id: "tracks",       url: "/tracks",        title: ("menu.Tracks")},
            {id: "reports",      url: "/reports",       title: ("menu.Reports")},
            {id: "geoitems",     url: "/geoitems",      title: ("menu.Geoitems")},
            {id: "notifications", url: "/notifications", title: ("menu.Notice")},
            // {id: "routes",       url: "/routes",        title: ("menu.Routes")},
            // {id: "personnel",    url: "/personnel",     title: ("menu.Personnel")},
            // {id: "passengers",   url: "/passengers",    title: ("menu.Passengers")},
            // {id: "fuel",         url: "/fuel",          title: ("menu.Fuel")},
            // {id: "maintenance",  url: "/maintenance",   title: ("menu.Maintenance")},
            // {id: "alarms",       url: "/alarms",        title: ("menu.Alarms")},
            // {id: "video",        url: "/video",         title: ("menu.Video")},
            // {id: "fuel_cards",   url: "/fuel_cards",    title: ("menu.Fuel cards")},
            // {id: "gauges",       url: "/gauges",        title: ("menu.Gauges")},
        ],
        popup: {show: false},
        timeFormats: [
            {php_format: 'HH:mm:ss',   name: 'HH:MM',   moment_js:'HH:mm'},
            {php_format: 'HH.mm.ss',   name: 'HH.MM',   moment_js:'HH.mm'},
            {php_format: 'hh:mm:ss a', name: 'HH:MM A', moment_js:'hh:mm A'},
            {php_format: 'a hh:mm:ss', name: 'A HH:MM', moment_js:'A hh:mm'},
        ],
        dateFormats: [
            // altFormat - only flatpickr - https://flatpickr.js.org/options/
            {php_format: 'MM/dd/yyyy', name: 'MM/DD/YYYY', moment_js:'MM/DD/YYYY', short: 'MMM D' , altFormat: 'M/j/Y'},
            //{php_format: 'MM-dd-yyyy', name: 'MM-DD-YYYY', moment_js:'MM-DD-YYYY', short: 'MMM D', altFormat: 'M-j-Y'},
            {php_format: 'MM.dd.yyyy', name: 'MM.DD.YYYY', moment_js:'MM.DD.YYYY', short: 'MMM D', altFormat: 'M.j.Y'},
            //{php_format: 'dd-MM-yyyy', name: 'DD-MM-YYYY', moment_js:'DD-MM-YYYY', short: 'D MMM', altFormat: 'j-M-Y'},
            {php_format: 'dd/MM/yyyy', name: 'DD/MM/YYYY', moment_js:'DD/MM/YYYY', short: 'D MMM', altFormat: 'j/M/Y'},
            {php_format: 'dd.MM.yyyy', name: 'DD.MM.YYYY', moment_js:'DD.MM.YYYY', short: 'D MMM', altFormat: 'j.M.Y'},
            //{php_format: 'dd MM yyyy', name: 'DD MM YYYY', moment_js:'DD MM YYYY', short: 'D MMM', altFormat: 'M j Y'},
            {php_format: 'yyyy-MM-dd', name: 'YYYY-MM-DD', moment_js:'YYYY-MM-DD', short: 'D MMM', altFormat: 'Y-M-j'},
            {php_format: 'yyyy/MM/dd', name: 'YYYY/MM/DD', moment_js:'YYYY/MM/DD', short: 'D MMM', altFormat: 'Y/M/j'},
        ],
        temperatureUnitsList:[
            {id: 'C', name: 'C'},
            {id: 'F', name: 'F'},
            //{id: 'K', name: 'K'},
        ],
        app: {
            jsTZ: new Date().getTimezoneOffset()*60,
            today: Math.floor(Date.now() / 1000),
            date_today: new Date().toISOString().split('T')[0],
            list30Days: [],
            user: {},
            settingsValues: null,
            sectionInfo: null,
            sectionWidget: null,
            groupsFavorite: [],
            groupsShowUnits: [],
            FilterSearchLists: {
                Units: {
                    signals: [
                        {id: 'on', name: ('ON line'), translate: ('Units.signals.ON line'), icon: 'common__car'},
                        {id: 'off', name: ('OFF line'), translate: ('Units.signals.OFF line'), icon: 'common__car'},
                    ],
                    movements: [
                        {id: 'moving', name: ('Moving'), translate: ('Units.movements.Moving'), icon: 'common__car'},
                        {id: 'stopped', name: ('Stopped'), translate: ('Units.movements.Stopped'), icon: 'common__car'},
                        {id: 'ignition', name: ('Ignition on'), translate: ('Units.movements.Ignition on'), icon: 'common__car'},
                    ],
                    car_icons: [
                        {id: 'map__car_type_sedan', name: ('Sedan'), translate: ('Units.icons.Sedan'), group:'Dynamic', isColored: true},
                        {id: 'map__car_type_pickup', name: ('Pickup'), translate: ('Units.icons.Pickup'), group:'Dynamic', isColored: true},
                        {id: 'map__car_type_truck', name: ('Truck'), translate: ('Units.icons.Truck'), group:'Dynamic', isColored: true},
                        {id: 'map__car_type_motorbike', name: ('Motorbike'), translate: ('Units.icons.Motorbike'), group:'Dynamic', isColored: true},
                        {id: 'map__car_type_taxi', name: ('Taxi'), translate: ('Units.icons.Taxi'), group:'Static', isColored: false},
                        {id: 'map__car_type_police', name: ('Police'), translate: ('Units.icons.Police'), group:'Static', isColored: false},
                        {id: 'map__car_type_ambulance', name: ('Ambulance'), translate: ('Units.icons.Ambulance'), group:'Static', isColored: false},
                    ],
                    types: [
                        {id: 'complex', name: ('Complex'), translate: ('Units.types.Complex'), icon: 'complex'},
                        {id: 'boat', name: ('Boat'), translate: ('Units.types.Boat'), icon: 'boat'},
                        {id: 'bus', name: ('Bus'), translate: ('Units.types.Bus'), icon: 'bus'},
                        {id: 'car', name: ('Car'), translate: ('Units.types.Car'), icon: 'car'},
                        {id: 'dry trailer', name: ('Dry Trailer'), translate: ('Units.types.Dry Trailer'), icon: 'dry_trailer'},
                        {id: 'engine', name: ('Engine'), translate: ('Units.types.Engine'), icon: 'engine'},
                        {id: 'fuel tank', name: ('Fuel Tank'), translate: ('Units.types.Fuel Tank'), icon: 'fuel_tank'},
                        {id: 'fuel tanker', name: ('Fuel Tanker'), translate: ('Units.types.Fuel Tanker'), icon: 'fuel_tanker'},
                        {id: 'generator', name: ('Generator'), translate: ('Units.types.Generator'), icon: 'generator'},
                        {id: 'jet ski', name: ('Jet Ski'), translate: ('Units.types.Jet Ski'), icon: 'jet_ski'},
                        {id: 'motorcycle', name: ('Motorcycle'), translate: ('Units.types.Motorcycle'), icon: 'motorcycle'},
                        {id: 'plant machine', name: ('Plant Machine'), translate: ('Units.types.Plant Machine'), icon: 'plant_machine'},
                        {id: 'pump', name: ('Pump'), translate: ('Units.types.Pump'), icon: 'pump'},
                        {id: 'rv', name: ('RV'), translate: ('Units.types.RV'), icon: 'rv'},
                        {id: 'reefer gt', name: ('Reefer GT'), translate: ('Units.types.Reefer GT'), icon: 'reefer_gt'},
                        {id: 'reefer trailer', name: ('Reefer Trailer'), translate: ('Units.types.Reefer Trailer'), icon: 'reefer_trailer'},
                        {id: 'stationary unit', name: ('Stationary Unit'), translate: ('Units.types.Stationary Unit'), icon: 'stationary_unit'},
                        {id: 'tank', name: ('Tank'), translate: ('Units.types.Tank'), icon: 'tank'},
                        {id: 'tractor', name: ('Tractor'), translate: ('Units.types.Tractor'), icon: 'tractor'},
                        {id: 'train', name: ('Train'), translate: ('Units.types.Train'), icon: 'train'},
                        {id: 'truck', name: ('Truck'), translate: ('Units.types.Truck'), icon: 'truck'},
                        {id: 'van', name: ('Van'), translate: ('Units.types.Van'), icon: 'van'},
                        {id: 'water tanker', name: ('Water Tanker'), translate: ('Units.types.Water Tanker'), icon: 'water_tanker'},
                    ],
                },
                Events: {
                    types: [
                        {id: 'lost connection', type: 'event', name: ('Lost connection'), translate: ('Events.types.Lost connection'), icon: 'notifications__lost-connection'},
                        {id: 'low battery', type: 'event', name: ('Low battery'), translate: ('Events.types.Low battery'), icon: 'notifications__low-battery'},
                        {id: 'max speed', type: 'event', name: ('Max speed'), translate: ('Events.types.Max speed'), icon: 'notifications__max-speed'},
                        {id: 'input', type: 'event', name: ('Input alert'), translate: ('Events.types.Input alert'), icon: 'notifications__input'},
                    ],
                },
                NotificationsEvents: {
                    types: [
                        // {id: '1', type: 'notification-event', name: ('Parameters control'), translate: ('NotifEvents.types.Parameters control'), icon: 'notifications__parameter_control'},
                        {id: '2', type: 'notification-event', name: ('Entry into geosite'), translate: ('NotifEvents.types.Entry into geosite'), icon: 'notifications__geozone_entrance_control'},
                        {id: '3', type: 'notification-event', name: ('Exit from geosite'), translate: ('NotifEvents.types.Exit from geosite'), icon: 'notifications__geozone_exit_control'},
                        // {id: '4', type: 'notification-event', name: ('Approaching geopoint'), translate: ('NotifEvents.types.Approaching geopoint'), icon: 'notifications__approaching_geopoint'},
                        // {id: '5', type: 'notification-event', name: ('Distance geopoint'), translate: ('NotifEvents.types.Distance geopoint'), icon: 'notifications__distance_geopoint'},
                        // {id: '6', type: 'notification-event', name: ('Lost connection'), translate: ('NotifEvents.types.Lost connection'), icon: 'notifications__lost-connection'},
                        // {id: '7', type: 'notification-event', name: ('Sensors control'), translate: ('NotifEvents.types.Sensors control'), icon: 'notifications__sensor_control'},
                        // {id: '9', type: 'notification-event', name: ('Approaching object'), translate: ('NotifEvents.types.Approaching object'), icon: 'notifications__approaching_object'},
                        // {id: '10', type: 'notification-event', name: ('Overspeeding by GIS'), translate: ('NotifEvents.types.Overspeeding by GIS'), icon: 'notifications__overspeeding_gis'},
                        // {id: '11', type: 'notification-event', name: ('Maintenance'), translate: ('NotifEvents.types.Maintenance'), icon: 'notifications__maintenance'},
                        // {id: '12', type: 'notification-event', name: ('Fuel control'), translate: ('NotifEvents.types.Fuel control'), icon: 'notifications__fuel_control'},
                        // {id: '17', type: 'notification-event', name: ('Towing'), translate: ('NotifEvents.types.Towing'), icon: 'notifications__towing'},

                        //{id: '8', type: 'notification-event', name: ('Track control'), translate: ('NotifEvents.types.Track control'), icon: 'notifications__'},
                        //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__condition_control'},//???
                        //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__movement'},
                        //{id: '14', type: 'notification-event', name: ('Condition control'), translate: ('NotifEvents.types.Condition control'), icon: 'notifications__parking'},
                        //'attribute' => 'type_condition', 'images' => ['movement' => 'movement','parking' => 'parking',],

                        //{id: '15', type: 'notification-event', name: ('Control of presence inside of geozone'), translate: ('NotifEvents.types.Control of presence inside of geozone'), icon: 'notifications__'},
                        //{id: '16', type: 'notification-event', name: ('Control of absence inside of geozone'), translate: ('NotifEvents.types.Control of absence inside of geozone'), icon: 'notifications__'},

                    ],
                }
            },

            backgrounds: [],
            unitsicons: [],
            unitstypes: [],
            unitsReeferTypes: [
                {
                    'reefer_title': 'Thermo King',
                    'reefer_type': 'thermo_king',
                    'reefer__icon': 'reefer__king',
                },
                {
                    'reefer_title':'Carrier',
                    'reefer_type':'carrier',
                    'reefer__icon': 'reefer__carrier',
                }
            ],

            showUserMenu: false,
            showMainList: true,
            showFilterDropdownMenu: false,
        },
        mobileMenuOpen: false
    },
    actions: {
        setPopup({commit} , popupInfo){
          commit('setPopup', popupInfo)
        },
        clearAppCache({commit ,getters ,dispatch }){
            commit('clearUnitsMaxSpeed')
            commit('clearUnitsLmsgsSpeed')
            dispatch('fetchUnits')
            dispatch('fetchUnitsLmsgs', {force: true})

            commit('clearEvents')
            commit('clearNotificationsEvents')
            commit('clearTracks')
            commit('clearTrips')
            commit('clearParkings')
            commit('clearStops')

            if(getters.getShowUnitTrack.unitId > 0 && getters.getShowUnitTrack.date > '') {
                let filter = getters.getShowUnitTrack
                dispatch("fetchStops4Date", filter )
                dispatch("fetchEvents4Date",  filter)
                dispatch("fetchParkings4Date", filter )
                dispatch("fetchTrips4Date", filter )
            }
            if(getters.getNotificationsEventsFilter.date > '') {
                let filter = getters.getNotificationsEventsFilter
                dispatch("fetchEvents4Date", filter)
                dispatch("fetchNotificationsEvents4Date", filter)
            }
        },
        fetchAppProps({ getters, dispatch }) {
            if(!getters.apiToken) {
                return
            }
            dispatch('fetchAppUser', {})
            dispatch('fetchUserSettings', {})
            dispatch('fetchUnitsIcons', {})
            dispatch('fetchUnitsHwTypes', {})
            dispatch('fetchUnitsTypes', {})
            dispatch('fetchProducts', {})
            //if(process.env.VUE_APP_PACKAGE !== "lite")
            {
                dispatch('fetchUsers', {})
                dispatch('fetchUnits', {})
                dispatch('fetchUnitsLmsgs', {})
                dispatch('fetchUnitsKeysFreeStatistics', {})

                dispatch('fetchGeopoints', {})
                dispatch('fetchGeozones', {})
            }
            if (process.env.VUE_APP_PACKAGE !== "lite") {
                dispatch('fetchUnitsGroups', {})
                dispatch('fetchGeopointsGroups', {})
                dispatch('fetchGeozonesGroups', {})
            }
        },
        fetchAppUser({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: userExpand_}
            this.$api.user.fetch(params)
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateAppUser', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },

        async saveAppUser({ commit }, user) {
            return new Promise((resolve, reject) => {
                const params = {expand: userExpand_}
                this.$api.user.update(user, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateAppUser', response.data)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
            })
        },

        async saveAppUserSettings({ commit, state }, settings) {
            return new Promise((resolve, reject) => {
                let user = { id: state.app.user.id, settings}
                const params = {expand: userExpand_}
                this.$api.user.update(user, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateAppUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async saveAppUserNotificationsSettings({ commit, state }, notifications_settings) {
            return new Promise((resolve, reject) => {
                let user = { id: state.app.user.id, notifications_settings}
                const params = {expand: userExpand_}
                this.$api.user.update(user, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateAppUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        sendUserRequest({ /*commit,*/ state }, request) {
            return new Promise((resolve, reject) => {
                let user = { ...request, id: state.app.user.id}
                const params = {expand: userExpand_}
                this.$api.user.request(user, params)
                    .then((response) => {
                        //console.error(response);
                        if(response.status < 400 && !response.data.error) {
                            //commit('updateAppUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        fetchUserSettings({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            this.$api.user.getSettings()
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateUserSettings', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },

        fetchBackgrounds({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            this.$api.customize.getBackgrounds()
                .then((response) => {
                    commit('updateBackgrounds', response.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchUnitsIcons({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            this.$api.units.getIcons()
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateUnitsIcons', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        fetchUnitsTypes({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            this.$api.units.getTypes()
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateUnitsTypes', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        setGroupFavorite({ commit }, args) {
            commit('setGroupFavorite', args)
        },
        setAllGroupsShowUnits({ commit, rootState }, args) {
            args.groupIds = (!args.showUnits) ? [] : rootState.units.unitsGroups_index
            commit('setAllGroupsShowUnits', args)
        },
        setGroupShowUnits({ commit }, args) {
            commit('setGroupShowUnits', args)
        },

        setSectionInfo({ commit }, sectionInfo) {
            commit('setSectionInfo', sectionInfo)
        },
        setSectionWidget({ commit }, sectionWidget) {
            commit('setSectionWidget', sectionWidget)
        },

    },
    mutations: {
        setPopup(state , popupInfo){
            if(!popupInfo) popupInfo = {show:false}
            else popupInfo.show = true
            state.popup = popupInfo
        },
        updateUserSettings(state, settingsValues) {
            state.app.settingsValues = settingsValues
        },
        calcDateToday(state) {
            if (!state.app.user) return
            state.app.today = calcTimeToday(state.app.user.time_zone, state.app.user.dst)
            state.app.date_today = calcDateToday(state.app.user.time_zone, state.app.user.dst)
        },
        updateAppUser(state, user) {
            if(!user) return
            user.time_zone = user.time_zone*1
            user.dst = !!user.dst

            let timezone = state.app.user.time_zone;
            let dst = state.app.user.dst;
            state.app.user = user
            if ((user.time_zone !== timezone) || (user.dts !== dst))
            {
                state.app.today = calcTimeToday(user.time_zone, user.dst)
                state.app.date_today = calcDateToday(user.time_zone, user.dst)
            }
        },
        updateBackgrounds(state, backgrounds) {
            state.app.backgrounds = backgrounds
        },
        updateUnitsIcons(state, unitsicons) {
            state.app.unitsicons = unitsicons
        },
        updateUnitsTypes(state, unitstypes) {
            state.app.unitstypes = unitstypes
        },
        setGroupFavorite(state, args) {
            if(!args.isFavorite){
                state.app.groupsFavorite = state.app.groupsFavorite.filter(id => {
                    return id !== args.groupId
                })
            }else
            if(/*args.isFavorite &&*/ !state.app.groupsFavorite.includes(args.groupId)) {
                state.app.groupsFavorite.push(args.groupId)
            }
        },
        setGroupShowUnits(state, args) {
            if(!args.showUnits){
                state.app.groupsShowUnits = state.app.groupsShowUnits.filter(id => {
                    return id !== args.groupId
                })
            }else
            if(/*args.showOnMap &&*/ !state.app.groupsShowUnits.includes(args.groupId)) {
                state.app.groupsShowUnits.push(args.groupId)
            }
        },
        setAllGroupsShowUnits(state, args) {
            state.app.groupsShowUnits = args.groupIds
        },

        setSectionInfo(state, sectionInfo) {
            state.app.sectionInfo = sectionInfo
        },
        setSectionWidget(state, sectionWidget) {
            state.app.sectionWidget = sectionWidget
        },
        showMainList(state) {
            state.app.showMainList = true
        },
        hideMainList(state) {
            state.app.showMainList = false
        },
        switchMainList(state) {
            state.app.showMainList = !state.app.showMainList
        },
        setShowMainList(state, show) {
            state.app.showMainList = !(!show)
        },
        setShowUserMenu(state, show) {
            state.app.showUserMenu = !(!show)
        },
        switchShowUserMenu(state) {
            state.app.showUserMenu = !state.app.showUserMenu
        },
        setShowFilterDropdownMenu(state, show) {
            state.app.showFilterDropdownMenu = !(!show)
        },
        switchShowFilterDropdownMenu(state) {
            state.app.showFilterDropdownMenu = !state.app.showFilterDropdownMenu
        },
        setMobileMenuOpen(state , control){
            state.mobileMenuOpen = control
        }
    },
    getters: {
        getUnitsReeferTypes(state){
            return state.app.unitsReeferTypes
        },
        getTimeFormats(state){
            return state.timeFormats
        },
        getTimeFormat(state){
            let settings = state.app.user.settings || {}
            let format = state.timeFormats.find( f => f.php_format == settings.timeFormat) || {}
            format = format.moment_js  || 'HH:mm'
            return format
        },
        getDateFormats(state){
            return state.dateFormats
        },
        getDateFormat(state){
            let settings = state.app.user.settings || {}
            let format = state.dateFormats.find( f => f.php_format == settings.dateFormat) || {}
            format = format.moment_js  || 'YYYY-MM-DD'
            return format
        },
        getDateFormatAll(state){
            let settings = state.app.user.settings || {}
            let format = state.dateFormats.find( f => f.php_format == settings.dateFormat) || {}
            return format
        },
        isDealer(state){
            return state.app.user.is_admin && state.app.user.parents && state.app.user.parents.length<5
        },
        isLite(){
            return (process.env.VUE_APP_PACKAGE === "lite")
        },
        getHideMenus(state, getters){
            if(!getters.getAppUserId) return []
            return [
                ...(getters.getAppUser.hide_menu || []),
                ...(getters.getAppUser.dealers_hide_menu || []),
            ]
        },
        getMenus(state, getters){
            if(!getters.getAppUserId) return []
            let hide_menu = getters.getHideMenus
            let menus = state.menus.filter(m => !hide_menu.includes(''+m.id))
            if(!getters.isDealer){
                //menus = menus.filter(m => !m.forDealerOnly)
            }
            return menus
        },
        getAppMenus(_, getters){
            return getters.getMenus
        },
        getPopup(state){
            return  state.popup
        },
        getLangs(state) {
            return state.langs || []
        },
        getAppUserSettings(state) {
            return state.app.user.settings || {}
        },
        getAppUserUnits(state) {
            let UnitsOfMeasure = (state.app.user.settings || {}).UnitsOfMeasure || {}
            return {
                distance: UnitsOfMeasure.distanceUnits ||  "km",
                speed: UnitsOfMeasure.speedUnits ||  "kph",
                altitude: UnitsOfMeasure.altitudeUnits ||  "m",
                volume: UnitsOfMeasure.volumeUnits ||  "l",
                fuelEconomy: UnitsOfMeasure.fuelEconomyUnits ||  "lPer100km",
                temperature: UnitsOfMeasure.temperatureUnits ||  "C",
                pressed: UnitsOfMeasure.pressedUnits ||  "mbar",
                precip: UnitsOfMeasure.precipUnits ||  "mm",
                humidity: UnitsOfMeasure.humidityUnits ||  "%",
                percentage: UnitsOfMeasure.percentageUnits ||  "%",
                electrical: UnitsOfMeasure.electricalUnits ||  "V",
            }
        },
        getAppUserUnitsValue(_,getters){
            return Object.values(getters.getAppUserUnits)
        },
        getSettingsValues(state) {
            return state.app.settingsValues
        },
        getAppUser(state) {
            return state.app.user
        },
        getAppUserId(state) {
            return state.app.user.id || 0
        },
        isSuperAdmin(state) {
            return state.app.user.is_super_admin
        },
        getUserStorageKey(state, getters){
            let userId = getters.getAppUserId
            return process.env.VUE_APP_PRODUCT+'.user.'+userId
        },

        isDST(state){
            let utc =  moment().unix() - (state.app.user.time_zone)
            return  moment(utc*1000).tz("Europe/London").isDST();
        },
        getUserTimeZone(state, getters){
            return state.app.user.time_zone*1 + (state.app.user.dst && getters.isDST ? 3600 : 0);
        },
        getTimeToday(state){//, getters
            return state.app.today
            // let tz = getters.getUserTimeZone/3600
            // return moment().utcOffset(tz).startOf('day').unix();
        },
        getDateToday(state){//, getters
            return state.app.date_today
            // let tz = getters.getUserTimeZone/3600
            // return moment().utcOffset(tz).startOf('day').format('YYYY-MM-DD')
            // return moment(state.app.today*1000).utcOffset(tz).format('YYYY-MM-DD')
        },
        getAppUserList30Days(state, getters){
            let dates = [];
            if (!state.app.user) return dates
            let tz = getters.getUserTimeZone/3600
            let today = getters.getTimeToday
            let seconds = today + 12 * 3600
            while (dates.length<30) {
                dates.push({
                    //date: new Date(seconds * 1000).toISOString().split('T')[0],
                    date: moment(seconds * 1000).utcOffset(tz).format('YYYY-MM-DD'),
                })
                seconds -= (24 * 3600)
            }
            return dates
        },

        getAppUserLetters(state) {
            if(!state.app.user || !state.app.user.login) return ''
            let name = state.app.user.login
            let letters = name.toLowerCase().match(/^([a-z])([a-z]?)[a-z]*[\W\d_]*([a-z]?).*@/)
            if (letters.length>=3) {
                name = letters[1] + ((letters[3] > '') ? letters[3] : letters[2])
            } else {
                name = name.slice(0, 2)
            }
            return name.toUpperCase()
        },
        getBackgrounds(state) {
            return state.app.backgrounds
        },
        getUnitsIcons(state) {
            return state.app.unitsicons
        },
        getUnitsTypes(state) {
            return state.app.unitstypes
        },

        groupsFavorite(state){
            return state.app.groupsFavorite
        },
        groupsShowUnits(state){
            return state.app.groupsShowUnits
        },
        groupsShowUnitsByIds(state){
            return state.app.groupsShowUnits.reduce((out, id) => {
                out[id] = true
                return out
            }, {})
        },

        showSectionInfo(state) {
            return !(!state.app.sectionInfo)
        },
        getSectionInfo(state) {
            return state.app.sectionInfo
        },
        showSectionWidget(state) {
            return !(!state.app.sectionWidget)
        },
        getSectionWidget(state) {
            return state.app.sectionWidget
        },

        getFilterSearchLists(state){
            return state.app.FilterSearchLists
        },
        showMainList(state){
            return state.app.showMainList
        },
        showUserMenu(state) {
            return state.app.showUserMenu
        },
        showFilterDropdownMenu(state) {
            return state.app.showFilterDropdownMenu
        },
        getMobileMenuOpen(state){
            return state.mobileMenuOpen
        }
    }
}
