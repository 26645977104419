import { objectComparison } from '@/lib/lib'

const usersExpand = ['user_creator','changed__time','access_right']
//const usersFields = ['id','creator','login','email','phone','legal_name','time_zone','dst','status']

export default {
    state: {
        users: [],
        users_sorted: [],
        users_index: [],
        users_filter: {
            type: false,
            name: '',
        },
    },
    actions: {
        async fetchUsers({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: usersExpand.join()}//, fields: usersFields.join()}
            this.$api.users.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error){
                        commit('updateUsers', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async saveUser({ dispatch }, user) {
            let fn = (user.id) ? 'updateUser' : 'createUser'
            return dispatch(fn, user);
        },
        async createUser({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                const params = {expand: usersExpand.join()}
                this.$api.users.create(user, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsers', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUser({ commit, dispatch }, user) {
            return new Promise((resolve, reject) => {
                const params = {expand: usersExpand.join()}
                this.$api.users.update(user.id, user, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsers', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async deleteUser({ commit, dispatch }, id) {//remove
            return new Promise((resolve, reject) => {
                const params = {expand: usersExpand.join()}
                this.$api.users.delete(id, params)//remove
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUser', id)
                            dispatch('fetchUsers', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async restoreUser({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                const params = {expand: usersExpand.join()}
                this.$api.users.restore(id, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUser', response.data)
                            dispatch('fetchUsers', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUserPermanently({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                const params = {expand: usersExpand.join()}
                this.$api.users.deletePermanently(id, params)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUser', id)
                            dispatch('fetchUsers', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        updateUserProp(state, arg) {
            let user = state.users.find(u => {return u.id == arg.userId})
            if(!user){
                console.error('no user', arg.userId)
            }else
            if(arg.prop) {
                user[arg.prop.name] = arg.prop.value
            }
        },
        updateUsers(state, nUsers) {
            if(!state.users.length) {
                state.users = nUsers
            }else{
                let nId = nUsers.map(nu => nu.id)
                let users = state.users.filter(u => nId.includes(u.id) )
                if(users.length !== state.users.length) {
                    state.users = users
                }
                // console.log('updateUsers', state.users.length)
                nUsers.forEach(function (nUser) {
                    let i = state.users.findIndex(u => {return u.id == nUser.id})
                    if(i<0) {
                        state.users.push(nUser)
                    }else{
                        let oUser = state.users[i]
                        let cmp = objectComparison(nUser,oUser,['changed__time',...usersExpand])
                        if(!cmp){
                            let user = Object.assign({},state.users[i],nUser)
                            state.users.splice(i, 1, user)
                        }
                    }
                })
            }
            // state.users_index = state.users.map(u => u.id)
            // state.users_sorted = state.users.map(u => u.id)
        },
        updateUser(state, nUser) {
            let i = state.users.findIndex(u => {return u.id == nUser.id})
            if(i<0) {
                state.users.push(nUser)
            }else{
                let oUser = state.users[i]
                let cmp = objectComparison(nUser,oUser,['changed__time',...usersExpand])
                if(!cmp){
                    let user = Object.assign({},state.users[i],nUser)
                    state.users.splice(i, 1, user)
                }
            }
        },
        deleteUser(state, id) {
            state.users = state.users.filter(u => {return u.id != id})
        },

        setUsersFilter(state, filter) {
            state.users_filter = filter
        },
    },
    getters: {
        getUsers(state){
            return state.users
        },
        getUsersByIds(state){
            return state.users.reduce((out, u) => {
                out[u.id] = u
                return out
            }, {})
        },
        usersFilter(state){
            return state.users_filter
        },

        filteredUsers(state) {
            let filter = state.users_filter
            let name = filter.name.toLocaleLowerCase()

            let users = state.users
            if(filter.type > '') {
                users = users.filter(u => {
                    return u.type === filter.type
                })
            }
            if(filter.name > '') {
                users = users.filter(u => {
                    return u.name.toLocaleLowerCase().includes(name)
                })
            }
            //ToDo sort???
            let collator = new Intl.Collator();
            users.sort(function (a, b) {
                let cmp = collator.compare(a.login, b.login)
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            return users.map(u => u.id)
        },
        usersCount(state) {
            return state.users.length
        },

    }
}
