// import Vue from 'vue'
import {objectComparison} from "@/lib/lib";

const productsExpand = [];
//const productsFields = ['id',]

export default {
    state: {
        products: [],
        products_index:[]
    },
    actions: {
        async fetchProducts({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: productsExpand.join()}//, fields: productsFields.join()}
            this.$api.products.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error){
                        commit('updateProducts', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async saveProduct({ dispatch }, product) {
            let fn = (product.id) ? 'updateProduct' : 'createProduct'
            return dispatch(fn, product);
        },
        async createProduct({ commit, dispatch }, product) {
            return new Promise((resolve, reject) => {
                const params = {expand: productsExpand.join()}//, fields: productsFields.join()}
                this.$api.products.create(product, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateProduct', response.data)
                            dispatch('fetchProducts', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateProduct({ commit, dispatch }, product) {
            return new Promise((resolve, reject) => {
                const params = {expand: productsExpand.join()}//, fields: productsFields.join()}
                this.$api.products.update(product.id, product, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateProduct', response.data)
                            dispatch('fetchProducts', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteProduct({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.products.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteProduct', id)
                            dispatch('fetchProducts', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations:{
        updateProducts(state, nProducts) {
            if(!state.products.length) {
                state.products = nProducts
            }else{
                let nId = nProducts.map(np=> np.id)
                let products = state.products.filter( p => nId.includes(p.id) )
                if(products.length !== state.products.length){
                    state.products = products
                }
                nProducts.forEach(function (nProduct) {
                    let i = state.products.findIndex(p => {return p.id == nProduct.id})
                    if(i<0) {
                        state.products.push(nProduct)
                    }else{
                        let oProduct = state.products[i]
                        let cmp = objectComparison(nProduct, oProduct, ['changed__time',...productsExpand])
                        if(!cmp){
                            let product = Object.assign({},state.products[i],nProduct)
                            state.products.splice(i, 1, product)
                        }
                    }
                })
            }
            // state.products_index = state.products.map(p => p.id)
            // state.units_sorted = state.products.map(p => p.id)
        },
        updateProduct(state, nProduct) {
            let i = state.products.findIndex(u => {return u.id == nProduct.id})
            if(i<0) {
                state.products.push(nProduct)
            }else{
                let oProduct = state.products[i]
                let cmp = objectComparison(nProduct, oProduct, ['changed__time',...productsExpand])
                if(!cmp){
                    let product = Object.assign({},state.products[i],nProduct)
                    state.products.splice(i, 1, product)
                }
            }
        },
        deleteUnit(state, id) {
            state.products = state.products.filter(p => {return p.id != id})
        },
    },
    getters:{
        getProducts(state){
            return state.products
        }
    }
}