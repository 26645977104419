<template>
    <FieldGroup__base_wrap
        class="field-group_type_select"
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :input_icon="input_icon"
        :error="error"
    >

        <kwrapper-dropdownlist
            ref="dropdownlist"
            class=""
            :class="fieldGroup_input_class"
            :data-source="list"
            :data-text-field="'name'"
            :data-value-field="'id'"
            :id="id"
            :name="id"
            :placeholder="placeholder_text"
            :option-label="$t('Select _')"
            :filter="'contains'"
            :height="360"
            v-model="selected"
        >
        </kwrapper-dropdownlist>
        <span class="field-group__icon icon"
              @click="open"
        >
            <span class="icon__symbol"><SvgIcon class="icon" name="input__select-2"/></span>
        </span>
    </FieldGroup__base_wrap>
</template>
<script>
    export default {
        name: 'FieldGroup_kwrapper_dropdownlist',
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'id': String,
            'label': String,
            'value': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'items': [Array, Object],
            'input_icon': String,
            'field_id': {
                type: [String],
                default: () => 'id'
            },
            'field_name': {
                type: [String],
                default: () => 'name'
            },
            'sortBy': {
                type: [String, Function],
                default: () => 'name'
            },
            'disableSortBy': Boolean,
        },
        data() {
            return {
                dropdownlist: null,
            }
        },
        computed: {
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
            list() {
                let field_id = this.field_id
                let field_name = this.field_name
                let field_sortBy = (typeof this.sortBy === 'string') ? this.sortBy : ''
                let addFieldSortBy = (!this.disableSortBy && (typeof this.sortBy === 'string'))
                let list = []
                if (Array.isArray(this.items)) {
                    list = this.items.map(item => {
                        let listItem = {id: item[field_id], name: item[field_name]}
                        if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                        return listItem
                    })
                } else {
                    //console.warn('items isn\'t array', (typeof this.items))
                    const objectArray = Object.entries(this.items);
                    objectArray.forEach(([id, item]) => {
                        let name = item;
                        if (typeof item == 'object' && item[field_name] !== undefined) {//.hasOwnProperty
                            name = item[field_name]
                        }
                        if (typeof name == 'string') {
                            let listItem = {id, name}
                            if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                            list.push(listItem)
                        } else {
                            console.warn('item isn\'t string', id, item)
                        }
                    });
                }
                let sortBy = (typeof this.sortBy !== 'string') ? this.sortBy : 'sortBy'
                if (!this.disableSortBy) {
                    if (typeof sortBy === 'string') {
                        let field = '' + sortBy
                        sortBy = (a, b) => {
                            return (a[field] == b[field]) ? 0 :
                                (a[field] > b[field]) ? 1 : -1;
                        }
                    }
                    if (typeof sortBy === 'function') {
                        list.sort(sortBy);
                    } else {
                        console.warn('unknown method sortBy: ', typeof sortBy, sortBy)
                    }
                }
                return list
            },
            selected: {
                get() { return this.value },
                set(v) { this.$emit('input', v) }
            }
        },
        methods: {
            open() {
                //console.log(this.dropdownlist)
                this.dropdownlist.open()
            }
        },
        mounted() {
            if (this.$refs.dropdownlist) {
                this.dropdownlist = this.$refs.dropdownlist.kendoWidget()
            }
            //this.dropdownlist.value(this.value)
        },
        updated() {
            if (!this.dropdownlist && this.$refs.dropdownlist) {
                this.dropdownlist = this.$refs.dropdownlist.kendoWidget()
            }
            //console.log('FieldGroup_kwrapper_dropdownlist updated', this.id, this.error)
        },
    }
</script>
