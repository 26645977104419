export default {

    state: {
        index: [],
        events: [],
        event_active: null,
        events_filter: {
            unitId: '',
            date: '',
            type: '',
            name: '',
        }
    },
    actions: {
        setEventActive( { commit } , id) {
            commit('setEventActive' , id)
        },
        async fetchLastEvents({ commit, getters }, last) {
            if(!getters.apiToken) {
                return
            }
            last = last || '15 days'
            this.$api.events.getLast(last)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeEvents', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchEvents4Date({ commit, getters }, args) {
            if(!getters.apiToken || !args.date) {//!args.unitId ||
                return
            }
            // let events = getters.getEvents.filter(e => {
            //     return e.time.date == args.date // && e.unit_id == args.unitId
            // })
            // if(events.length){
            //     // return
            // }
            this.$api.events.get4Date(args.unitId, args.date, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeEvents', response.data)
                        commit('setIndex', {...args, count: response.data.length})
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchEvents4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                return
            }
            // let events = getters.getEvents.filter(e => {
            //     return e.unit_id == args.unitId &&
            //         e.time.utc >= args.from && e.time.utc <= args.to
            // })
            // if(events.length){
            //     // return
            // }
            this.$api.events.get4Period(args.unitId, args.from, args.to, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeEvents', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setEventActive(state , id){
            return state.event_active = id
        },
        setIndex(state, params) {
            state.index.push({...params})
        },
        storeEvents(state, events) {
            // state.events = events
            events = events.filter(e => {
                let event = state.events.find(se => {
                    return se.unit_id == e.unit_id
                        && se.time.utc == e.time.utc
                        && se.type == e.type
                })
                return !event
            })
            if(events.length) state.events.push(...events)
        },
        clearEvents(state){
            state.events = [];
            state.index = [];
        },
        setEventsFilter(state, filter) {
            state.events_filter = filter
        },
    },
    getters: {
        getEventActive(state){
            return state.event_active
        },
        getEventsFilter(state){
            return state.events_filter
        },
        getEventsIndex(state){
            return state.index
        },
        getEventsIndexFiltered(state){
            let filter = state.events_filter
            let index = state.index
            if(filter.date > '') {
                index = index.filter(i => {
                    return i.date == filter.date
                })
            }
            if(filter.unitId > '') {
                index = index.filter(i => {
                    return i.unitId == filter.unitId
                })
            }
            return !!(index.length)
        },
        getEventsCount(state){
            return state.events.length
        },
        getEvents(state){
            return state.events
        },
        getEventsByIds(state){
            return state.events.reduce((out, event) => {
                out[event.id] = event
                return out
            }, {})
        },
        getEventsFilteredByUnitDate(state) {
            let filter = state.events_filter

            let events = state.events
            if(filter.date > '') {
                events = events.filter(e => {
                    return e.time.date == filter.date
                })
            }
            if(filter.unitId > '') {
                events = events.filter(e => {
                    return e.unit_id == filter.unitId
                })
            }
            return events
        },
        getEventsFiltered(state, getters) {
            let filter = state.events_filter
            let name = filter.name ? filter.name.toLocaleLowerCase() : ''

            let events = getters.getEventsFilteredByUnitDate
            if(filter.name > '') {
                events = events.filter(e => {
                    return e.text.toLocaleLowerCase().includes(name)
                })
            }
            if(filter.type > '') {
                events = events.filter(e => {
                    return e['sub-type'] == filter.type
                })
            }
            return events
        },
    }
}
