<template>
    <vue-final-modal
        :click-to-close="false"
        :esc-to-close="true"
        v-slot="{ params, close }"
        v-bind="$attrs"
        classes="modal-container"
        content-class="modal-content"
        v-on="$listeners"
    >
        <button class="modal__close button button_view_icon button_theme_primary" @click="close">
            <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close"/></span> </span>
        </button>
        <span class="modal__title">
          <slot name="title"></slot>
        </span>
        <div class="modal__content">
            <slot v-bind:params="params"></slot>
        </div>
        <div class="modal__action">
            <button class="button button_view_base button_theme_danger mr__8"
                    @click="$emit('confirm', close)"
            >
                {{ confirm_text || $t('buttons.Confirm') }}
            </button>
            <button class="button button_view_base button_theme_neutral"
                    @click="$emit('cancel', close)"
            >
                {{ cancel_text || $t('buttons.Cancel') }}
            </button>
        </div>
    </vue-final-modal>
</template>

<script>
// import { VueFinalModal } from 'vue-final-modal'
export default {
    name: 'CustomModal',
    inheritAttrs: false,
    props: [
        // 'params',
        'confirm_text',
        'cancel_text',
    ],
    data() {
        return {}
    },
    // comments: { 'vue-final-modal': VueFinalModal },
}
</script>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.dark-mode div.modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>
