<template>
    <section class="section section_type_main-side is_new-design">
        <!--- Section type List --->
        <SectionList v-show="!isGuest && hasList" />

        <!--- Section type Basic --->
        <SectionBasic />
    </section>
</template>

<script>
    import SectionList from '@/components/SectionList.vue'
    import SectionBasic from '@/components/SectionBasic.vue'
    import {mapGetters} from "vuex";

    export default {
        name: "SectionMainside",
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isGuest",
            ]),
            hasList() {
                return (this.$route.meta.list)
            },
        },
        components: {
            SectionList, SectionBasic
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>