<template>
    <section
            class="section section_type_wrapper-curtain"
            :class="{'section_status_open-widget': showSectionWidget,}"
    >
        <section class="section section_type_widget is_new-design">
            <component :is="sectionWidget_component" v-bind="sectionWidget_props"  v-on:close-dialog="onClose" />
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import emptySection from "@/components/app/emptySection.vue";
    import UserSettings from "@/components/app/sectionInfo/UserSettings.vue";
    // import UserChangePassword from "@/components/app/sectionInfo/UserChangePassword.vue";

    export default {
        name: "SectionWidget",
        props: [],
        components: {
            emptySection,
            UserSettings,
            'UserChangePassword':  () => import('@/components/app/sectionInfo/UserChangePassword.vue'),
            'UserUpgrade':  () => import('@/components/app/sectionInfo/UserUpgrade.vue'),

            //units
            'UnitLocator':  () => import('@/components/units/sectionInfo/UnitLocator.vue'),
            'UnitEdit':     () => import('@/components/units/sectionInfo/UnitEdit.vue'),
            'GroupEdit':    () => import('@/components/units/sectionInfo/GroupEdit.vue'),
            'UnitCommand':  () => import('@/components/units/sectionInfo/UnitCommand.vue'),
            'GroupCommand': () => import('@/components/units/sectionInfo/GroupCommand.vue'),

            'UnitInfo':     () => import('@/components/units/sectionInfo/UnitInfo.vue'),
            'UnitChat':     () => import('@/components/units/sectionInfo/UnitChat.vue'),
            'UnitLiveVideo':  () => import('@/components/units/sectionInfo/UnitLiveVideo.vue'),

            //geoitems
            'GeozoneEdit':    () => import('@/components/geoitems/sectionInfo/GeozoneEdit.vue'),

            //notifications
            'NotificationsProps':    () => import('@/components/notifications_events_history/sectionInfo/NotificationsProps.vue'),

        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "showSectionWidget",
                "getSectionWidget",
            ]),
            sectionWidget() {
                return (this.getSectionWidget || {component: 'emptySection', props: {}})
            },
            sectionWidget_component() {
                return this.getSectionWidget ? this.getSectionWidget.component : 'emptySection'
            },
            sectionWidget_props() {
                return this.getSectionWidget ? this.getSectionWidget.props : {}
            },
        },
        methods: {
            ...mapActions([
                "setSectionWidget",
            ]),
            onClose(){
                this.setSectionWidget(null)
            },
        },
        updated: function() {
            //console.log('SectionWidget updated', this.sectionWidget_component);
        },
        mounted() {
            // console.log('section_type_info', this)
        },
    }
</script>

<style scoped>

</style>