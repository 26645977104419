export default {

    state: {
        index: [],
        parkings: [],
        parkings_filter: {unitId: '', date: ''}
    },
    actions: {
        async fetchParkings4Date({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            let parkings = getters.getParkings.find(t => {
                return t.unit_id == args.unitId && t.date == args.date
            })
            if(parkings){
                return
            }
            this.$api.parkings.get4Date(args.unitId, args.date, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeParkings', response.data)
                        commit('setIndex', {...args, count: response.data.length})
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchParkings4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let parkings = getters.getParkings.find(t => {
                return t.unit_id == args.unitId && t.time_start.utc==args.from && t.time_end.utc==args.to
            })
            if(parkings){
                return
            }
            this.$api.parkings.get4Period(args.unitId, args.from, args.to, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeParkings', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setIndex(state, params) {
            state.index.push({...params})
        },
        storeParkings(state, parkings) {
            // state.parkings = parkings
            parkings.forEach(p => {
                let parking = state.parkings.find(sp => {
                    return sp.unit_id == p.unit_id
                        && sp.time_start.utc == p.time_start.utc
                        && sp.time_end.utc == p.time_end.utc
                })
                if(!parking) state.parkings.push(p)
            })
        },
        clearParkings(state){
            state.parkings = [];
            state.index = [];
        },
        setParkingsFilter(state, filter) {
            state.parkings_filter = filter
        },
    },
    getters: {
        getParkingsFilter(state){
            return state.parkings_filter
        },
        getParkingsIndex(state){
            return state.index
        },
        getParkingsIndexFiltered(state){
            let filter = state.parkings_filter
            let index = state.index
            if(filter.date > '') {
                index = index.filter(i => {
                    return i.date == filter.date
                })
            }
            if(filter.unitId > '') {
                index = index.filter(i => {
                    return i.unitId == filter.unitId
                })
            }
            return !!(index.length)
        },
        getParkings(state){
            return state.parkings
        },
        getParkingsFiltered(state){
            let filter = state.parkings_filter
            let parkings = state.parkings
            if(filter.date > '') {
                parkings = parkings.filter(p => {
                    return p.time_start.date == filter.date
                        //&& p.time_end.date == filter.date
                })
            }
            if(filter.unitId > '') {
                parkings = parkings.filter(p => {
                    return p.unit_id == filter.unitId
                })
            }
            return parkings
        },
    }
}
