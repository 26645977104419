<template>
    <section class="section__info pfull__16" :class="class_type">
        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__signal"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Online') }}</h5>
                <p class="parameter__value">{{online}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__signal"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Offline') }}</h5>
                <p class="parameter__value">{{offline}}</p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle icon_theme_secondary"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__movement"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Moving') }}</h5>
                <p class="parameter__value">{{moving}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Parked') }}</h5>
                <p class="parameter__value">{{parked}}</p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__shown"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Shown') }}</h5>
                <p class="parameter__value">{{shown}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__hidden"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Hidden') }}</h5>
                <p class="parameter__value">{{hidden}}</p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle___geozone_circle"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Geo shown') }}</h5>
                <p class="parameter__value">{{geo_shown}}</p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle___geozone_circle_hidden"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Geo hidden') }}</h5>
                <p class="parameter__value">{{geo_hidden}}</p>
            </div>
        </div>

        <div class="vertical-line vertical-line_type_tracking type_tracking"
             :class="{type_tracking_status_active: unitTracking }"
             v-if="false"
        >
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__coords"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Tracking unit') }}</h5>
                <p class="parameter__value">{{unit.name}}</p>
            </div>
            <button class="button button_view_base button_theme_neutral button_size_md"
                    @click="setUnitTracking(null)"
            >
                {{ $t('buttons.Cancel') }}
            </button>
        </div>
    </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SectionMapInfo',
    props: {
        'class_type':{
            type: String,
            default: () => { return '' }
        }
    },
    data() {
        return {}
    },
    components: {},
    computed: {
        ...mapGetters([
            "units",
            "unitsLmsgs",
            "unitsOnMap",
            "unitsByIds",
            "unitTracking",
            "geozones",
            "geoitemsOnMap"
        ]),
        online(){
            return this.unitsLmsgs.reduce((cnt, lmsg) => {
                if (lmsg.timeAgo && lmsg.timeAgo<3600) // < 60 min
                    cnt++
                return cnt
            }, 0)
        },
        offline(){
            return this.units.length - this.online
        },
        moving(){
            return this.unitsLmsgs.reduce((cnt, lmsg) => {
                if (lmsg.status && lmsg.status.isMoving) cnt += 1
                return cnt
            }, 0)
        },
        parked(){
            return this.units.length - this.moving
        },
        shown(){
            return this.unitsOnMap.length
        },
        hidden(){
            return this.units.length - this.shown
        },
        geo_shown(){
            return this.geoitemsOnMap.length
        },
        geo_hidden(){
            return this.geozones.length - this.geo_shown
        },
        unit(){
            let id = this.unitTracking
            return this.unitsByIds[id] || {}
        }
    },
    methods: {
        ...mapActions([
            "setUnitTracking",
        ]),
    },
    updated() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>

</style>