<template>
    <div class="field-group" :class="fieldGroup__class">
        <div class="field-group__img-wrap" :style="styleContainer">
            <label class="field-group__label" :class="fieldGroup_label_class" :for="id">
                {{ label }}
            </label>

            <ul class="field-group__list-img">
                <li v-for="image in list"
                    class="field-group__img-item"
                    :class="{'field-group__img-item--active': selected == image[field_id]}"
                    :key="image.id"
                    :style="styleWrapImage"
                    @click="selected = image[field_id]"
                >
                    <img :src="image.url" :alt="image.alt" :title="image.title" :style="styleImage">
                </li>
            </ul>
            <span class="field-group__icon icon" v-if="input_icon">
                <span class="icon__symbol"><SvgIcon class="icon" :name="input_icon"/></span>
            </span>
            <span class="field-group__help" :class="fieldGroup_info_class">
            {{ info }}
            <template v-for="error in errors">
            {{ error }}
            </template>
        </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "FieldGroup_image_list",
    props: {
        fieldGroup_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        fieldGroup_label_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        fieldGroup_input_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        fieldGroup_info_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        label: String,
        previewStyle: [String, Object, Array],
        value: [String, Object, Array],
        errors: {
            type: [String, Array, Object],
            default: () => ''
        },
        id: [String, Number],
        info: [String, Number],
        input_icon: [String],
        field_id: {
            type: [String],
            default: () => 'id'
        },
        field_title: {
            type: [String],
            default: () => 'title'
        },
        field_url: {
            type: [String],
            default: () => 'url'
        },
        field_alt: {
            type: [String],
            default: () => 'alt'
        },
        items: [Array, Object],
        sortBy: {
            type: [String, Function],
            default: () => 'title'
        },
        disableSortBy: [Boolean],
        styleImage: [String, Array, Object],
        styleContainer: [String, Array, Object],
        styleWrapImage: [String, Array, Object]
    },
    data() {
        return {}
    },
    methods: {
    },
    computed: {
        selected: {
            get(){ return this.value },
            set(v){ this.$emit('input', v) }
        },
        fieldGroup__class() {
            let fieldGroup_class = this.fieldGroup_class
            let type = (typeof this.fieldGroup_class)
            if (type === 'string') {
                return fieldGroup_class
                    + ((this.error > '') ? ' has-error ' : '')
                    + ((this.disabled === true) ? ' is_disabled ' : '')
            } else
            if (type === 'object') {
                if (Array.isArray(this.fieldGroup_class)) {
                    if (this.error > '') fieldGroup_class.push('has-error')
                    if (this.disabled === true) fieldGroup_class.push('is_disabled')
                    return fieldGroup_class
                } else {
                    return {
                        'is_disabled': (this.disabled === true),
                        'has-error': (this.error > ''),
                        ...fieldGroup_class
                    }
                }
            }
            return this.fieldGroup_class
        },
        list() {
            let field_id = this.field_id
            let field_title = this.field_title
            let field_url = this.field_url
            let field_alt = this.field_alt
            let field_sortBy = (typeof this.sortBy === 'string') ? this.sortBy : ''
            let addFieldSortBy = (!this.disableSortBy && (typeof this.sortBy === 'string'))
            let list = []
            if (Array.isArray(this.items)) {
                list = this.items.map(item => {
                    let listItem = {id: item[field_id], title: item[field_title], url: item[field_url], alt: item[field_alt]}
                    if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                    return listItem
                })
            } else {
                //console.warn('items isn\'t array', (typeof this.items))
                const objectArray = Object.entries(this.items);
                objectArray.forEach(([id, item]) => {
                    if (typeof item == 'object' && item[field_title] && item[field_url] && item[field_alt]) {
                        let listItem = {id, title: item[field_title], url: item[field_url], alt: item[field_alt]}
                        if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                        list.push(listItem)
                    }
                });
            }
            let sortBy = (typeof this.sortBy !== 'string') ? this.sortBy : 'sortBy'
            if (!this.disableSortBy) {
                if (typeof sortBy === 'string') {
                    let field = '' + sortBy
                    sortBy = (a, b) => {
                        return (a[field] == b[field]) ? 0 :
                            (a[field] > b[field]) ? 1 : -1;
                    }
                }
                if (typeof sortBy === 'function') {
                    list.sort(sortBy);
                } else {
                    console.warn('unknown method sortBy: ', typeof sortBy, sortBy)
                }
            }
            return list
        },
    }
}
</script>

<style scoped>

</style>