// import Vue from 'vue'
// import {objectComparison} from "@/lib/lib";

const weathersExpand = [];
//const weathersFields = ['id',]

export default {
    state: {
        weathers: [],
        weathers_index:[]
    },
    actions: {
        async fetchWeathers({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: weathersExpand.join()}//, fields: weathersFields.join()}
            this.$api.weather.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error){
                        commit('updateWeathers', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchWeather({ commit, getters }, latLon) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: weathersExpand.join()}//, fields: weathersFields.join()}
            if(typeof latLon !== 'string'){
                if(Array.isArray(latLon)){
                    latLon = latLon.join(',')
                }else
                if(latLon.lat && latLon.lon){
                    latLon = `${latLon.lat},${latLon.lon}`
                }else
                if(latLon.lat && latLon.lng){
                    latLon = `${latLon.lat},${latLon.lng}`
                }
            }
            if(!(latLon > '')) {
                console.error('Unknown latLon', {...latLon})
                return
            }
            this.$api.weather.find(latLon, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error){
                        commit('updateWeathers', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchUnitWeather({ commit, getters }, id) {//commit,
            if(!getters.apiToken) {
                return false
            }
            //getters.getUnitActiveWeather
            await this.$api.units.weather(id, {})
                .then((response) => {
                    commit('updateWeathers', response.data)
                })
                .catch((error) => {
                    console.error(error);
                });
        },

    },
    mutations:{
        updateWeathers(state, nWeathers) {
            if(!state.weathers.length) {
                state.weathers = nWeathers
            }else{
                // let nId = nWeathers.map(nu=> nu.request_time)
                let weathers = state.weathers.filter(w => w.request_time +30*60 > Date.now()/1000)
                if(weathers.length !== state.weathers.length){
                    state.weather = weathers
                }
                nWeathers.forEach(function (nWeather) {
                    let isExists = state.weathers.find(w => w.latLng.lat == nWeather.latLng.lat && w.latLng.lon == nWeather.latLng.lon && w.request_time == nWeather.request_time)
                    if(!isExists) state.weathers.push(nWeather)
                })
            }
        },
        updateWeather(state, nWeather) {
            let weathers = state.weathers.filter(w => w.request_time +30*60 > Date.now()/1000)
            let update = (weathers.length !== state.weathers.length)

            let isExists = weathers.find(w => w.latLng.lat == nWeather.latLng.lat && w.latLng.lon == nWeather.latLng.lon && w.request_time == nWeather.request_time)
            if(!isExists) weathers.push(nWeather)
            if(!isExists || update){
                state.weathers = weathers
            }
        },
    },
    getters:{
        getWeathers(state){
            return state.weathers//.filter(w => w.request_time > Date.now()/1000 -3600)
        },
        getUnitActiveWeather(state){
            let unitId = state.unit_active
            if(!unitId) return false
            let lmsg = state.unitsLmsgs.find(lmsg => lmsg.id_unit == unitId)
            if(!lmsg || !lmsg.latlng) return false
            let weathers = state.weathers
                //.filter(w => w.request_time > Date.now()/1000 -3600)
                .map(w => {
                    let lat = (w.latLng.lat*1.0) - (lmsg.latlng.lat*1.0)
                    let lon = (w.latLng.lng*1.0) - (lmsg.latlng.lng*1.0)
                    let distances = Math.sqrt(Math.pow(lat,2) + Math.pow(lon,2))
                    return {...w, distances}
                })
            weathers.sort(function (a,b){
                return a.distances - b.distances + (Math.pow((a.request_time - b.request_time),2))
            })
            return weathers[0]
        },
    }
}