// import Vue from 'vue'
import {objectComparison} from "@/lib/lib";

const keysExpand = [];

export default {
    state: {
        unitsKeys: [],
        unitsKeysFreeStatistics: [],
    },
    actions: {
        async fetchUnitsKeysFreeStatistics({ commit, getters }) {//only for master
            if(!getters.apiToken) {
                return
            }
            let params = {'filter[free]': 1}
            this.$api.unitskeys.getStatistics(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateUnitsKeysFreeStatistics', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchUnitsKeys({ commit, getters }, params) {
            if(!getters.apiToken) {
                return
            }
            params = {expand: keysExpand.join(), ...params}
            this.$api.unitskeys.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateUnitsKeys', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async saveUnitsKey({ dispatch }, key) {
            let fn = (key.id) ? 'updateUnitsKey' : 'createUnitsKey'
            return dispatch(fn, key);
        },
        async createUnitsKey({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = {expand: keysExpand.join()}
                this.$api.unitskeys.create(key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeys', {})
                            dispatch('fetchUnitsKeysOrders', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnitsKey({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = {expand: keysExpand.join()}
                this.$api.unitskeys.update(key.id, key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsKey', response.data)
                            dispatch('fetchUnitsKeys', {})
                            dispatch('fetchUnitsKeysOrders', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitsKey({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitskeys.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUnitsKey', id)
                            dispatch('fetchUnitsKeys', {})
                            dispatch('fetchUnitsKeysOrders', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async calcUnitsKeysTimeLeft({ commit }){
            commit('calcUnitsKeysTimeLeft')
        },

        //sayHello() {}
    },
    mutations: {
        updateUnitsKeysFreeStatistics(state, keys) {
            state.unitsKeysFreeStatistics = keys
            state.unitsKeys = []
        },

        updateUnitsKeys(state, nKeys) {
            if(!state.unitsKeys.length) {
                state.unitsKeys = nKeys
            }else{
                let nId = nKeys.map(nk => nk.id)
                let keys = state.unitsKeys.filter(k => nId.includes(k.id) )
                if(keys.length !== state.unitsKeys.length) {
                    state.unitsKeys = keys
                }
                // console.log('updateUnits', state.unitsKeys.length)
                nKeys.forEach(function (nKey) {
                    let i = state.unitsKeys.findIndex(k => {return k.id == nKey.id})
                    if(i<0) {
                        state.unitsKeys.push(nKey)
                    }else{
                        let oKey = state.unitsKeys[i]
                        let cmp = objectComparison(nKey,oKey,['changed__time',...keysExpand])
                        if(!cmp){
                            let key = Object.assign({},state.unitsKeys[i],nKey)
                            state.unitsKeys.splice(i, 1, key)
                        }
                    }
                })
            }
            // state.unitsKeys_index = keys.map(k => k.id)
            // state.unitsKeys_sorted = keys.map(k => k.id)
            state.unitsKeysFreeStatistics = []
        },
        updateUnitsKey(state, nKey) {
            let i = state.unitsKeys.findIndex(k => {return k.id == nKey.id})
            if(i<0) {
                state.unitsKeys.push(nKey)
            }else{
                let oKey = state.unitsKeys[i]
                let cmp = objectComparison(nKey,oKey,['changed__time',...keysExpand])
                if(!cmp){
                    let key = Object.assign({},state.unitsKeys[i],nKey)
                    state.unitsKeys.splice(i, 1, key)
                }
            }
        },
        deleteUnitsKey(state, id) {
            state.unitsKeys = state.unitsKeys.filter(k => k.id != id)
        },

        calcUnitsKeysTimeLeft(state){
            state.unitsKeys.forEach(key => {
                key.time__left--
            })
        },

    },
    getters: {
        unitsKeysFreeStatistics(state,getters){
            if(state.unitsKeysFreeStatistics.length) {
                return state.unitsKeysFreeStatistics
            } else
            if(state.unitsKeys.length) {
                let unitsKeys = state.unitsKeys.filter(k => !k.activation.utc && !k.removed.utc && k.time__left>0)
                return getters.getProducts.map(p => {
                    let keys = unitsKeys.filter(k => p.id == k.productId)
                    let count = keys.length
                    return {id: p.id, name: p ? p.name +` (${count})`: '', keys, count}
                })
                .filter(p => p.count)
            }
        },
        unitsKeysFreeSum(state, getters){//only for master
            if(getters.isSuperAdmin) return 0
            return state.unitsKeysFreeStatistics.map(k => {
                return  k.id ? k.id.length : 0;
            }).reduce((sum , cnt) => sum + cnt, 0);
        },
        unitsKeysFreeByProducts(state, getters){//only for master
            if(getters.isSuperAdmin) return []
            return state.unitsKeysFreeStatistics.map(k => {
                let days = k.days
                let keys = k.id
                //console.log(`${days} ${keys.length}`);
                return {id: k.productId, name: k.name+` (${keys.length})`, name_: `+${days} days (${keys.length})`, keys}
            });
        },

        unitsKeys(state){
            return state.unitsKeys
        },
        _unitsKeysFreeSum(state, getters){
            if(getters.isSuperAdmin) return 0
            let unitsKeys = state.unitsKeys.filter(k => !k.activation.utc && !k.is_transferred && !k.removed.utc && k.time__left>0)
            return unitsKeys.length
        },
        _unitsKeysFreeByProducts(state, getters){
            if(getters.isSuperAdmin) return []
            let unitsKeys = state.unitsKeys.filter(k => !k.activation.utc && !k.is_transferred && !k.removed.utc && k.time__left>0)
            return getters.getProducts.map(p => {
                let keys = unitsKeys.filter(k => p.id == k.productId)
                let count = keys.length
                return {id: p.id, name: p ? p.name +` (${count})`: '', keys, count}
            })
            .filter(p => p.count)
        },
        unitsKeysFreeByOrdersIds(state, getters){
            if(getters.isSuperAdmin) return []
            let unitsKeys = state.unitsKeys.filter(k => !k.activation.utc && !k.is_transferred && !k.removed.utc && k.time__left>0)
            let unitsKeysOrders = unitsKeys.reduce((out,k) => {
                let order = out.find( o => o.order_id == k.order_id)
                if(!order){
                    order = {order_id: k.order_id, keys: []}
                    out.push(order)
                }
                order.keys.push(k.id)
                return out
            },[])
            return unitsKeysOrders
        },
        unitsKeysFreeSumText(state,getters){
            if(getters.isSuperAdmin) return {}
            let unitsKeys = state.unitsKeys.filter(k => !k.activation.utc && !k.is_transferred && !k.removed.utc && k.time__left>0)
            let products = getters.getProducts.sort(function (a,b){
                return (a.type == b.type) ? a.days - b.days : ((a.type > b.type) ? 1 : -1);
            })
            let balance = {commercial:[], trial:[{name:'Trial', count: 0}]}
            balance = products.reduce((out,p) => {
                let keys = unitsKeys.filter(k => p.id == k.productId)
                let count = keys.length
                if(count){
                    if(p.type == 'trial') {
                        out.trial[0]['count'] += count
                    } else {
                        let inArray = out.commercial.find( t => t.name == p.name)
                        if(inArray) {
                            inArray['count'] += count
                        } else {
                            out.commercial.push({name: p.name,  count: count})
                        }
                    }
                }
            return out
            }, balance)
            if(!balance.trial[0]['count']) delete balance.trial
            return balance
        },
        unitsKeysByIds(state){
            return state.unitsKeys.reduce((out, k) => {
                out[k.id] = k
                return out
            }, {})
        },
        unitsKeysByGroups(state){
            let list = state.unitsKeys.filter(k => {
                return k.indx == 1
            })
            list = list.map( k => {
                // let keys = state.unitsKeys.filter(uk => {
                //     return uk.parentId == k.id
                // })
                //k.removed.utc = 0
                return {...k, id: -k.id, parentId: null, indx: 0,
                    removed: {utc: 0},
                    changed: {formated:{}},
                    unit: {},
                    status: '',
                }
            })
            list.push(...state.unitsKeys)
            list = list.map( k => {
                if(k.parentId) k.parentId *= -1
                return k
            })
            return list
        },

    }
}
