<template>
    <div class="field-group" :class="fieldGroup__class">
        <label class="field-group__label" :class="fieldGroup_label_class" :for="id">
            {{ label }}
        </label>

        <slot></slot>

        <span class="field-group__icon icon" v-if="input_icon" data-toggle="">
            <span class="icon__symbol">
                <SvgIcon class="icon" :name="input_icon"/>
            </span>
        </span>
        <span class="field-group__help" :class="fieldGroup_info_class">
            {{ info }}
            <template v-for="error in errors">
            {{ error }}
            </template>
        </span>
    </div>
</template>

<script>
export default {
    name: "FieldGroup__base_wrap",
    props: {
        'fieldGroup_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_label_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'fieldGroup_info_class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'label': String,
        'id': String,
        'info': String,
        'input_icon': String,
        'error': {
            type: [String, Array, Object],
            default: () => ''
        },
    },
    data() {
        return {}
    },
    computed: {
        fieldGroup__class() {
            let fieldGroup_class = this.fieldGroup_class
            let type = (typeof this.fieldGroup_class)
            if (type === 'string') {
                return fieldGroup_class
                    + ((this.error > '') ? ' has-error ' : '')
                    + ((this.disabled === true) ? ' is_disabled ' : '')
            } else
            if (type === 'object') {
                if (Array.isArray(this.fieldGroup_class)) {
                    if (this.error > '') fieldGroup_class.push('has-error')
                    if (this.disabled === true) fieldGroup_class.push('is_disabled')
                    return fieldGroup_class
                } else {
                    return {
                        'is_disabled': (this.disabled === true),
                        'has-error': (this.error > ''),
                        ...fieldGroup_class
                    }
                }
            }
            console.error('FieldGroup_input::fieldGroup__class', type, fieldGroup_class)
            return this.fieldGroup_class
        },
        errors() {
            if (!this.error) return []
            let type = (typeof this.error)
            if (type === 'string') {
                return [this.error]
            } else
            if (type === 'object' && Array.isArray(this.error)) {
                return this.error
            }
            console.error('FieldGroup_input::errors', type, this.error)
            return []
        },
    }
}
</script>

<style scoped>

</style>