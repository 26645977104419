const RESOURCE_NAME = '/v1/users';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    remove(id, params) {
        return axios.delete(`${RESOURCE_NAME}/${id}`, {params});
    },
    restore(id, params) {
        return axios.put(`${RESOURCE_NAME}/${id}/restore`, {}, {params});
    },
    deletePermanently(id, params) {
        return axios.delete(`${RESOURCE_NAME}/${id}?permanently`, {params});
    },
});