var moment = require('moment-timezone');

export {
    calcUTCOffset, calcTimeToday, calcDateToday,
    arrayFilter_OnlyUnique,
    diffObjects, diffObjectsRecursive, diffObjectsRecursiveExceptions,
    objectComparison
}

// var ApiPromise_catch = (error, errors) => {
//     // Error
//     if (error.response) {
//         // console.error(error.response)
//         if(error.response.status == 422){
//             errors = error.response.data.reduce((out, error) => {
//                 out[error.field] = error.message
//                 return out
//             }, {})
//         }
//     } else if (error.request) {
//         console.error(error.request);
//     } else {
//         console.error('Error', error.message);
//     }
//     this.$toast.error(this.$t("error!"))
//     //console.error(error.config);
// }

function arrayFilter_OnlyUnique(value, index, self)
{
    return self.indexOf(value) === index;
}

function objectComparison( newObj, oldObj, fields)
{
    let equal = true
    fields.every(fld => {
        let newVal = typeof newObj[fld] == 'object' ? JSON.stringify(newObj[fld]) : newObj[fld]
        let oldVal = typeof oldObj[fld] == 'object' ? JSON.stringify(oldObj[fld]) : oldObj[fld]
        if(newVal != oldVal){
            equal = false
            return false
        }
    })
    return equal
}

function calcUTCOffset(/*int*/ timezone, /*boolean*/ dst)
{
    let utc = moment().unix() - timezone*1
    dst = dst && moment(utc*1000).tz("Europe/London").isDST()
    timezone += (dst ? 3600 : 0)
    return timezone
}
function calcTimeToday(/*int*/ timezone, /*boolean*/ dst)
{
    timezone = calcUTCOffset(timezone, dst)
    let today = moment().utcOffset(timezone/3600).startOf('day').unix()
    return today
}
function calcDateToday(/*int*/ timezone, /*boolean*/ dst)
{
    timezone = calcUTCOffset(timezone, dst)
    let today = moment().utcOffset(timezone/3600).startOf('day').format('YYYY-MM-DD')
    return today
}

function diffObjects(oldVal,newVal)
{
    if(typeof newVal == 'object' && typeof oldVal == 'object'){
        if(Array.isArray(newVal) && Array.isArray(oldVal)){
            return newVal
        } else {
            let diffObj
            let oldV = {...oldVal}
            let newV = {...newVal}
            let newKeys = Object.keys(newV)
            newKeys.forEach(key => {
                if(JSON.stringify(newV[key]) != JSON.stringify(oldV[key]))
                {
                    if(!diffObj) diffObj = {}
                    diffObj[key] = newV[key]
                }
            })
            // Object.keys(oldV).forEach(key => {
            //     if(!newKeys.includes(key)){
            //         if(!diffObj) diffObj = {}
            //         diffObj[key] = null
            //     }
            // })
            return diffObj
        }
    } else
    if(newVal !== oldVal) {
        return newVal
    }
    return undefined
}
function diffObjectsRecursive(oldVal, newVal)
{
    //???
    //if(newVal == oldVal){ return undefined }
    if(typeof newVal !== typeof oldVal){
        return newVal
    } else
    if(typeof newVal == 'object') {
        let diffObj
        if (Array.isArray(newVal)) {
            return newVal
        } else
        {//objects
            let oldV = {...oldVal}
            let newV = {...newVal}
            Object.keys(newV).forEach(key => {
                let keyVal = diffObjectsRecursive(oldV[key], newV[key])
                if(keyVal !== undefined){
                    if(!diffObj) diffObj = {}
                    diffObj[key] = keyVal
                }
            })
        }
        return diffObj
    } else
    if(newVal !== oldVal) {
        return newVal
    }
    return undefined
}
function diffObjectsRecursiveExceptions(oldVal, newVal, fields4DiffRecursive = [])
{
    if ((typeof newVal != 'object' || typeof oldVal != 'object') ||
        (Array.isArray(newVal) || Array.isArray(oldVal))
    ) {
        return undefined
    }

    //only objects
    let oldValR = {},
        newValR = {},
        oldValNR = {...oldVal},
        newValNR = {...newVal}

    let keysRecursive = Object.keys(newVal).filter(key => fields4DiffRecursive.find(fld => fld == key ))
    keysRecursive.forEach(k => {
            oldValR[k] = oldValNR[k]
            newValR[k] = newValNR[k]
            delete oldValNR[k]
            delete newValNR[k]
    })
    let newNR = diffObjects(oldValNR, newValNR)
    let newR = diffObjectsRecursive(oldValR, newValR)
    let result = {...newNR , ...newR}
    return Object.keys(result).length ? result : undefined
}