<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :input_icon="input_icon"
        :error="error"
    >

        <input class="field-group__input"
               :readonly="read_only"
               :autocomplete="false"
               :class="fieldGroup_input_class"
               :disabled="disabled===true"
               :type="type"
               :id="id"
               :name="id"
               :placeholder="placeholder_text"
               :value="value" @input="$emit('input', $event.target.value)"
        >
    </FieldGroup__base_wrap>
</template>
<script>
    export default {
        name: 'FieldGroup_input',
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'read_only':{
                type: Boolean,
                default: () => false
            },
            'id': String,
            'label': String,
            'value': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'input_type': String,
            'input_icon': String,
            'disabled': Boolean,
        },
        data() {
            return {}
        },
        computed: {
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
            type() {
                return this.input_type ? this.input_type : 'text'
            }
        },
        methods: {
        },
        mounted() {
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>
