<template>
    <section class="section__info info info_view_notice pfull__16">
        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__geozone_entrance_control"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Entr geo') }}</h5>
                <p class="parameter__value">
                    {{ $t('MapInfo.notices', {n: events['2'] || 0}) }}
                </p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__geozone_exit_control"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Exit geo') }}</h5>
                <p class="parameter__value">
                    {{ $t('MapInfo.notices', {n: events['3'] || 0}) }}
                </p>
            </div>
        </div>
        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__low-battery"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Low bat') }}</h5>
                <p class="parameter__value">
                    {{ $t('MapInfo.notices', {n: events['low battery'] || 0}) }}
                </p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="common__signal_lost"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Lost con') }}</h5>
                <p class="parameter__value">
                    {{ $t('MapInfo.notices', {n: events['lost connection'] || 0}) }}
                </p>
            </div>
        </div>
        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__speed_lg"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Speed viol') }}</h5>
                <p class="parameter__value">
                    {{ $t('MapInfo.notices', {n: events['max speed'] || 0}) }}
                </p>
            </div>

            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="notifications__input"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Input alert') }}</h5>
                <p class="parameter__value">
                    {{ $t('MapInfo.notices', {n: events['input'] || 0}) }}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SectionMapNotifEventsInfo',
    data() {
        return {}
    },
    components: {},
    computed: {
        ...mapGetters([
            "getAppUser",
            "getAppUserSettings",

            "getEventsFiltered",
            "getNotificationsEventsFiltered",
        ]),
        now() {
            return Date.now()
        },
        no_data() {
            return !(this.getNotificationsEventsFiltered.length+this.getEventsFiltered.length)
        },
        events(){
            if(this.no_data) return {}
            let events = {}
            this.getEventsFiltered.reduce((out, event) => {
                if(!out[event['sub-type']]){
                    out[event['sub-type']] = 0
                }
                out[event['sub-type']]++
                return out
            }, events)
            this.getNotificationsEventsFiltered.reduce((out, event) => {
                if(!out[event['sub-type']]){
                    out[event['sub-type']] = 0
                }
                out[event['sub-type']]++
                return out
            }, events)
            return events
        },
    },
    methods: {
        ...mapActions([
        ]),
    },
    updated() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>

</style>