<template>
    <section class="section section_type_basic is_new-design">
        <!--- Section type Info --->
        <SectionInfo v-show="!isGuest && hasInfo" />

        <!--- Section type Content --->
        <SectionContent />

        <!--- Section type Wrapper-curtain + Section type Widget --->
        <SectionWidget v-show="!isGuest && hasWidget" />
    </section>
</template>

<script>
    import SectionInfo from './SectionInfo.vue'
    import SectionContent from './SectionContent.vue'
    import SectionWidget from './SectionWidget.vue'
    import {mapGetters} from "vuex";

    export default {
        name: "SectionBasic",
        // props: ['app'],
        components: {
            SectionInfo, SectionContent, SectionWidget
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isGuest",
            ]),
            hasInfo() {
                return true
            },
            hasWidget() {
                return true
            },
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>