// import Vue from 'vue'
import {objectComparison} from "@/lib/lib";

const ordersExpand = [];

export default {
    state: {
        unitsKeysOrders: [],
    },
    actions: {
        async fetchUnitsKeysOrders({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: ordersExpand.join()}//,'filter[free]': 0
            this.$api.unitskeysorders.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateUnitsKeysOrders', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async saveUnitsKeysOrder({ dispatch }, key) {
            let fn = (key.id) ? 'updateUnitsKeysOrder' : 'createUnitsKeysOrder'
            return dispatch(fn, key);
        },
        async createUnitsKeysOrder({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = {expand: ordersExpand.join()}
                this.$api.unitskeysorders.create(key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeysOrders', {})
                            dispatch('fetchUnitsKeys', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnitsKeysOrder({ commit, dispatch }, key) {
            return new Promise((resolve, reject) => {
                const params = {expand: ordersExpand.join()}
                this.$api.unitskeysorders.update(key.id, key, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeysOrders', {})
                            dispatch('fetchUnitsKeys', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitsKeysOrder({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitskeysorders.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUnitsKeysOrder', id)
                            dispatch('fetchUnitsKeysOrders', {})
                            dispatch('fetchUnitsKeys', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async transferKeys({ dispatch }, order){
            return new Promise((resolve, reject) => {
                const params = {expand: ordersExpand.join()}
                this.$api.unitskeysorders.transfer(order, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            //commit('updateUnitsKeysOrder', response.data)
                            dispatch('fetchUnitsKeysOrders', {})
                            dispatch('fetchUnitsKeys', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        calcUnitsKeysOrdersTimeLeft({ commit }){
            commit('calcUnitsKeysOrdersTimeLeft')
        },

        //sayHello() {}
    },
    mutations: {
        updateUnitsKeysOrders(state, nOrders) {
            if(!state.unitsKeysOrders.length) {
                state.unitsKeysOrders = nOrders
            }else{
                let nId = nOrders.map(nO => nO.id)
                let orders = state.unitsKeysOrders.filter(o => nId.includes(o.id) )
                if(orders.length !== state.unitsKeysOrders.length) {
                    state.unitsKeysOrders = orders
                }
                // console.log('updateUnits', state.unitsKeysOrders.length)
                nOrders.forEach(function (nOrder) {
                    let i = state.unitsKeysOrders.findIndex(o => {return o.id == nOrder.id})
                    if(i<0) {
                        state.unitsKeysOrders.push(nOrder)
                    }else{
                        let oOrder = state.unitsKeysOrders[i]
                        let cmp = objectComparison(nOrder,oOrder,['changed__time',...ordersExpand])
                        if(!cmp){
                            let order = Object.assign({},state.unitsKeysOrders[i],nOrder)
                            state.unitsKeysOrders.splice(i, 1, order)
                        }
                    }
                })
            }
            // state.unitsKeysOrders_index = keys.map(o => o.id)
            // state.unitsKeysOrders_sorted = keys.map(o => o.id)
        },
        updateUnitsKeysOrder(state, nOrder) {
            let i = state.unitsKeysOrders.findIndex(o => {return o.id == nOrder.id})
            if(i<0) {
                state.unitsKeysOrders.push(nOrder)
            }else{
                let oOrder = state.unitsKeysOrders[i]
                let cmp = objectComparison(nOrder,oOrder,['changed__time',...ordersExpand])
                if(!cmp){
                    let order = Object.assign({},state.unitsKeysOrders[i],nOrder)
                    state.unitsKeysOrders.splice(i, 1, order)
                }
            }
        },
        deleteUnitsKeysOrder(state, id) {
            state.unitsKeysOrders = state.unitsKeysOrders.filter(o => {return o.id != id})
        },

        calcUnitsKeysOrdersTimeLeft(state){
            state.unitsKeysOrders.forEach(key => {
                key.time__left--
            })
        },

    },
    getters: {
        unitsKeysOrders(state){
            return state.unitsKeysOrders
        },
        unitsKeysOrdersByIds(state){
            return state.unitsKeysOrders.reduce((out, o) => {
                out[o.id] = o
                return out
            }, {})
        },
        unitsKeysOrdersByGroups(state){
            return state.unitsKeysOrders.map( o => {
                if(o.parentId) o.parentId = null
                return o
            })
        },
        unitsKeysOrdersByGroups0(state){
            let list = state.unitsKeysOrders.filter(o => {
                return o.indx == 1
            })
            list = list.map( o => {
                // let keys = state.unitsKeysOrders.filter(kk => {
                //     return kk.parentId == o.id
                // })
                //o.removed.utc = 0
                return {...o, id: -o.id, parentId: null, indx: 0,
                    removed: {utc: 0},
                    changed: {formated:{}},
                    unit: {},
                    status: '',
                }
            })
            list.push(...state.unitsKeysOrders)
            list = list.map( o => {
                if(o.parentId) o.parentId = null
                return o
            })
            return list
        },

    }
}
