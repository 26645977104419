<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :error="error"
    >
        <div class="input-group"><!-- 'input-group'!!! -->
            <flat-pickr
                class="field-group__input"
                :class="fieldGroup_input_class"
                :id="id"
                :name="id"
                :placeholder="placeholder_text"
                :config="config"
                :value="value"
            >
            </flat-pickr>
            <span class="field-group__icon icon"
                  v-if="icon"
                  data-toggle=""
            >
                <span class="icon__symbol"><SvgIcon class="icon" :name="icon"/></span>
            </span>
        </div>
    </FieldGroup__base_wrap>
</template>
<script>
    import flatPickr from "vue-flatpickr-component";
    import 'flatpickr/dist/flatpickr.css';
    // theme is optional
    // try more themes at - https://flatpickr.js.org/themes/
    import 'flatpickr/dist/themes/material_blue.css';
    // localization is optional
    import {english} from "flatpickr/dist/l10n/default.js"
    import {Spanish} from "flatpickr/dist/l10n/es.js"
    import {Portuguese} from "flatpickr/dist/l10n/pt.js"
    import {mapGetters} from "vuex";

    export default {
        name: 'FieldGroup_flatpickr',
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'id': String,
            'label': String,
            'value': [String, Object, Array],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'input_icon': String,
            'maxDate': [String, Number],
            'minDate': [String, Number],
        },
        components: {
            flatPickr,
        },
        data() {
            return {
                // Get more form https://flatpickr.js.org/options/
                def_config: {
                    wrap: true, // set wrap to true only when using 'input-group'
                    altFormat: 'M j, Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    //locale: Hindi, // locale for this instance only
                    onChange: null,
                },
                locales: {
                    'en': english,
                    'es': Spanish,
                    'pt': Portuguese,
                }
            }
        },
        computed: {
            ...mapGetters([
                "getDateFormatAll",
            ]),
            locale() {
                return this.$root.$i18n.locale
            },
            config() {
                let altFormat = this.getDateFormatAll.altFormat || 'M j, Y'
                let config = {...this.def_config, altFormat}
                config.locale = this.locales[this.locale]
                if (this.minDate > '') config.minDate = this.minDate
                if (this.maxDate > '') config.maxDate = this.maxDate
                return config
            },
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
            icon() {
                return this.input_icon ? this.input_icon : 'input__date'
            }
        },
        methods: {
        },
        created() {
            //Hack !!!
            this.config.onChange = (selectedDates, dateStr /*, instance*/) => {
                //console.log(selectedDates, dateStr, instance);
                this.$emit('input', dateStr)
            }
        },
        mounted() {
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>
