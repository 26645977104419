<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title">{{ $t('User.title.User settings') }}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <section class="section__container container container_active separator-when-scrolling pfull__16">
                <FieldGroup_input
                        :field-group_class="'mb__12'"
                        :id="'name'"
                        :label="$t('User.Login')"
                        :error="errors.login"
                        v-model="user.login"
                />
                <FieldGroup_input
                        :field-group_class="'mb__24'"
                        :id="'email'"
                        :label="$t('User.Email')"
                        :error="errors.email"
                        v-model="user.email"
                        v-if="false"
                />

                <h3 class="form-subtitle mb__12">{{ $t('User.Language') }}</h3>
                <SegmentControl
                    :segment-control-class="'mb__24'"
                    :items="getLangs"
                    v-model="$root.$i18n.locale"
                />

                <h3 class="form-subtitle mb__12">{{ $t('User.Measure') }}</h3>
                <SegmentControl
                        :segment-control-class="'mb__12'"
                        :items="{'metric':$t('measure.metric'),'imperial':$t('measure.imperial'),'us':$t('measure.us')}"
                        v-model="user.units_of_measure"
                />

                <FieldGroup_select
                    :field-group_class="'field-group mb__12'"
                    :id="'time_zone'"
                    :label="$t('User.Time zone')"
                    :items="getSettingsValues.time_zone"
                    :sortBy="(a, b) => { return Math.sign(a.id-b.id) }"
                    :error="errors.time_zone"
                    v-model="user.time_zone"
                />
                <CheckboxControl
                    :control-class="'mb__12'"
                    :label="$t('User.Daylight Saving Time')"
                    v-model="user.dst"
                />
                <div style="display: flex">
                    <FieldGroup_select
                        :field-group_class="'field-group mb__12 mr__8'"
                        :id="'format'"
                        :field_id="'php_format'"
                        :field_name="'name'"
                        :label="$t('User.Date format')"
                        :items="getDateFormats"
                        v-model="user.settings.dateFormat"
                    />
                    <FieldGroup_select
                        :field-group_class="'field-group mb__12'"
                        :id="'format'"
                        :field_id="'php_format'"
                        :field_name="'name'"
                        :label="$t('User.Time format')"
                        :items="getTimeFormats"
                        v-model="user.settings.timeFormat"
                    />
                </div>
                <div class="aside__info info">
                    <SectionCurrentDateTime
                        :control-class="'mb__44'"
                        :timezone-offset="timeZone"
                        :format="userDateTimeFormat"
                    />
                </div>

                <h3 class="form-subtitle mb__12">{{ $t('User.Color track by speed') }}</h3>
                <div class="color-slider color-slider_width_400 ml__12 mb__64">
                    <vue-slider id="speed"
                                :min="3"
                                :max="200"
                                v-model="slider.values"
                                :marks="slider.marks"
                                :minRange="slider.minRange"
                                :process="slider.colors"
                                :tooltip="'always'"
                                :enable-cross="false"
                                :clickable="false"
                    >
                        <template v-slot:process="{ style, index }">
                            <div class="vue-slider-process custom-class"
                                 :style="style"
                                 @click="onClick_slider(index)"
                            >
                                <!-- Can add custom elements here -->
                            </div>
                        </template>
                        <!-- <template v-slot:tooltip="{ value }">-->
                        <!--     <div class="custom-tooltip">{{ value }}</div>-->
                        <!-- </template>-->
                    </vue-slider>
                </div>
            </section>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{ $t('buttons.Save') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    //https://nightcatsama.github.io/vue-slider-component/#/basics/simple
    import VueSlider from 'vue-slider-component'
    //import 'vue-slider-component/theme/antd.css'
    // import 'vue-slider-component/theme/material.css'
    import 'vue-slider-component/theme/default.css'
    import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";
    import {calcUTCOffset, diffObjectsRecursiveExceptions} from "@/lib/lib";

    export default {
        name: "UserSettings",
        props: [],
        data() {
            return {
                slider: {
                    // min: 3,
                    // max: 200,
                    marks: [3, 50, 100, 150, 200],
                    minRange: 10,
                    values: [50, 80, 120],
                    //https://nightcatsama.github.io/vue-slider-component/#/basics/process
                    colors: dotsPos => [
                        [0, dotsPos[0], {backgroundColor: '#29F499'}],
                        [dotsPos[0], dotsPos[1], {backgroundColor: '#027AFD'}],
                        [dotsPos[1], dotsPos[2], {backgroundColor: '#FF8100'}],
                        [dotsPos[2], 100, {backgroundColor: '#FF2D00'}],
                    ],
                },
                user: {},
                //settings: {},
                errors: {},
            }
        },
        components: {
            SectionCurrentDateTime,
            VueSlider
        },
        computed: {
            ...mapGetters([
                "getLangs",
                "getAppUser",
                "getSettingsValues",
                "getAppUserSettings",
                "getDateFormats",
                "getDateFormat",
                "getTimeFormats",
                "getTimeFormat",
            ]),
            userSettings(){
                return this.getAppUserSettings || {}
            },
            timeZone(){
                return calcUTCOffset(this.user.time_zone*1, this.user.dst)
            },
            userDateTimeFormat(){
                return this.getDateFormat + ' ' + this.getTimeFormat
            },
        },
        methods: {
            ...mapActions([
                "setSectionWidget",
                "saveAppUser",
                "setPopup",
                "clearAppCache"
            ]),
            // onClick_slider(index){
            //     console.log(index);
            // },
            onCancel(){
                this.$emit('close-dialog')
            },
            onSave(){
                localStorage.setItem(process.env.VUE_APP_PRODUCT+'.lang', this.$root.$i18n.locale)
                let trackSpeedColors = this.user.settings.trackSpeedColors
                let speed0 = 0
                this.slider.values.forEach((speed, i) => {
                    //console.log(speed, i)
                    trackSpeedColors[i]['from'] = speed0
                    trackSpeedColors[i]['to'] = speed
                    speed0 = speed
                })
                trackSpeedColors[3]['from'] = speed0
                trackSpeedColors[3]['to'] = 999
                //console.log(trackSpeedColors)

                this.setPopup({
                    title: this.$t('User.title.User settings'),
                    methods: {
                        ok: () => this.onSaveUser(),
                        //cancel: () => {},
                    }
                })
            },
            onSaveUser(){
                let user = this.user
                if(user.id) {
                    let org = JSON.parse(this.org)
                    let diffUnit = diffObjectsRecursiveExceptions(org, user)
                    user = { id: user.id, ...diffUnit}
                }
                //console.log(user)
                this.saveAppUser(user)
                    .then((/*res*/) => {
                        //console.log(res)
                        this.errors = {}
                        this.$toast.success(this.$t("success!"))
                        this.$emit('close-dialog')
                        this.clearAppCache()
                    })
                    .catch((error) => {
                        // Error
                        if (error.response) {
                            console.error(error.response)
                            if(error.response.status == 422){
                                this.errors = error.response.data.reduce((out, error) => {
                                    out[error.field] = error.message
                                    return out
                                }, {})
                            }
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error('Error', error.message);
                        }
                        //console.error(error.config);
                    })
            }
        },
        created() {
            let user = this.getAppUser
            let settings = this.getAppUser.settings || {}
            this.user = {id: user.id, login: user.login, email: user.email, dst: user.dst, time_zone: user.time_zone, units_of_measure: user.units_of_measure, settings}
            //this.settings = {...this.getAppUser.settings}// user.settings.dateFormat,// user.settings.timeFormat,
            let values = (settings.trackSpeedColors || []).map(o => o.to)
            this.slider.values = values.slice(0, 3)
            this.org = JSON.stringify(this.user)
            //console.log('UserSettings created', {...this.user})
        },
        mounted() {
            // console.log('UserSettings mounted', {...this.user})
        },
        updated() {
            // console.log('UserSettings updated', {...this.user})
        },
    }
</script>

<style scoped>

</style>