<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :input_icon="input_icon"
        :error="error"
    >
        <input type="text"
               tabindex="1"
               class="input_hidden-password"
               style="position: absolute; top: -2000px"
               @focus="onFocus"
               v-if="optional_hidden_field"
        >

        <input class="field-group__input"
               :readonly="readOnly"
               :autocomplete="false"
               :class="fieldGroup_input_class"
               :disabled="disabled===true"
               :type="type"
               :id="id"
               :name="id"
               :placeholder="placeholder_text"
               :value="value" @input="$emit('input', $event.target.value)"
               @focus="readOnly = false"
               @blur="readOnly = true"
        >
    </FieldGroup__base_wrap>
</template>
<script>
    export default {
        name: 'FieldGroup_password',
        props: {
            'fieldGroup_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'optional_hidden_field':{
              type: Boolean,
              default: () => false
            },
            'id':String,
            'label':String,
            'value':[Number, String, Array, Object],
            'placeholder':String,
            'error':{
                type:[String, Array, Object],
                default: () => ''
            },
            'info':String,
            'input_type':String,
            'input_icon':String,
            'disabled':Boolean
,        },
        data() {
            return {
                readOnly: true,
            }
        },
        computed: {
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter')+' '+ this.label.toLowerCase()
            },
            type() {
                return 'password'
            },
        },
        methods: {
            onFocus(e){
                e.target.nextElementSibling.focus()
            },
                    },
        mounted() {
            //this.changeReadOnly()
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>
