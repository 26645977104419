import axios from 'axios';
//import http from "./api/http-common";
import store from '../store'
import i18n from '../i18n'

const instance = axios.create({
    //baseURL: `http://92.53.64.78:8883/api`,
    headers: {
        "Content-type": "application/json",
    }
})

instance.defaults.timeout = 30*1000;//10 sec

instance.interceptors.request.use(
    (config) => {
        //const token = this.$cookies.get('token')
        //const token = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.authtoken');
        const token = store.state.security.tokens.access_token
        if (token) {
            config.headers['Authorization'] = store.getters.getHeaderAuthorization || `Bearer ${token}`;
        }
        config.headers['X-Accept-Language'] = i18n.locale || 'en'
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // if (response.status === 200) {
        //     return response;
        // }
        // return Promise.reject(response);
        return response;
    },
    error => {
        if (error) {
            const status = error.response ? error.response.status : -1
            store.commit('addHttpResponseStatus', status)
            if (status === 401) {
                //if(store.state.security.tokens) {
                    store.commit('updateTokens', {})
                //}
                if(!(['/site/logout', '/login']).includes(location.pathname)) {
                    window.location.href = window.location.origin + '/site/logout'
                }
            }
        }
        return Promise.reject(error);
    }
);

export default instance;