<template>
    <div class="aside-menu_wrap" :class="{'aside-menu_wrap__list': hasList}">
        <UserMenu class="menu__hamburger" v-if="!hasList" :class="{'aside-menu_wrap-mobile':getMobileMenuOpen}"/>
        <aside class="section section_type_aside is_new-design" :class="{'aside-menu_wrap-mobile':getMobileMenuOpen}">
            <div class="menu">
                <div class="menu__hamburger" v-if="hasList">
                    <button class="button button_view_icon button_theme_primary mtb__-4" @click="switchMainList">
                        <span class="icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__hamburger" /></span> </span>
                    </button>
                </div>
                <nav class="nav">
                    <ul class="nav__list">
                        <router-link
                            v-for="item in main_menus"
                            :key="item.url"
                            :to="item.url"
                            custom
                            v-slot="{ route, isActive, isExactActive }"
                        >
                            <li class="nav__item item"
                                :class="[isActive && 'item_active', isExactActive && 'router-link-exact-active' ]"
                                @click="onClickMainNavItem(route)"
                                @keypress.enter="onClickMainNavItem(route)"
                            >
                                <div class="nav__icon-box" >
                                    <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="`menu__${item.icon? item.icon : route.meta.menu_icon}`" /></span> </span>
                                    <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" :name="`menu__${item.icon? item.icon : route.meta.menu_icon}_fill`" /></span> </span>
                                </div>
                                <span class="nav__title title">{{item.title ? $t(item.title) : route.name}}</span>
                                <div class="nav__decor decor"></div>
                            </li>
                        </router-link>

                        <template v-if="hasMoreMenu">
                            <router-link
                                v-if="replaceable"
                                :to="replaceable.url"
                                custom
                                v-slot="{ route, isActive, isExactActive }"
                            >
                                <!-- https://next.router.vuejs.org/api/#router-link-s-v-slot -->
                                <li class="nav__item item"
                                    :class="[isActive && 'item_active', isExactActive && 'router-link-exact-active' ]"
                                    @click="onClickMainNavItem(route)"
                                    @keypress.enter="onClickMainNavItem(route)"
                                >
                                    <div class="nav__icon-box" >
                                        <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="`menu__${replaceable.icon? replaceable.icon : route.meta.menu_icon}`" /></span> </span>
                                        <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" :name="`menu__${replaceable.icon? replaceable.icon : route.meta.menu_icon}_fill`" /></span> </span>
                                    </div>
                                    <span class="nav__title title">{{replaceable.title ? $t(replaceable.title) : route.name}}</span>
                                    <div class="nav__decor decor"></div>
                                </li>
                            </router-link>

                            <li class="nav__item item item_type_more"
                                @mouseover="showItemTypeMore = true"
                                @mouseout="showItemTypeMore = false"
                            >
                                <div class="nav__icon-box">
                                    <span class="nav__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__more" /></span> </span>
                                    <span class="nav__icon icon icon_size_32 icon_active"> <span class="icon__symbol"><SvgIcon class="icon" name="menu__more_fill" /></span> </span>
                                </div>
                                <span class="nav__title title">{{ $t('menu.More') }}</span>
                                <div class="nav__decor decor"></div>

                                <div class="nav__dropdown-menu dropdown-menu position_left-center" data-type=""
                                     :class="{'dropdown-menu_open': showItemTypeMore}"
                                >
                                    <div class="dropdown-menu__wrap">
                                        <ul class="dropdown-menu__list">

                                            <router-link
                                                v-for="item in more_menus"
                                                :key="item.url"
                                                :to="item.url"
                                                custom
                                                v-slot="{ route, isActive, isExactActive }"
                                            >
                                                <li class="dropdown-menu__item item "
                                                    :class="[isActive && 'item_active', isExactActive && 'router-link-exact-active' ]"
                                                    @click="onClickDropdownNavItem(item,route)"
                                                    @keypress.enter="onClickDropdownNavItem(item,route)"
                                                    v-show="item.url !== replaceable.url"
                                                >
                                                    <span class="dropdown-menu__icon icon icon_size_32"> <span class="icon__symbol"><SvgIcon class="icon" :name="`menu__${item.icon? item.icon : route.meta.menu_icon}`" /></span> </span>
                                                    <span class="dropdown-menu__item-name">{{item.title ? $t(item.title) : route.name}}</span>
                                                </li>
                                            </router-link>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </nav>
            </div>
            <div class="aside__info info">
                <SectionConnection />
                <SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getTimeFormat"/>
            </div>
        </aside>
    </div>

</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import SectionConnection from "@/components/_base/SectionConnection.vue";
    import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";
    import UserMenu from "@/components/app/UserMenu.vue";

    export default {
        name: "SectionAside",
        props: [],
        data() {
            return {
                showItemTypeMore: false,
                //mainSize: 99,
                replaceable: null,
            }
        },
        components: {
            UserMenu,
            SectionCurrentDateTime,
            SectionConnection
        },
        computed: {
            ...mapGetters([
                "getMobileMenuOpen",
                "showMainList",
                "isLite",
                "isGuest",
                "isDealer",
                "getAppMenus",
                "getUserTimeZone",
                "getTimeFormat",
            ]),
            hasMoreMenu(){
                return (this.getAppMenus.length > this.mainSize)
            },
            mainSize(){
                return this.isLite ? 8 : 15
            },
            main_menus(){
                return this.getAppMenus.slice(0,this.mainSize)
            },
            more_menus(){
                return this.getAppMenus.slice(this.mainSize)
            },
            route_path(){
                return this.$route.path
            },
            hasList(){
                return !!this.$route.meta.list
            }
        },
        watch: {
            route_path: {
                handler: function (newVl, oldVl) {
                    if(newVl !== oldVl){
                        console.log('route_path', newVl, oldVl)
                        this.setRoutePath();
                    }
                }
            },
            hasMoreMenu:{
                handler: function (newVL){
                    if(newVL && !this.replaceable) {
                        this.replaceable = this.getAppMenus[this.mainSize]
                    }
                }
            }
        },
        methods: {
            ...mapMutations([
                "switchMainList",
            ]),
            onClickMainNavItem: function (route) {
                if(!this.showMainList) this.switchMainList()
                if(this.$route.fullPath !== route.fullPath) {
                    this.$router.push(route.fullPath)
                }
            },
            onClickDropdownNavItem: function (item,route) {
                if(!item) return false
                this.showItemTypeMore = false
                this.replaceable = item
                if(this.$route.fullPath !== route.fullPath) {
                    this.$router.push(route.fullPath)
                }
            },
            setRoutePath(){
                if(this.hasMoreMenu && this.$route.path > '/') {
                    let menu = this.more_menus.find(m => {
                        return m.url === this.$route.path
                    })
                    if (menu) {
                        this.replaceable = menu
                    }
                }
            }
        },
        created() {
        },
        mounted() {
        },
        updated() {
        }
    }
</script>

<style scoped>

</style>