

export default {
    state: {
        index: [],
        notificationsEvents: [],
        notificationEvent_active: null,
        notificationsEvents_filter: {
            unitId: '',
            date: '',
            type: '',
            name: '',
        },
    },
    actions: {
        setNotificationEventActive( { commit } , id) {
            commit('setNotificationEventActive' , id)
        },
        async fetchLastNotificationsEvents({ commit, getters }, last) {
            if(!getters.apiToken) {
                return
            }
            last = last || '15 days'
            this.$api.notificationsevents.getLast(last)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateNotificationsEvents', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchNotificationsEvents4Date({ commit, getters }, args) {
            if(!getters.apiToken || !args.date) {//!args.unitId ||
                return
            }
            // let events = getters.getNotificationsEvents.filter(e => {
            //     return e.time.date == args.date //&& e.unit_id == args.unitId
            // })
            // if(events.length){
            //     // return
            // }
            this.$api.notificationsevents.get4Date(args.unitId, args.date, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateNotificationsEvents', response.data)
                        commit('setIndex', {...args, count: response.data.length})
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchNotificationsEvents4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                return
            }
            // let events = getters.getNotificationsEvents.filter(e => {
            //     return //e.unit_id == args.unitId &&
            //         e.time.utc >= args.from && e.time.utc <= args.to
            // })
            // if(events.length){
            //     //return
            // }
            this.$api.notificationsevents.get4Period(args.unitId, args.from, args.to, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateNotificationsEvents', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setNotificationEventActive(state , id){
          return state.notificationEvent_active = id
        },
        setIndex(state, params) {
            state.index.push({...params})
        },
        updateNotificationsEvents(state, events) {
            //state.notificationsEvents = events
            events = events.filter(e => {
                let event = state.notificationsEvents.find(se => {
                    return se.unit_id == e.unit_id
                        && se.time.utc == e.time.utc
                        && se.type == e.type
                })
                return !event
            })
            if(events.length) state.notificationsEvents.push(...events)
        },
        clearNotificationsEvents(state){
            state.notificationsEvents = [];
            state.index = [];
        },
        setNotificationsEventsFilter(state, filter) {
            state.notificationsEvents_filter = filter
        },
    },
    getters: {
        getNotificationEventActive(state){
            return state.notificationEvent_active
        },
        getNotificationsEventsFilter(state){
            return state.notificationsEvents_filter
        },
        getNotificationsEventsIndex(state){
            return state.index
        },
        getNotificationsEventsIndexFiltered(state){
            let filter = state.notificationsEvents_filter
            let index = state.index
            if(filter.date > '') {
                index = index.filter(i => {
                    return i.date == filter.date
                })
            }
            if(filter.unitId > '') {
                index = index.filter(i => {
                    return i.unitId == filter.unitId
                })
            }
            return !!(index.length)
        },
        getNotificationsEventsCount(state){
            return state.notificationsEvents.length
        },
        getNotificationsEvents(state){
            return state.notificationsEvents
        },
        getNotificationsEventsByIds(state){
            return state.notificationsEvents.reduce((out, event) => {
                out[event.id] = event
                return out
            }, {})
        },
        getNotificationsEventsFilteredByUnitDate(state) {
            let filter = state.notificationsEvents_filter

            let events = state.notificationsEvents
            if(filter.date > '') {
                events = events.filter(e => {
                    return e.time.date == filter.date
                })
            }
            if(filter.unitId > '') {
                events = events.filter(e => {
                    return e.unit_id == filter.unitId
                })
            }
            return events
        },
        getNotificationsEventsFiltered(state, getters) {
            let filter = state.notificationsEvents_filter
            let name = filter.name ? filter.name.toLocaleLowerCase() : ''

            let events = getters.getNotificationsEventsFilteredByUnitDate
            if(filter.name > '') {
                events = events.filter(e => {
                    return e.text.toLocaleLowerCase().includes(name)
                })
            }
            if(filter.type > '') {
                events = events.filter(e => {
                    return e['sub-type'] == filter.type
                })
            }
            return events
        },
    }
}
