<template>
    <section
        class="section section_type_info is_new-design"
        :class="{'section_status_open': showSectionInfo,}"
    >
        <component :is="sectionInfo_component"
                   v-bind="sectionInfo_props"
                   v-on:close-dialog="onClose"
        />
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"
    import emptySection from "@/components/app/emptySection.vue"
    import UserSettings from "@/components/app/sectionInfo/UserSettings.vue"
    import UserChangePassword from "@/components/app/sectionInfo/UserChangePassword.vue";
    //import Payment from "@/components/app/sectionInfo/Payment.vue"

    export default {
        name: "SectionInfo",
        // props: ['app'],
        components: {
            emptySection,
            UserSettings,
            UserChangePassword,
            'UserUpgrade':  () => import('./app/sectionInfo/UserUpgrade.vue'),
            // 'Payment':      () => import('./app/sectionInfo/Payment.vue'),

            //units
            'UnitEdit':     () => import('./units/sectionInfo/UnitEdit.vue'),
            'GroupEdit':    () => import('./units/sectionInfo/GroupEdit.vue'),
            'UnitCommand':  () => import('./units/sectionInfo/UnitCommand.vue'),
            'GroupCommand': () => import('./units/sectionInfo/GroupCommand.vue'),
            'UnitLocator':  () => import('./units/sectionInfo/UnitLocator.vue'),

            'UnitInfo':     () => import('./units/sectionInfo/UnitInfo.vue'),
            'UnitChat':     () => import('./units/sectionInfo/UnitChat.vue'),
            'UnitLiveVideo':  () => import('./units/sectionInfo/UnitLiveVideo.vue'),

            //geoitems
            'GeozoneEdit':    () => import('./geoitems/sectionInfo/GeozoneEdit.vue'),
            //notifications
            'NotificationsProps':    () => import('./notifications_events_history/sectionInfo/NotificationsProps.vue'),

        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "showSectionInfo",
                "getSectionInfo",
            ]),
            sectionInfo() {
                return (this.getSectionInfo || {component: 'emptySection', props: {}})
            },
            sectionInfo_component() {
                return this.getSectionInfo ? this.getSectionInfo.component : 'emptySection'
            },
            sectionInfo_props() {
                return this.getSectionInfo ? this.getSectionInfo.props : {}
            },
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
            onClose(){
                this.setSectionInfo(null)
            },
        },
        updated: function() {
            //console.log('SectionInfo updated', this.sectionInfo_component, {...this.sectionInfo_props});
        },
        mounted() {
            // console.log('section_type_info', this)
        },
    }
</script>

<style scoped>

</style>