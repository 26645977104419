<template>
    <!--- Section head --->
    <section class="section__head head is_new-design">
        <div class="head__logo logo">
            <img :src="headLogo">
        </div>
        <UserMenu/>
    </section>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import UserMenu from "./UserMenu.vue";

    export default {
        name: "SectionUserHead",
        components: {UserMenu},
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "getAppUser",
            ]),
            headLogo(){
                let headLogo = this.getAppUser.head__logo
                return  headLogo ? headLogo.url+'?h='+headLogo.hash : require('@/assets/img/empty1px.png')
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
        },
        mounted() {
            // console.log('UserDropdownMenu', this)
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>

<style scoped>

</style>