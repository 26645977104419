<template>
    <section class="section__info info info_view_track pfull__16">
        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__parking"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Parking') }}</h5>
                <p class="parameter__value">
                    {{parking_time}}
                    ({{parkings}} ev.)
                </p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__stop"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Stop') }}</h5>
                <p class="parameter__value">
                    {{stop_time}}
                    ({{stops}} ev.)
                </p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__mileage"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Mileage') }}</h5>
                <p class="parameter__value">
                    {{mileage}} {{distanceUnits}}
                </p>
            </div>

            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__low-battery"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Low bat') }}</h5>
                <p class="parameter__value">
                    {{events['low battery'] || 0}} ev.
                </p>
            </div>
        </div>

        <div class="vertical-line">
            <div class="parameter mb__12">
                <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__signal"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Lost con') }}</h5>
                <p class="parameter__value">
                    {{events['lost connection'] || 0}} ev.
                </p>
            </div>
            <div class="parameter">
                <span class="parameter__icon icon icon_type_circle icon_theme_danger"> <span class="icon__symbol"><SvgIcon class="icon" name="circle__speed_lg"/></span> </span>
                <h5 class="parameter__label">{{ $t('MapInfo.Speed viol') }}</h5>
                <p class="parameter__value">
                    {{events['max speed'] || 0}} ev.
                </p>
            </div>
        </div>

        <div class="vertical-line vertical-line_type_track-legend"
             v-if="trackSpeedColors.length"
        >
            <ul class="track-legend">
                <li class="track-legend__item"
                    v-for="(item, i) in trackSpeedColors"
                    :key="'track-legend__item-'+i"
                >
                    <span class="track-legend__color color"
                          :class="'color_interval-'+(i+1)"
                          :style="{background: '#'+item['color']}"
                    ></span>
                    <span class="track-legend__value">
                        {{item['from']}} - {{item['to']}} &nbsp;{{speedUnits}}
                    </span>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SectionMapTrackInfo',
    data() {
        return {}
    },
    components: {},
    computed: {
        ...mapGetters([
            "getAppUser",
            "getAppUserSettings",
            "getAppUserUnits",

            "getTripsFiltered",
            "getParkingsFiltered",
            "getStopsFiltered",
            "getEventsFiltered",
        ]),
        speedUnits(){
            return this.getAppUserUnits.speed
        },
        distanceUnits(){
            return this.getAppUserUnits.distance
        },
        now(){
            return Date.now()
        },
        no_data() {
            return !(this.getTripsFiltered.length+this.getParkingsFiltered.length)
        },
        parkings(){
            if(this.no_data) return 0
            return this.getParkingsFiltered.length
        },
        stops(){
            if(this.no_data) return 0
            return this.getStopsFiltered.length
        },
        events(){
            if(this.no_data) return {}
            return this.getEventsFiltered.reduce((out, event) => {
                if(!out[event['sub-type']]){
                    out[event['sub-type']] = 0
                }
                out[event['sub-type']]++
                return out
            }, {})
        },
        parking_time(){
            if(this.no_data) return ''
            let duration = this.getParkingsFiltered.reduce((out,p) => {
                out += p.duration.sec
                return out
            },0)
            duration = {
                h: Math.floor(duration / 3600),
                m: Math.floor((duration % 3600) / 60),
            }
            return duration.h > 0 ? duration.h+'h '+duration.m+'m' : duration.m+'m'
        },
        stop_time(){
            if(this.no_data) return ''
            let duration = this.getStopsFiltered.reduce((out,s) => {
                out += s.duration.sec
                return out
            },0)
            duration = {
                h: Math.floor(duration / 3600),
                m: Math.floor((duration % 3600) / 60),
            }
            return duration.h > 0 ? duration.h+'h '+duration.m+'m' : duration.m+'m'
        },
        mileage(){
            if(this.no_data) return 0
            let mileage = this.getTripsFiltered.reduce((out,t) => {
                out += t.mileage*1.0
                return out
            },0)
            return Math.floor(mileage*100)/100
        },
        trackSpeedColors(){
            //let settings = this.getAppUserSettings
            let trackSpeedColors = this.getAppUserSettings.trackSpeedColors || []
            return trackSpeedColors
        },
    },
    methods: {
        ...mapActions([
        ]),
    },
    updated() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>

</style>