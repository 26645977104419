<template>
    <!--- Section type Global --->
    <section id="app"
             class="section section_type_global is_new-design"
             @click="onClick"
    >
        <!--- Section type Aside --->
        <SectionAside v-show="!isGuest && hasAside"/>
        <!--- Section type Main-side --->
        <SectionMainside/>
        <!--- -- --->
        <custom-modal v-model="getPopup.show" @confirm="confirm" @cancel="cancel"
                      :confirm_text="getPopup.buttonOk || $t('buttons.Ok')"
                      :cancel_text="getPopup.buttonCancel || $t('buttons.Cancel')">
            <template v-slot:title>{{ getPopup.title }}</template>
            <p v-html="getPopup.text || $t('popup.Are you sure')"></p>
        </custom-modal>
    </section>
</template>

<script>
//import app from './app'

import {mapGetters, mapActions, mapMutations} from "vuex";
import SectionAside from '@/components/SectionAside.vue'
import SectionMainside from '@/components/SectionMainside.vue'

export default {
    name: 'sectionGlobal',
    components: {
        SectionAside, SectionMainside
    },
    data: () => ({
        interval: null,
        app: {}
    }),
    computed: {
        ...mapGetters([
            "isGuest",
            "getTokens",
            "apiToken",
            "apiTokenExpiresIn",
            "getLangs",
            "getAppUser",
            "getUserStorageKey",
            "showSectionInfo",
            "showSectionWidget",
            "showFilterDropdownMenu",
            "showMainList",
            "getGeozoneActive",
            "getNotificationEventActive",
            "getEventActive",
            "getUnitActive",
            "unitTracking",
            "getPopup"
        ]),
        route_name(){
            return this.$route.name
        },
        hasAside() {
            return true
        },
    },
    watch: {
        apiToken: {
            handler: function (newVl, oldVl) {
                if(!(newVl > ' ') && (oldVl > ' ') && this.route_name !== 'Login'){
                    this.$router.push('/login')
                } /*else
                if(newVl > ' ' && !(oldVl > ' ') && this.route_name === 'Login'){
                    this.$router.push('/units')
                }*/
            }
        },
    },
    created() {

        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let token = params.get("access-token");

        if( window.location.pathname === "/login" && token>''){
            localStorage.setItem(process.env.VUE_APP_PRODUCT+'.authtoken', token)
            //this.refreshTokens(token)
        }

        this.$router.beforeEach(async (to, from, next) => {
            // console.log('beforeEach', to.name, from.name)
            if(!this.showMainList) this.switchMainList()
            if(this.showSectionInfo) this.setSectionInfo(null)
            if(this.showSectionWidget) this.setSectionWidget(null)
            if(this.showFilterDropdownMenu) this.setShowFilterDropdownMenu(false)
            //Geo
            if(this.getGeozoneActive) this.setGeozoneActive(null)
            //NotificationEvents
            if(this.getNotificationEventActive) this.setNotificationEventActive(false)
            //Events
            if(this.getEventActive) this.setEventActive(false)
            //Units
            if(to.name === 'Units'){
                // let unitId = localStorage.getItem(this.getUserStorageKey + '.unit.active')*1
                let unitId = this.getUnitActive
                //console.log('beforeEach', to.name, from.name, unitId)
                if(unitId) {
                    this.setUnitTracking(unitId)
                }
            } else
            if (from.name === 'Units'){
                this.setUnitTracking(0)
            }
            //Tracks
            if (from.name === 'Tracks'){
                this.setShowUnitTrackIndex(null)
                this.setUnitMoveOnTrack(null)
                this.setShowMarkerOnTrack(null)
            }

            //console.log('beforeEach', to)
            if (this.isGuest && to.name !== 'Login' && !to.meta.isGuest) {
                next({ name: 'Login' })
            } else
            if (to.name === 'Login' && !this.isGuest){
                next({ name: 'Units' })
            } else {
                next()
            }
        });
    },
    async mounted() {
        //locale
        let lang = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.lang')
        lang = this.getLangs.map(l => l.id).includes(lang)? lang : 'en'
        this.$root.$i18n.locale = lang

        //tokens
        let token = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.authtoken')
        // this.$cookies.get('authtoken')
        await this.refreshTokens(token)

        this.interval = setInterval(() => {
            let t = Math.round(Date.now() / 1000)
            this.incrementUnitsLmsgTimeAgo()
            if(t % 10 === 0) {
                this.fetchUnits()
                this.fetchUnitsLmsgs({});

                this.calcTokensExpire(t)
            }
            if(this.apiTokenExpiresIn < 300 || !(this.apiToken>'')) {
                if(t % 10 === 5) {
                    this.refreshTokens()
                }
            }
        }, 1000)

        if (this.isGuest) {
            let guestUrls =  this.$router.getRoutes()
                .filter(r => { return !(!r.meta.isGuest) })
                .map(r => { return r.path })
            if (!guestUrls.includes(this.$route.path)) {//!this.$route.meta.isGuest
                this.$router.push('/login')
            }
            // window.location.href = window.location.origin+'/site/logout'
        } else {
            this.fetchAppProps()

            if (this.$route.name === 'Login') {
                this.$router.push('/units')
            }else
            if (this.$route.name === 'Units' && this.$route.path !== '/units') {
                this.$router.push('/units')
            }
        }
    },
    beforeDestroy() {
        if(this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    },
    methods: {
        ...mapMutations([
            //"calcTokensExpire",
            "setShowFilterDropdownMenu",
            "setShowUserMenu",
            "switchMainList",
            "setAllUnitsShowOnMap"
        ]),
        ...mapActions([
            "refreshTokens",
            "calcTokensExpire",
            "fetchAppProps",
            "incrementUnitsLmsgTimeAgo",

            "fetchUnitsLmsgs",
            "fetchUnits",

            "setSectionInfo",
            "setSectionWidget",
            "setShowUnitTrackIndex",
            "setUnitMoveOnTrack",
            "setShowMarkerOnTrack",
            "setNotificationEventActive",
            "setEventActive",
            "setGeozoneActive",
            "setUnitActive",
            "setUnitTracking",
            "setPopup"
        ]),
        confirm() {
            if(this.getPopup.methods.ok && typeof this.getPopup.methods.ok === "function") {
              this.getPopup.methods.ok()
            }
            this.setPopup(null)
        },
        cancel(close) {
            if(this.getPopup.methods.cancel && typeof this.getPopup.methods.cancel === "function") {
              this.getPopup.methods.cancel()
            }
            this.setPopup(null)
            close()
        },
        onClick(e){
            //console.warn('onClick', e.target)
            // Close the dropdown if the user clicks outside of it
            let onDropdownMenu = e.target.matches('.filter-search__dropdown-menu *')
                || e.target.matches('.button_dropdown-menu') || e.target.matches('.button_dropdown-menu *')
            if(!onDropdownMenu) {
                this.setShowFilterDropdownMenu(false)
            }
            this.setShowUserMenu(false)
        },
        isAuthenticated(){
            let token = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.authtoken')
            return (token && token>'')
        },
    }
}
</script>

<style lang="scss">
@import './scss/main.scss';

.section.section_type_login {
    background: url("~@/assets/img/empty1px.png");
    background-size: cover;
}

.section.section_type_form-page {
    background: url("~@/assets/img/empty1px.png");
    background-size: cover;
}
</style>
