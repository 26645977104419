export default {

    state: {
        index: [],
        tracks: [],
        tracks_filter: {unitId: '', date: ''}
    },
    actions: {
        async fetchTracks4Date({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            let tracks = getters.getTracks.find(t => {
                return t.unit_id == args.unitId && t.date == args.date
            })
            if(tracks){
                return
            }
            this.$api.tracks.get4Date(args.unitId, args.date, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTracks', response.data)
                        commit('setIndex', {...args, count: response.data.length})
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchTracks4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let track = getters.getTracks.find(t => {
                return t.unit_id == args.unitId && t.timeFrom==args.from && t.timeTo==args.to
            })
            if(track){
                return
            }
            this.$api.tracks.get4Period(args.unitId, args.from, args.to, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeTrack', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        setIndex(state, params) {
            state.index.push({...params})
        },
        storeTracks(state, tracks) {
            //state.tracks = tracks
            tracks.forEach(t => {
                let track = state.tracks.find(st => {
                    return st.unit_id == t.unit_id
                        && st.time_start.utc == t.time_start.utc
                        && st.time_end.utc == t.time_end.utc
                })
                if(!track) state.tracks.push(t)
            })
        },
        storeTrack(state, track) {
            // state.tracks.push(track)
            let tr = state.tracks.find(st => {
                return st.unit_id == track.unit_id
                    && st.timeFrom == track.timeFrom
                    && st.timeTo == track.timeTo
            })
            if(!tr) state.tracks.push(track)
        },
        clearTracks(state){
            state.tracks = [];
            state.index = [];
        },
        setTracksFilter(state, filter) {
            state.tracks_filter = filter
        },
    },
    getters: {
        getTracksFilter(state){
            return state.tracks_filter
        },
        getTracksIndex(state){
            return state.index
        },
        getTracksIndexFiltered(state){
            let filter = state.tracks_filter
            let index = state.index
            if(filter.date > '') {
                index = index.filter(i => {
                    return i.date === filter.date
                })
            }
            if(filter.unitId > '') {
                index = index.filter(i => {
                    return i.unitId === filter.unitId
                })
            }
            return !!(index.length)
        },
        getTracks(state){
            return state.tracks
        },
        getTracksFiltered(state){
            let filter = state.tracks_filter
            let tracks = state.tracks
            if(filter.date > '') {
                tracks = tracks.filter(t => {
                    return t.date == filter.date
                })
            }
            if(filter.unitId > '') {
                tracks = tracks.filter(t => {
                    return t.unit_id == filter.unitId
                })
            }
            return tracks
        },
    }
}
