<template>
    <section class="section section_type_loader loader "
             :class="{'loader_status_active': inprogress}"
    >
        <div class="container">
            <div class="dot-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <div class="dot-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <div class="dot-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <span class="loader__text">
            {{ (loader_text) || $t('Loading_') }}
        </span>
    </section>
</template>

<script>
export default {
    name: "SectionLoading",
    props: {
        'inprogress': Boolean,
        'loader_text': {
            type: String,
            default: null,
        },
    },
    data() {
        return {}
    },
}
</script>

<style scoped>

</style>