const RESOURCE_NAME = '/v1/parkings';

export default axios => ({
    getLast(last, params) {
        //?last-days=15days
        return axios.get(`${RESOURCE_NAME}/last/${last}`, {params});
    },
    get4Date(unitId, date, data, params) {
        if(!(unitId > '')){
            //?date=y-m-d
            return axios.get(`${RESOURCE_NAME}?date=${date}`, data, {params});
        }
        return axios.get(`/v1/unit/${unitId}/parkings/${date}`, data, {params});
    },
    get4Period(unitId, from, to, data, params) {
        if(!(unitId > '')){
            //?start-time=int&end-time=int
            return axios.get(`${RESOURCE_NAME}?start-time=${from}&end-time=${to}`, data, {params});
        }
        return axios.get(`/v1/unit/${unitId}/parkings/${from}/${to}`, data, {params});
    },
    getStatistics(unitId, params) {
        return axios.get(`/v1/unit/${unitId}/parkings/statistics`, {params});
    },
});