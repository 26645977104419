// import Vue from 'vue'
import {objectComparison} from "@/lib/lib";

const unitsExpand = ['user_creator','access_right']//,counters
//const unitsFields = ['id','hw_type','hw_id','name','type','unit_icon','icon','max_speed','phone']

export default {
    state: {
        unitsGroups: [],
        unitsGroups_sorted: [],
        unitsGroups_index: [],

        units: [],
        unit_active: null,
        units_sorted: [],
        units_index: [],
        units_filter: {
            signal: false,
            movement: false,
            type: false,
            name: '',
        },

        unitsLmsgs: [],
        unitsLmsgs_index: [],
    },
    actions: {
        async setUnitActive({ commit, dispatch }, id) {
            commit('setUnitActive', id)
            //commit('setUnitTracking', id)
            commit('setAllUnitsShowOnMap', [id])
            dispatch('setMapBoundsByUnits', [id])
            // dispatch('fetchUnitWeather', id)
        },

        async fetchUnitsGroups({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            this.$api.unitsgroups.getAll()
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateUnitsGroups', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchUnits({ commit, getters }) {//, dispatch
            if(!getters.apiToken) {
                return
            }
            const params = {expand: unitsExpand.join()}//, fields: unitsFields.join()}
            this.$api.units.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        let firstLoad = !getters.units.length //&& !getters.getUnitActive
                        commit('updateUnits', response.data)
                        if (firstLoad) {
                            let unitId = localStorage.getItem(getters.getUserStorageKey + '.unit.active') * 1
                            if (response.data && response.data.length) {
                                let unit = response.data.find(u => u.id == unitId)
                                if (!unit || unit.license.isExpired) {
                                    unit = response.data.find(u => !u.license.isExpired)
                                }
                                unitId = (unit || {}).id
                            }
                            //console.log('fetchUnits', getters.getUnitActive, unitId)
                            if (!getters.getUnitActive && unitId) {
                                commit('setUnitActive', unitId)
                                commit('setAllUnitsShowOnMap', [unitId])
                                //dispatch('fetchUnitWeather', unitId)
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
            //dispatch('fetchUnitsLmsgs', {force: true})
        },

        async fetchUnitsLmsgs({ commit, getters }, args={}) {
            if(args.force) console.log('fetchUnitsLmsgs', args.force, Date.now()/1000)
            if(!getters.apiToken) {
                return
            }
            let params = {withAddress: 1}
            if(!args.force) params.lmsgtimes = getters.unitsLmsgsTimeByIds
            this.$api.units.getLmsgs(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        if (response.data && response.data.length) {
                            commit('updateUnitsLmsgs', response.data)
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

            /*if(!args.force)
            {
                setTimeout(function () {
                    dispatch('fetchUnitsLmsgs', {});
                }, 10 * 1000)
            }*/
        },

        async fetchUnitFullInfo({ commit, getters }, id) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: unitsExpand.join()}
            this.$api.units.getFull(id, params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateUnit', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchUnitLmsg({ commit, getters }, id) {
            if(!getters.apiToken) {
                return
            }
            const params = {lmsgtime: getters.unitsLmsgsTimeByIds[id], withAddress: 1}
            this.$api.units.getLmsg(id, params)
                .then((response) => {
                    if(response.status < 400 && response.data) {
                        commit('updateUnitsLmsgs', [{...response.data}])
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async saveUnit({ dispatch }, unit) {
            let fn = (unit.id) ? 'updateUnit' : 'createUnit'
            return dispatch(fn, unit);
        },
        async createUnit({ commit, dispatch }, unit) {
            return new Promise((resolve, reject) => {
                const params = {expand: unitsExpand.join()}//, fields: unitsFields.join()}
                this.$api.units.create(unit, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnits', {})
                            dispatch('fetchUnitsKeysStatistics', {'filter[free]': 1})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnit({ commit, dispatch }, unit) {
            return new Promise((resolve, reject) => {
                const params = {expand: unitsExpand.join()}//, fields: unitsFields.join()}
                this.$api.units.update(unit.id, unit, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnits', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnit({ commit, dispatch }, id) {//remove
            return new Promise((resolve, reject) => {
                const params = {expand: unitsExpand.join()}
                this.$api.units.delete(id, params)//remove
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUnit', id)
                            dispatch('fetchUnits', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async restoreUnit({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                const params = {expand: unitsExpand.join()}//, fields: unitsFields.join()}
                this.$api.units.restore(id, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnit', response.data)
                            dispatch('fetchUnits', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitPermanently({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                const params = {expand: unitsExpand.join()}
                this.$api.units.deletePermanently(id, params)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUnit', id)
                            dispatch('fetchUnits', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        async updateUnitCounters({ commit },args) {
            return new Promise((resolve, reject) => {
                const params = {expand: unitsExpand.join()}//, fields: unitsFields.join()}
                this.$api.units.updateCounters(args.id, args.counters, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            console.log(response.data)
                            debugger
                            commit('updateUnitLmsgCounters', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },


        async fetchUnitLiveVideo({ getters }, id) {//commit,
            if(!getters.apiToken) {
                return false
            }
            let rs = false
            await this.$api.units.liveVideo(id, {})
                .then((response) => {
                    console.log(response)
                    rs = response.data
                })
                .catch((error) => {
                    console.error(error);
                });

            return rs
        },

        async saveUnitCounters({ getters }, id) {//commit,
            if(!getters.apiToken) {
                return false
            }
            let rs = true
            let unit = getters.unitsByIds[id]
            await this.$api.units.updateCounters(id, unit.counters)
                .then((response) => {
                    console.log(response)
                    //commit('updateUnit', response.data)
                })
                .catch((error) => {
                    console.error(error);
                    rs = false
                });
            return rs
        },

        async incrementUnitsLmsgTimeAgo({ commit }){
            commit('incrementUnitsLmsgTimeAgo')
        },

        async sendUnitCmd({ getters }, args){
            if(!getters.apiToken) {
                return
            }
            return new Promise((resolve, reject) => {
                this.$api.units.sendCmd(args.id, args)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            //???
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        }

        //sayHello() {}
    },
    mutations: {
        setUnitActive(state , id){
            state.unit_active = id
        },
        updateUnitProp(state, arg) {
            let unit = state.units.find(u => {return u.id == arg.unitId})
            if(!unit){
                console.error('no unit', arg.unitId)
            }else
            if(arg.prop) {
                unit[arg.prop.name] = arg.prop.value
            }else
            if(arg.info) {
                unit.info[arg.info.name] = arg.info.value
            }else
            if(arg.counter) {
                if (!unit.counters || !unit.counters.length) {
                    unit.counters = [{type: arg.counter.type, value: arg.counter.value}]
                } else {
                    let counter = unit.counters.find(c => { return c.type == arg.counter.type })
                    if (!counter) {
                        unit.counters.push({type: arg.counter.type, value: arg.counter.value})
                    }else {
                        counter.value = arg.counter.value
                    }
                }
            }
        },

        updateUnitsGroups(state, nGroups) {
            state.unitsGroups = nGroups
            // if(!state.unitsGroups.length) {
            //     state.unitsGroups = nGroups
            // }else{
            //     let nId = nGroups.map(ng=> ng.id)
            //     let groups = state.unitsGroups.filter( g => nId.includes(g.id) )
            //     if(groups.length !== state.unitsGroups.length){
            //         state.unitsGroups = groups
            //     }
            //     nGroups.forEach(function (nGroup) {
            //         let i = state.unitsGroups.findIndex(g => {return g.id == nGroup.id})
            //         if(i<0) {
            //             state.unitsGroups.push(nGroup)
            //         }else{
            //             let oGroup = state.unitsGroups[i]
            //             let cmp = objectComparison(nGroup, oGroup, ['changed__time',...unitsExpand])
            //             if(!cmp){
            //                 let group = Object.assign({},state.unitsGroups[i],nGroup)
            //                 state.unitsGroups.splice(i, 1, group)
            //             }
            //         }
            //     })
            // }
            state.unitsGroups_index = nGroups.map(g => g.id)
            //state.unitsGroups_sorted = nGroups.map(g => g.id)
        },
        updateUnits(state, nUnits) {
            if(!state.units.length) {
                state.units = nUnits
            }else{
                let nId = nUnits.map(nu=> nu.id)
                let units = state.units.filter( u => nId.includes(u.id) )
                if(units.length !== state.units.length){
                    state.units = units
                }
                nUnits.forEach(function (nUnit) {
                    let i = state.units.findIndex(u => {return u.id == nUnit.id})
                    if(i<0) {
                        state.units.push(nUnit)
                    }else{
                        let oUnit = state.units[i]
                        let cmp = objectComparison(nUnit, oUnit, ['changed__time','max_speed',...unitsExpand])
                        if(!cmp){
                            let unit = Object.assign({},state.units[i],nUnit)
                            state.units.splice(i, 1, unit)
                        }
                    }
                })
            }
            state.units_index = state.units.map(u => u.id)
            // state.units_sorted = state.units.map(u => u.id)
        },
        updateUnit(state, nUnit) {
            let i = state.units.findIndex(u => {return u.id == nUnit.id})
            if(i<0) {
                state.units.push(nUnit)
            }else{
                let oUnit = state.units[i]
                let cmp = objectComparison(nUnit, oUnit, ['changed__time','max_speed',...unitsExpand])
                if(!cmp){
                    let unit = Object.assign({},state.units[i],nUnit)
                    state.units.splice(i, 1, unit)
                }
            }
        },
        deleteUnit(state, id) {
            state.units = state.units.filter(u => {return u.id != id})
        },

        clearUnitsMaxSpeed(state){
            state.units = state.units.map(u => { return {...u, changed__time: 0, max_speed: null}})
        },
        clearUnitsLmsgsSpeed(state){
            state.unitsLmsgs = state.unitsLmsgs.map(u => { return {...u, time: 0, speed: null}})
        },

        incrementUnitsLmsgTimeAgo(state){
            state.unitsLmsgs.forEach(lmsg => {
                //if(lmsg.timeAgo*1 === lmsg.timeAgo)
                lmsg.timeAgo++
            })
        },
        updateUnitLmsgCounters(state, nLmsg) {
            let indx = state.unitsLmsgs.findIndex(l => {return l.id_unit == nLmsg.id_unit })
            //let unitsLmsgs = state.unitsLmsgs.filter(l => {return l.id_unit != nLmsg.id_unit })
            if(indx>=0) {
                let lmsg = state.unitsLmsgs[indx]
                //if(lmsg.time < nLmsg.time)
                {
                    let counters = nLmsg.counters
                    lmsg = ({...lmsg, counters})
                    state.unitsLmsgs.splice(indx, 1, lmsg)
                    //Vue.set(state.unitsLmsgs, indx, lmsg)
                }
            }else{
                //Vue.set(state.unitsLmsgsObj, nl.id, lmsg)
                state.unitsLmsgs.push(nLmsg)
            }
        },
        updateUnitsLmsgs(state, lmsgs) {
            /*
            //vue recommended
            const chunks = splitIntoChunks(data, 10).reverse();

            const pushOnRenderTask = () => {
              if (chunks.length === 0) return;
              state.elements.push(chunks.pop());

              requestAnimationFrame(pushOnRenderTask);
            }

            pushOnRenderTask();
            */
            if(!state.unitsLmsgs.length){
                state.unitsLmsgs = lmsgs
                return true
            }

            let chunks = []
            let chunk_length = 10
            while (lmsgs.length > 0) {
                let chunk = lmsgs.splice(0,chunk_length)
                chunks.push(chunk)
            }

            const pushOnRenderTask = () => {
                if (chunks.length === 0) return;
                let lmsgs_chunk = chunks.pop();

                let count = 0
                lmsgs_chunk.forEach(nLmsg => {
                    let indx = state.unitsLmsgs.findIndex(l => {return l.id_unit == nLmsg.id_unit })
                    //let unitsLmsgs = state.unitsLmsgs.filter(l => {return l.id_unit != nLmsg.id_unit })
                    if(indx>=0) {
                        let lmsg = state.unitsLmsgs[indx]
                        if(lmsg.time < nLmsg.time) {
                            lmsg = ({...lmsg, ...nLmsg})
                            state.unitsLmsgs.splice(indx, 1, lmsg)
                            //Vue.set(state.unitsLmsgs, indx, lmsg)
                            count++
                        }
                    }else{
                        //Vue.set(state.unitsLmsgsObj, nl.id, lmsg)
                        state.unitsLmsgs.push(nLmsg)
                        count++
                    }
                })
                if(count<0) console.log('updateUnitsLmsgs',count)

                requestAnimationFrame(pushOnRenderTask);
            }

            pushOnRenderTask();
        },
        setUnitsFilter(state, filter) {
            state.units_filter = filter
        },
    },
    getters: {
        getUnitActive(state){
          return state.unit_active
        },
        hasUnitActiveVideo(state){
            let unitId = //this.unitTracking ||
                state.unit_active
            let unit = state.units.find(u => u.id == unitId) || {}
            return !!unit.video
        },
        getUnitActiveVideoStatus(state){
            let unitId = //this.unitTracking ||
                state.unit_active
            let unit = state.units.find(u => u.id == unitId) || {}
            return (unit.video || {}).status
        },
        getUnitActiveCameras(state){
            let unitId = //this.unitTracking ||
                state.unit_active
            let unit = state.units.find(u => u.id == unitId) || {}
            return (unit.video || {}).cameras || []
        },

        unitsGroups(state){
            return state.unitsGroups.filter(g => {
                return g.units && g.units.length
            })
        },
        unitsGroupsByIds(state){
            return state.unitsGroups.reduce((out, g) => {
                out[g.id] = g
                return out
            }, {})
        },
        units(state){
            return state.units
        },
        unitsActivated(state, getters){
            return state.units.filter( u => u.license && u.license.expired && u.license.expired.utc >= getters.getTimeToday)
        },
        unitsByIds(state){
            return state.units.reduce((out, unit) => {
                out[unit.id] = unit
                return out
            }, {})
        },
        unitsLmsgs(state){
            return state.unitsLmsgs
        },
        unitsLmsgsIds(state){
            return state.unitsLmsgs.map(lmsg => lmsg.id_unit)
        },
        unitsLmsgsTimeByIds(state){
            return state.unitsLmsgs.reduce((out, lmsg) => {
                if(lmsg.time)
                    out[lmsg.id_unit] = lmsg.time
                return out
            }, {})
        },
        unitsLmsgsLatlngByIds(state){
            return state.unitsLmsgs.reduce((out, lmsg) => {
                if(lmsg.latlng)
                    out[lmsg.id_unit] = lmsg.latlng
                return out
            }, {})
        },
        unitsLmsgsByIds(state){
            // return state.unitsLmsgsObj
            return state.unitsLmsgs.reduce((out, lmsg) => {
                out[lmsg.id_unit] = lmsg
                return out
            }, {})
        },
        getUnitsMarkers: (state/*, getters*/) => {
            return state.units.reduce((out, unit) => {
                    let lmsg = state.unitsLmsgs.find(lmsg => {return lmsg.id_unit == unit.id}) || {}
                    // if (lmsg && lmsg.latlng)// && unitLmsgs.timeAgo < 30*24*60*60)
                    {
                        // let type = getters.getFilterSearchLists.Units.types.find(t => {return t.id == unit.type })
                        // let unit_icon = "common__"+(type ? type.icon : 'car')+"_lg"
                        let unit_icon = unit.icon || 'map__car_type_sedan'
                        let icon_color = unit.icon_color || '#003B79'
                        let icon_has_halo = !(!unit.icon_halo)
                        let style = 'color: '+icon_color+';'
                        if(lmsg.course) style += 'transform: rotate('+lmsg.course+'deg);'
                        let img_src =
                            '/img/icons.svg#'+unit_icon
                            // '/img/unit/'+unit_icon+'.svg'
                            // '/img/unit/common__car.png'
                        let iconHtml =
                            '<div class="unit-marker '+(icon_has_halo? 'unit-marker-has-halo':'')+'">'+
                            '<div class="unit-marker-svg-icon" style="'+style+'">' +
                            // '<img class="svg-icon-unit-marker" src="'+img_src+'" sty-le="color:'+icon_color+'" style="'+style+'" width="50px" height="50px" >' +
                            '<svg class="svg-icon-unit-marker" xmlns="http://www.w3.org/2000/svg">' +
                            '<use xlink:href="'+img_src+'"/>' +
                            '</svg>' +
                            '</div>'+
                            // '<img class="unit-marker-icon" src="'+unit_icon+'" />'+
                            // '<img class="unit-ignition-icon" src="/img/ignition_icon.png" />'+
                            // '<img class="unit-moving-icon" src="/img/moving_icon.png" />'+
                            // '<img class="unit-status-icon" src="/img/unit-icon-status-motion.png" style="transform: rotateZ('+lmsg.course+'deg)'+';"/>'+
                            //                                 // /img/unit-icon-status-parking-more-5-minutes.png
                            //                                 // /img/unit-icon-status-parking-more-15-minutes.png
                            '<div class="leaflet-label unit-marker-label" >' + unit.name + '</div>' +
                            '</div>'

                        out[unit.id] = {
                            id: unit.id,
                            name: unit.name,
                            // type: unit.type,
                            icon: unit_icon,
                            icon_color: icon_color,
                            icon_has_halo: icon_has_halo,

                            time: lmsg.time,
                            latlng: lmsg.latlng,
                            course: lmsg.course,
                            // speed: lmsg.speed,
                            iconHtml: iconHtml,
                        }
                    }
                return out
            }, {})
        },
        unitsFilter(state){
            return state.units_filter
        },

        filteredUnits(state) {
            let filter = state.units_filter
            let name = filter.name.toLocaleLowerCase()

            let units = state.units
            if(filter.type > '') {
                units = units.filter(u => {
                    return u.type === filter.type
                })
            }
            if(filter.name > '') {
                units = units.filter(u => {
                    return u.name.toLocaleLowerCase().includes(name)
                })
            }
            if(filter.signal > '' || filter.movement > '') {
              let t15m = Date.now()/1000 - 15*60
              units = units.filter(u => {
                // return (!ids) ? true : ids.includes(u.id)
                let lmsg = state.unitsLmsgs.find(lmsg => {
                    return lmsg.id_unit == u.id
                })
                if(!lmsg) return false

                let isActive = (lmsg.time >= t15m)
                let isMoving = (lmsg.speed > 3)
                let rt = true
                rt &= (!filter.signal) ? true :
                    (filter.signal==='on') ? isActive :
                    (filter.signal==='off') ? !isActive : false
                rt &= (!filter.movement) ? true :
                    (filter.movement==='moving')? isMoving :
                    (filter.movement==='stopped')? !isMoving : false
                return rt
              })
            }
            //ToDo sort???
            let collator = new Intl.Collator();
            units.sort(function (a, b) {
                let cmp = collator.compare(a.name, b.name)
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            return units.map(u => u.id)
        },
        unitsCount(state, getters) {
            return getters.filteredUnits.length
        },
        unitsGroupsFiltered(state, getters){
            return getters.unitsGroups.reduce((out, g) => {
                let units = g.units.filter(id => {
                    return getters.filteredUnits.includes(id)
                })
                out.push({
                    ...g,
                    units: units,
                })
                return out
            }, [])
        },
    }
}
