<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title">{{ $t('menu.Change Password') }}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <section class="section__container container container_active separator-when-scrolling pfull__16">

                <FieldGroup_password
                    :field-group_class="'field-group mb__36'"
                    :id="'old_password'"
                    :label="'Old password'"
                    :optional_hidden_field="true"
                    :error="errors.old_password"
                    v-model="user.old_password"
                    v-if="false"
                />

                <FieldGroup_password
                    :field-group_class="'field-group mb__12'"
                    :id="'new_password'"
                    :label="'New password'"
                    :optional_hidden_field="true"
                    :error="errors.new_password"
                    v-model="user.new_password"
                />
                <FieldGroup_password
                    :field-group_class="'field-group mb__12'"
                    :id="'repeat_password'"
                    :label="'Repeat password'"
                    :optional_hidden_field="true"
                    :error="errors.repeat_password"
                    v-model="user.repeat_password"
                />

            </section>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{ $t('buttons.Save') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
    name: "UserChangePassword",
    props: [],
    data() {
        return {
            org: {},
            user: {},
            errors: {},
        }
    },
    validations: {
        user: {
            new_password: { required, minLength: minLength(6) },
            repeat_password: { required, sameAsPassword: sameAs('new_password') },
        }
    },
    computed: {
        ...mapGetters([
            "getAppUser",
        ]),
    },
    methods: {
        ...mapMutations([
            "updateTokens",
        ]),
        ...mapActions([
            "saveAppUser",
            "setPopup",
            "clearAppCache"
        ]),
        validateUser(){
            let components = ['UserChangePassword']
            this.$v.$touch()
            this.errors = {}
            this.componentsErrors = [];
            let hasError;
            if (this.$v.$invalid) {
                if(components.includes('UserChangePassword')){
                    hasError = false;
                    //old_password
                    // if(!this.$v.user.old_password.required) {
                    //     hasError =
                    //     this.errors.old_password= 'Old password is required'
                    // }
                    //new_password
                    if(!this.$v.user.new_password.required) {
                        hasError =
                        this.errors.new_password= 'New password is required'
                    }else
                    if(!this.$v.user.new_password.minLength){
                        let min = this.$v.user.new_password.$params.minLength.min
                        hasError =
                        this.errors.new_password= 'New password must be minimum '+min+' characters'
                    }
                    //repeat_password
                    if(!this.$v.user.repeat_password.required) {
                        hasError =
                        this.errors.repeat_password= 'Repeat password is required'
                    }else
                    if(!this.$v.user.repeat_password.sameAsPassword){
                        // new_password != repeat_password
                        hasError =
                        this.errors.repeat_password= 'New Password and Repeat Password should match'
                    }
                    if(hasError){
                        this.componentsErrors.push('UserChangePassword')
                    }
                }
            }
        },
        onCancel(){
            this.$emit('close-dialog')
        },
        onSave(){
            this.validateUser()
            if(Object.keys(this.errors).length)
                return false // stop here if form is invalid
            this.setPopup({
                title: this.$t('menu.Change Password'),
                methods: {
                    ok: () => this.onSaveUser(),
                    //cancel: () => {},
                }
            })
        },
        updateUserTokens() {
            let data = {
                username: this.getAppUser.login,
                password: this.user.new_password,
                lang: this.$root.$i18n.locale,
            }
            this.$api.site.login(data)
                .then((response) => {
                    console.log(response);
                    if(response.data && !response.data.error){
                        this.updateTokens(response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        onSaveUser(){
            //let user = {...this.user, id: this.getAppUser.id}
            //this.user.id = this.getAppUser.id
            this.saveAppUser(this.user)
                .then((/*res*/) => {
                    //console.log(res)
                    this.updateUserTokens()
                    this.errors = {}
                    this.$toast.success(this.$t("success!"))
                    this.$emit('close-dialog')
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        console.error(error.response)
                        if(error.response.status == 422){
                            this.errors = error.response.data.reduce((out, error) => {
                                out[error.field] = error.message
                                return out
                            }, {})
                        }
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    //console.error(error.config);
                })
        }
    },
    created() {
        // console.log('UserSettings created', this.user)
        this.user = this.org = {
            id: this.getAppUser.id,
            login: this.getAppUser.login,
            //'old_password': '',
            'new_password': '',
            'repeat_password': '',
        }
    },
    mounted() {
        // console.log('UserSettings mounted', this.user.settings)
    },
    updated() {
        // console.log('UserSettings updated', this.user.settings)
    },
}
</script>

<style scoped>

</style>