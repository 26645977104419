const RESOURCE_NAME = '/v1/exports';

export default axios => ({
    build(type, format, data) {
        return axios.post(`${RESOURCE_NAME}/${type}/${format}`, data);
    },
    get4Date(type, unitId, date, format, data) {
        return axios.get(`${RESOURCE_NAME}/${type}/${unitId}/${date}/${format}`, data);
    },
    get4Period(type, unitId, from, to, format, data) {
        return axios.get(`${RESOURCE_NAME}/${type}/${unitId}/${from}/${to}/${format}`, data);
    },
});