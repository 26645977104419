<template>
    <div>
        <div>
            <kendo-upload
                :files="files"
                :default-files="files"
                :batch="false"
                :multiple="false"
                :auto-upload="true"
                :with-credentials="false"
                :restrictions="{
                    allowedExtensions: [ '.jpg', '.jpeg', '.png', '.gif', '.ico' ],
                    minFileSize: 10,//in bytes
                    maxFileSize: 4000000,//in bytes ~4M
                }"
                :actions-layout="actionsLayout"

                :save-headers="{'Authorization': 'Bearer '+token}"
                :save-url="uploadUrl+'save'"
                :remove-headers="{'Authorization': 'Bearer '+token}"
                :remove-url="uploadUrl+'remove'"

                @add="onAdd"
                @remove="onRemove"
                @progress="onProgress"
                @statuschange="onStatusChange"

                :li-st="'customList'"
            >
<!--                <template v-slot:customList="{props}">-->
<!--                    <ul :data-props="JSON.stringify(props)" :data-async="JSON.stringify(props.async)">-->
<!--                        <li v-for="file in props.files" :key="file.name" > {{file.name}} </li>-->
<!--                    </ul>-->
<!--                </template>-->
            </kendo-upload>
        </div>
        <div class="field-group field-group_type_image mt__8">
            <label class="field-group__label"> {{ label }} </label>
            <div class="field-group__image">
                <img v-for="fileKey in Object.keys(filePreviews)"
                     :src="filePreviews[fileKey]"
                     :key="fileKey"
                     alt='image preview'
                     :style="previewStyle"
                />
            </div>
        </div>
<!-- <br>{{files}} -->
<!-- <br>{{filePreviews}} -->
    </div>
</template>
<script>
//https://www.telerik.com/kendo-vue-ui/components/upload/#toc-events
//https://www.telerik.com/kendo-vue-ui/components/upload/custom-rendering/
import {Upload} from '@progress/kendo-vue-upload';

export default {
    name: 'FieldGroup_upload',
    props: {
        label: String,
        previewStyle: [String, Object, Array],
        value: [String, Object, Array],
        uploadUrl: String,
        actionsLayout: {
            type: String,
            default: 'stretched', //'start','end','center'
        }
    },
    components: {
        'kendo-upload': Upload
    },
    data() {
        return {
            filePreviews: {},
            //actionsLayout: 'stretched', //'start','end','center'
            addOneValidFile: false,
            success: false,
        }
    },
    computed: {
        token() {
            return localStorage.getItem(process.env.VUE_APP_PRODUCT + '.authtoken')
        },
        files: {
            get() {
                //console.log('files: ', this.value)
                //[{ "extension": ".jpg", "name": "volf.jpg", "progress": 100, status: 4, uid: 'default'}]
                //{ name: 'Picture1', extention: '.jpg', progress: 0, status: UploadFileStatus.Initial }
                if (typeof this.value === "string" && this.value > '') {
                    let filename = this.value.substring(this.value.lastIndexOf('/') + 1);
                    let ext = filename.substring(filename.lastIndexOf('.'))
                    let name = filename.indexOf('?') != -1 ? filename.substring(0,filename.indexOf('?')) : filename
                    return [{"extension": ext, name, "progress": 100, status: 4, uid: 'default'}]
                } else
                if (this.value && Object.keys(this.value).length) {
                    return [{...this.value}];
                }/*else
                if(this.value && Array.isArray(this.value)) {
                    return [{...this.value}];
                }*/
                return []
            },
            set(v) {
                if (Array.isArray(v) && v.length) { v = v[0] }
                //console.log('files = ', {...v})
                this.$emit('input', {...v})
            }
        },
    },
    methods: {
        hasOneValidFile() {
            return this.files.some((f) => f.status === 4);
        },
        onAdd(event) {
            // console.log('onAdd', event)
            this.filePreviews = {}
            const afterStateChange = () => {
                event.affectedFiles
                    .filter(file => !file.validationErrors)
                    .forEach(file => {
                        const reader = new FileReader();
                        reader.onloadend = (ev) => {
                            this.filePreviews[file.uid] = ev.target.result;
                        };
                        reader.readAsDataURL(file.getRawFile());
                    });
            };

            this.files = event.newState;
            afterStateChange();
        },
        onRemove(event) {
            // console.log('onRemove', event)
            const filePreviews = {
                ...this.filePreviews
            };

            event.affectedFiles.forEach(file => {
                delete filePreviews[file.uid];
            });

            this.files = event.newState;
            this.filePreviews = filePreviews;
        },
        onProgress(event) {
            // console.log('onProgress', event)
            this.files = event.newState;
        },
        onStatusChange(event) {
            // console.log('onStatusChange', event)
            this.files = event.newState;
        },
        handleSubmit(event) {
            // console.log('handleSubmit', event)
            event.preventDefault();
            this.addOneValidFile = !this.hasOneValidFile();
            setTimeout(() => { this.addOneValidFile = false; }, 3000);

            if (!this.addOneValidFile) {
                this.success = this.hasOneValidFile();
                setTimeout(() => { this.success = false; }, 3000);
            }
        }
    },
    created() {
        // console.log('FieldGroup_upload created')
    },
    updated() {
        // console.log('FieldGroup_upload updated')
    },
    mounted() {
        // console.log('FieldGroup_upload mounted')
        if (typeof this.value === "string" && this.value > '') {
            this.filePreviews = {
                'default': this.value
            }
        }
    }
}
</script>