<template>
    <div class="horizontal-line" :class="fieldGroup_line_class">
        <div class="field-group field-group_type_color-chooser mr__8" :class="fieldGroup_class">
            <label class="field-group__label" :class="fieldGroup_label_class">
                {{ label ? label : $t('text.Color') }}
            </label>
            <div class="field-group__color-chooser"
                 :class="fieldGroup_color_chooser_class"
                 :style="style"
            >
                <slot></slot>
            </div>
        </div>
        <div class="field-group field-group_type_color-slider" :class="fieldGroup_color_slider_class">
            <slider-picker v-model="color"/>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'FieldGroup_color_chooser',
        props: {
            'fieldGroup_line_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_color_chooser_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_color_slider_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'label': String,
            'value': [String, Object],
            'style_param_name': [String, Object, Array]
        },
        data() {
            return {
                displayPicker: false,
            }
        },
        computed: {
            style() {
                let style_param_name = this.style_param_name || 'backgroundColor'
                let style = {}
                style[style_param_name] = this.color
                return style
            },
            color: {
                get(){ return this.value || '#00BB3F' },
                set(color){ this.$emit('input', color['hex']) }
            },
        },
        created() {
        },
        mounted() {
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>
