<template>
    <div class="weather" :class="{'weather-extended': !extendedSize}">
        <Spinner class="size-spinner" v-if="!weather" />
        <template v-else>
            <div class="weather__block">
                <div class="weather__time-info">
                    <div class="weather__time weather__font"><SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getTimeFormat"/></div>
                    <div class="weather__date weather__font"><SectionCurrentDateTime :timezone-offset="getUserTimeZone" :format="getDateFormat"/></div>
                </div>
                <div class="weather__main-info">
                    <div class="weather-icon">
                        <SvgIcon class="icon weather-icon__icon" :name="weather_icon"/>
                    </div>
                    <div class="weather__value weather__font">
                        <img class="image" src="@/assets/image/gradus.png" alt="">
                        <div class="text info-units">
                            {{ temperature }}
                            <span class="badge">
                                <SvgIcon class="icon" style="width: 9px" name="circle__radius"/>
                                {{ temperatureUnits }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="weather__block">
                <ul class="weather__info-list" v-if="listExtended.length">
                    <li class="weather__info-item weather__font weather__list-text" v-for="(item, i) in listExtended" :key="i" >
                        <img style="height: 30px; width: 30px" :src="require(`@/assets/image/weather__${item.icon}.svg`)" alt="">
                        <!-- <SvgIcon :name="'weather__'+item.icon" class="icon" style="width: 30px; height: 30px"/> -->
                        {{item.value}}<span class="weather__list-meaning ">{{ item.unit }}</span>
                    </li>
                </ul>
                <div class="weather__text weather__font">
                    {{weather_text}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SectionCurrentDateTime from "@/components/_base/SectionCurrentDateTime.vue";
export default {
    name: "WeatherWidget",
    components: {SectionCurrentDateTime},
    props: {
        'unitId': [String, Number],
        'extendedSize':{
            type: Number,
            default: () => { return 0 }
        }
    },
    data() {
        return {
            weathersList: [
                {name: 'wind',     icon: 'wind',},
                {name: 'humidity', icon: 'raindrop',},
                {name: 'pressure', icon: 'pressure',},
                // {name: 'precip',    icon: 'precip', },
                // {name: 'feelslike', icon: 'thermometer', },
                // {name: 'gust',      icon: 'wind', }
            ]
        }
    },
    computed:{
        ...mapGetters([
            "getAppUserSettings",
            "getUserTimeZone",
            "getTimeFormat",
            "getDateFormat",
            "getWeathers",
            "unitsLmsgsByIds",
            "getAppUserUnits",
            "getAppUserUnitsValue",
        ]),
        temperatureUnits(){
            return this.getAppUserUnits.temperature
        },
        lmsg(){
            return (this.unitsLmsgsByIds[this.unitId] || {})
        },
        latlng(){
            return (this.unitsLmsgsByIds[this.unitId] || {}).latlng
        },
        weather(){
            let lmsg = this.lmsg
            if(!lmsg || !lmsg.latlng) {
                return false
            }
            // console.log('weather at latlng', {...lmsg.latlng})
            let weathers = this.getWeathers
                .filter(w => w.request_time > Date.now()/1000 -3600)
                .map(w => {
                    let lat = (w.latLng.lat*1.0) - (lmsg.latlng.lat*1.0)
                    let lon = (w.latLng.lng*1.0) - (lmsg.latlng.lng*1.0)
                    let distances = Math.sqrt(Math.pow(lat,2) + Math.pow(lon,2))
                    let time_ago = Date.now()/1000 - w.request_time
                    return (distances < 0.2)? {...w, distances, time_ago} : null
                })
                .filter(w => w)
            weathers.sort(function (a,b){
                //let time = a.time_ago - b.time_ago
                return (a.distances - b.distances)/0.2// + (time/3600)
            })
            //console.log('weather at latlng', {...lmsg.latlng}, weathers)
            return weathers[0]
        },
        weatherExtended(){
            return (this.weather || {}).extendWeather || []
        },
        listExtended(){
            let weatherExtended = this.weathersList.map(w => {
                let item = this.weatherExtended[w.name]
                if(typeof item != 'object') {
                    item = null;
                } else {
                    item = Object.fromEntries(Object.entries(item).filter(([key]) => this.getAppUserUnitsValue.includes(key)));
                    if(item) item = {value: item[Object.keys(item)[0]], unit:Object.keys(item)[0],...w}
                }
                return item
            })
            .filter(w => w)
            return weatherExtended.slice(0,this.extendedSize)
        },
        temperature(){
          return Math.round( ((this.weather || {}).temperature || {})[this.temperatureUnits])
        },
        weather_text(){
            return (this.weather.text) ? this.weather.text[this.$root.$i18n.locale] || this.weather.text.en || this.weather.text : ''
        },
        weather_icon(){
            return this.weather.icon ? ('weather__'+this.weather.icon) : 'empty'
        }
    },
    watch:{
        weather:{
            handler: function (nVal){
                if(!nVal) {
                    if(this.latlng) {
                        this.fetchWeather(this.latlng)
                    }else {
                        this.fetchUnitWeather(this.unitId)
                    }
                }
            },
        }
    },
    methods:{
        ...mapActions([
            "fetchWeather",
            "fetchUnitWeather"
        ])
    },
}
</script>

<style scoped>

</style>