<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :input_icon="input_icon"
        :error="error"
    >
        <textarea class="field-group__textarea"
                  :class="fieldGroup_textarea_class"
                  :id="id"
                  :name="id"
                  :placeholder="placeholder_text"
                  :value="value" @input="$emit('input', $event.target.value)"
        ></textarea>
    </FieldGroup__base_wrap>
</template>
<script>
    export default {
        name: 'FieldGroup_textarea',
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_textarea_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'id': String,
            'label': String,
            'value': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'input_icon': String
        },
        data() {
            return {}
        },
        computed: {
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
        },
        methods: {
        },
        mounted() {
        },
        updated() {
            //console.log('FieldGroup_textarea updated', this.id, this.error)
        },
    }
</script>
