<template>
    <button class="button button_view_icon button_theme_base is_dual-status"
            :class="[buttonClass, disabled && 'is_dual-status-disabled']"
            @click.stop="onClick"
    >
<!--        <span class="icon" :class="{'icon_active': true}"> <span class="icon__symbol"><SvgIcon class="icon" :name="icon_name"/></span> </span>-->
        <span class="icon" :class="{'icon_active': value}">
            <span class="icon__symbol"><SvgIcon class="icon" :name="icons[0]"/></span>
        </span>
        <span class="icon" :class="{'icon_active': !value}">
            <span class="icon__symbol"><SvgIcon class="icon" :name="icons[1]"/></span>
        </span>
    </button>
</template>

<script>
export default {
    name: "ButtonIcon_dual_status",
    props: [
        'button-class',
        'disabled',
        'icon',
        'icons',
        'value',
    ],
    components: {},
    data() {
        return {}
    },
    computed: {
        icon_name() {
            return this.icon ? this.icon : this.icons[this.value]
        }
    },
    methods: {
        onClick() {
            //if(!this.disabled)
            this.$emit('click')
        },
    },
    created() {
    },
    mounted() {
    },
    updated() {
    },
}
</script>

<style scoped>

</style>