<template>
    <div class="segment-control segment-control_type_slider"
         :class="segmentControlClass"
    >
        <slick class="segment-control__list"
               ref="slick"
               :options="slick_options"
        >
            <div class="segment-control__item item"
                 :class="[segmentControlItemClass, (item.id == selected)?'item_active': '', (item.id == selected)?'slick-slide_active': '']"
                 v-for="(item,i) in list"
                 :key="'segment-control__item-'+i"
                 @click="selected = item.id"
            >
                {{ item.name }}
            </div>

        </slick>

    </div>

</template>

<script>
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default {
    name: "SegmentControl_slider",
    props: {
        'segment-control-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'segment-control-list-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'segment-control-item-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'slickOptions': {
            type: [Object],
            default: () => {
            }
        },
        'items': [Array, Object],
        'field_id': {
            type: [String],
            default: () => 'id'
        },
        'field_name': {
            type: [String],
            default: () => 'name'
        },
        'value': [Number, String],
    },
    data() {
        return {
            def_slickOptions: {
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 3,
                speed: 400,
                // Any other options that can be got from plugin documentation
            },
        }
    },
    components: {Slick},
    computed: {
        slick_options() {
            return this.slickOptions ?
                {...this.def_slickOptions, ...this.slickOptions} : this.def_slickOptions
        },
        list() {
            let id = this.field_id
            let name = this.field_name
            let list = []
            if (Array.isArray(this.items)) {
                list = this.items.map(item => {
                    return {id: item[id], name: item[name]}
                })
            } else {
                //console.warn('items isn\'t array', (typeof this.items))
                const objectArray = Object.entries(this.items);
                objectArray.forEach(([id, item]) => {
                    let name = item;
                    if (typeof item == 'object' && item['name'] !== undefined) {//.hasOwnProperty
                        name = item['name']
                    }
                    if (typeof name == 'string') {
                        list.push({id, name})
                    } else {
                        console.warn('item isn\'t string', id, item)
                    }
                });
            }
            return list
        },
        selected: {
            get(){ return this.value },
            set(v){ this.$emit('input', v) }
        }
    },
    watch: {
        list() {//newList, oldList
            //console.log('list', newList[0], oldList[0])
            this.reInit();
        }
    },
    methods: {
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
        mobileSlidesToShow(){
            if(window.innerWidth <= 340){
                this.def_slickOptions.slidesToShow = 2
            }
        }
    },
    created() {
        this.mobileSlidesToShow()
        // console.log('SegmentControl_slider created')
        // this.reInit()
    },
    updated() {
        // console.log('SegmentControl_slider updated')
    },
    activated() {
        // console.log('SegmentControl_slider activated')
        setTimeout(() => { this.reInit() }, 500)
    },
    deactivated() {
    },
    mounted() {
        // console.log('SegmentControl_slider mounted')
        setTimeout(() => { this.reInit() }, 500)
    },
    beforeDestroy() {
    },
}
</script>

<style scoped>

</style>