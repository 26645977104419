import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

// import JQuery from 'jquery'
// window.$ = window.jQuery = JQuery

import development from "@/config/development.json";
import production from "@/config/production.json";
let config = (process.env.NODE_ENV === 'production')?
    Object.freeze(production) : Object.freeze(development);
Vue.prototype.$config = config

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(Toast, config.toast.options);

//Axios is not a Vue plugin so you cannot use it like this.
// import axios from 'axios';
// Vue.use(axios);

// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)

// import { Chrome } from 'vue-color'
// Vue.component('chrome-picker', Chrome)

import { Chrome, Slider } from 'vue-color'
Vue.component('chrome-picker', Chrome)
Vue.component('slider-picker', Slider)

import SvgIcon from './components/_base/SvgIcon.vue'
Vue.component('SvgIcon', SvgIcon)
import FieldGroup_input from './components/_base/FieldGroup_input.vue'
Vue.component('FieldGroup_input', FieldGroup_input)
import FieldGroup_password from './components/_base/FieldGroup_password.vue'
Vue.component('FieldGroup_password', FieldGroup_password)
import FieldGroup_textarea from './components/_base/FieldGroup_textarea.vue'
Vue.component('FieldGroup_textarea', FieldGroup_textarea)
import FieldGroup_select from './components/_base/FieldGroup_select.vue'
Vue.component('FieldGroup_select', FieldGroup_select)
import FieldGroup_flatpickr from './components/_base/FieldGroup_flatpickr.vue'
Vue.component('FieldGroup_flatpickr', FieldGroup_flatpickr)
import FieldGroup_kendoui_combobox from './components/_base/FieldGroup_kendoui_combobox.vue'
Vue.component('FieldGroup_kendoui_combobox', FieldGroup_kendoui_combobox)
import FieldGroup_kwrapper_dropdownlist from './components/_base/FieldGroup_kwrapper_dropdownlist.vue'
Vue.component('FieldGroup_kwrapper_dropdownlist', FieldGroup_kwrapper_dropdownlist)
import FieldGroup_color_chooser from './components/_base/FieldGroup_color_chooser.vue'
Vue.component('FieldGroup_color_chooser', FieldGroup_color_chooser)
import FieldGroup_upload from './components/_base/FieldGroup_upload.vue'
Vue.component('FieldGroup_upload', FieldGroup_upload)
import FieldGroupImageList from './components/_base/FieldGroup_image_list.vue'
Vue.component('FieldGroup_image_list',FieldGroupImageList)
import FieldGroup__base_wrap from './components/_base/FieldGroup__base_wrap.vue'
Vue.component('FieldGroup__base_wrap',FieldGroup__base_wrap)
import CheckboxControl from './components/_base/CheckboxControl.vue'
Vue.component('CheckboxControl', CheckboxControl)
import CheckboxControlsForArray from './components/_base/CheckboxControlsForArray.vue'
Vue.component('CheckboxControlsForArray', CheckboxControlsForArray)
// Vue.component('FieldsGroup_table_2c', FieldsGroup_table_2c)
// import FieldsGroup_table_2c from './components/_base/FieldsGroup_table_2c.vue'
import SegmentControl from './components/_base/SegmentControl.vue'
Vue.component('SegmentControl', SegmentControl)
import SegmentControl_icon from './components/_base/SegmentControl_icon.vue'
Vue.component('SegmentControl_icon', SegmentControl_icon)
import SegmentControl_slider from './components/_base/SegmentControl_slider.vue'
Vue.component('SegmentControl_slider', SegmentControl_slider)
import ButtonIcon_dual_status from './components/_base/ButtonIcon_dual_status.vue'
Vue.component('ButtonIcon_dual_status', ButtonIcon_dual_status)
import SectionLoading from './components/_base/SectionLoading.vue'
Vue.component('SectionLoading', SectionLoading)
import FieldGroup_info from './components/_base/FieldGroup_info.vue'
Vue.component('FieldGroup_info', FieldGroup_info)
import Spinner from './components/_base/spinner/Spinner.vue'
Vue.component('Spinner', Spinner)
import WeatherWidget from './components/_base/weather_widget/WeatherWidget.vue'
Vue.component('WeatherWidget', WeatherWidget)

import {
    vfmPlugin,
    VueFinalModal,
    // ModalsContainer,
} from 'vue-final-modal'
Vue.use(vfmPlugin)
Vue.component(VueFinalModal)
// Vue.component(ModalsContainer)
import CustomModal from './components/_base/modal/CustomModal.vue'
Vue.component('CustomModal', CustomModal)


import dateFilter from '@/filters/date.filter'
Vue.filter('date', dateFilter)

//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DateRangePicker from 'vue2-daterange-picker'
Vue.use(DateRangePicker)

// "vue-auto-virtual-scroll-list": "^0.3.0",
// import VueAutoVirtualScrollList from 'vue-auto-virtual-scroll-list'
// Vue.use(VueAutoVirtualScrollList)

// "vue-observe-visibility": "^1.0.0",
// import VueObserveVisibility from 'vue-observe-visibility'
// Vue.use(VueObserveVisibility)

// "vue-virtual-scroll-list": "^2.3.2",
// import VirtualList from 'vue-virtual-scroll-list'
// Vue.component('virtual-list', VirtualList)//+style-VDom

import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueVirtualScroller from 'vue-virtual-scroller'
Vue.use(VueVirtualScroller)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// // import Vue plugin
// import VueSvgInlinePlugin from "vue-svg-inline-plugin";
// // import polyfills for IE if you want to support it
// import "vue-svg-inline-plugin/src/polyfills";
// // use Vue plugin without options
// Vue.use(VueSvgInlinePlugin);
// // use Vue plugin with options
// VueSvgInlinePlugin.install(Vue, {
//   attributes: {
//     data: [ "src" ],
//     remove: [ "alt" ]
//   }
// });

// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// am4core.useTheme(am4themes_animated);

import { Grid } from '@progress/kendo-vue-grid'
Vue.component('Grid', Grid);
import { TreeListInstaller } from '@progress/kendo-treelist-vue-wrapper'
Vue.use(TreeListInstaller)
// import {
//   DropDownList
// } from '@progress/kendo-vue-dropdowns';
// Vue.component('dropdownlist', DropDownList)

import '@progress/kendo-ui' // This will import the entire Kendo UI library
// As an alternative, you could import only the scripts that are used by a specific widget:
// import '@progress/kendo-ui/js/kendo.autocomplete' // Imports only the AutoComplete script and its dependencies
// import '@progress/kendo-ui/js/kendo.combobox' // Imports only the ComboBox script and its dependencies
// import '@progress/kendo-ui/js/kendo.dropdownlist' // Imports only the DropDownList script and its dependencies
// import '@progress/kendo-ui/js/kendo.multiselect' // Imports only the MultiSelect script and its dependencies
// import '@progress/kendo-ui/js/kendo.multicolumncombobox' // Imports only the MultiColumnComboBox script and its dependencies
import {
    AutoComplete,
    ComboBox,
    DropDownList,
    MultiSelect,
    MultiColumnComboBox,
    MultiColumnComboBoxColumn,
    DropdownsInstaller
} from '@progress/kendo-dropdowns-vue-wrapper'

Vue.use(DropdownsInstaller)
Vue.component('kwrapper-autocomplete', AutoComplete)
Vue.component('kwrapper-combobox', ComboBox)
Vue.component('kwrapper-dropdownlist', DropDownList)
Vue.component('kwrapper-multiselect', MultiSelect)
Vue.component('kwrapper-multicolumncombobox', MultiColumnComboBox)
Vue.component('kwrapper-multicolumncomboboxcolumn', MultiColumnComboBoxColumn)

import '@progress/kendo-theme-default/dist/all.css';

import 'leaflet/dist/leaflet.css';
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

// import "@/api.js";
import api from "@/api.js";
Vue.prototype.$api = api
store.$api = api
//store.$collator = new Intl.Collator(),

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.performance = true

new Vue({
    data: {
        // am4core,
        // am4charts,
        now: Date.now()/1000,
        //collator: new Intl.Collator(),
    },
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
