// import Vue from 'vue'

export default {
    state: {
        map: {
            center: [25.761681, -80.191788],
            bounds: null,
            unitsOnMap: [],
            unitTracking: null,
            unitMoveOnTrack: null,
            unitMoveOnTrackSpeed: 1,
            showMarkerOnTrack: null,
            showUnitTrack: {
                unitId: null,
                date: null,
            },
            showUnitTrack_index: null,
            geoitemEdit: null,
            geoitemsOnMap: [],
            showNotificationEvent: null,
        },
    },
    actions: {
        setMapCenter({ commit }, value) {
            commit('setMapCenter', value)
        },
        setMapBounds({ commit }, value) {
            commit('setMapBounds', value)
        },
        setMapBoundsByUnits({ commit, rootState }, units) {
            if(!units || !units.length) return false
            let Lmsgs = rootState.units.unitsLmsgs
            let bounds = units.reduce((out, id) => {
                let lmsg = Lmsgs.find(lmsg => {
                    return lmsg.id_unit == id
                })
                if(lmsg && lmsg.latlng) {
                    out.push(lmsg.latlng)
                }
                return out
            }, [])
            if(bounds.length>1) {
                commit('setMapBounds', bounds)
            }else
            if(bounds.length == 1) {
                commit('setMapCenter', bounds[0])
            }
            //commit('setUnitsShowOnMap', { showOnMap: true, unitIds: units })
        },
        setMapBoundsByGeozones({ commit, rootState }, geoitems) {
            if(!geoitems || !geoitems.length) return false
            let geozones = rootState.geozones.geozones
            let bounds = geoitems.reduce((out, id) => {
                let geozone = geozones.find(geo => {
                    return geo.id == id
                })
                if(geozone && geozone.points.length) {
                    let rd = geozone.radius/111200
                    out.push([geozone.points[0][0]*1.0 + 0,  geozone.points[0][1]*1.0 + 0])
                    out.push([geozone.points[0][0]*1.0 + rd, geozone.points[0][1]*1.0 + rd])
                    out.push([geozone.points[0][0]*1.0 - rd, geozone.points[0][1]*1.0 - rd])
                }
                return out
            }, [])
            if(bounds.length>1) {
                commit('setMapBounds', bounds)
            }else
            if(bounds.length == 1) {
                commit('setMapCenter', bounds[0])
            }
        },

        setGeoitemShowOnMap({ commit }, args) {
            commit('setGeoitemShowOnMap', args)
        },
        setGeoitemsShowOnMap({ commit }, args) {
            commit('setGeoitemsShowOnMap', args)
        },

        setUnitShowOnMap({ commit }, args) {
            commit('setUnitShowOnMap', args)
        },
        setUnitsShowOnMap({ commit }, args) {
            commit('setUnitsShowOnMap', args)
        },
        // setAllUnitsShowOnMap({ commit, rootState }, args) {
        //     args.unitIds = (!args.showOnMap) ? [] : rootState.units.units_index
        //     commit('setAllUnitsShowOnMap', args)
        // },
        setUnitTracking({ commit }, unitId) {
            commit('setUnitTracking', unitId)
        },
        setShowUnitTrack({ commit }, args) {
            commit('setShowUnitTrack', args)
        },
        setShowUnitTrackIndex({ commit }, args) {
            commit('setShowUnitTrackIndex', args)
        },
        setUnitMoveOnTrack({ commit }, args) {
            commit('setUnitMoveOnTrack', args)
        },
        setUnitMoveOnTrackSpeed({ commit }, speed) {
            commit('setUnitMoveOnTrackSpeed', speed)
        },
        setUnitTrackColor({ commit } , color) {
            commit('setUnitTrackColor', color)
        },
        setShowMarkerOnTrack({ commit } , marker) {
            commit('setShowMarkerOnTrack', marker)
        },
        setGeoitemEdit({ commit }, args) {
            commit('setGeoitemEdit', args)
        },
        patchGeoitemEdit({ commit }, args) {
            commit('patchGeoitemEdit', args)
        },
        setNotificationEventShow({ commit }, event) {
            commit('setNotificationEventShow', event)
            if(event && event.latlng) {
                commit('setMapCenter', event.latlng)
            }
        },
    },
    mutations: {
        setNotificationEventShow(state, event){
            state.map.showNotificationEvent = event
        },

        setGeoitemEdit(state, geoitem) {
            state.map.geoitemEdit = geoitem
        },
        patchGeoitemEdit(state, patch) {
            if(state.map.geoitemEdit) {
                state.map.geoitemEdit = {
                    ...state.map.geoitemEdit,
                    ...patch
                }
            }
        },

        setShowUnitTrack(state, args) {
            state.map.showUnitTrack = {
                unitId: args.unitId,
                date: args.date,
            };
        },
        setShowUnitTrackIndex(state, index) {
            if(index){
                index = {...state.map.showUnitTrack_index, ...index}
            }
            state.map.showUnitTrack_index = index
        },
        setUnitTrackColor( state , color) {
            if(state.map.showUnitTrack_index){
                state.map.showUnitTrack_index.trackColor = color
            }
        },
        setUnitMoveOnTrack(state, args) {
            state.map.unitMoveOnTrack = args;
        },
        setUnitMoveOnTrackSpeed(state, speed) {
            state.map.unitMoveOnTrackSpeed = speed;
        },
        setShowMarkerOnTrack( state, marker ){
            state.map.showMarkerOnTrack = marker
        },
        setMapCenter(state, center) {
            if(typeof center === 'string'){
                center = center.split(',')
            }
            if(Array.isArray(center) && center.length === 2) {
                state.map.center = center
            }else
            if(center.lat && center.lng){
                state.map.center = center
            }else{
                console.warn('setMapCenter', center)
            }
        },
        setMapBounds(state, value) {
            state.map.bounds = value
        },
        setGeoitemShowOnMap(state, args) {
            if(!args.showOnMap){
                state.map.geoitemsOnMap = state.map.geoitemsOnMap.filter(id => {
                    return id !== args.geoitemId
                })
            }else
            if(/*args.showOnMap &&*/ !state.map.geoitemsOnMap.includes(args.geoitemId)) {
                state.map.geoitemsOnMap.push(args.geoitemId)
            }
        },
        setGeoitemsShowOnMap(state, args) {
            if(!args.showOnMap){
                state.map.geoitemsOnMap = state.map.geoitemsOnMap.filter(id => {
                    return !args.geoitemIds.includes(id)
                })
            }else
            {
                let ids = args.geoitemIds.filter(id => {
                    return !state.map.geoitemsOnMap.includes(id)
                })
                state.map.geoitemsOnMap.push(...ids)
            }
        },
        setAllGeoitemsShowOnMap(state, geoitemIds) {
            state.map.geoitemsOnMap = geoitemIds
        },

        setUnitShowOnMap(state, args) {
            if(!args.showOnMap){
                state.map.unitsOnMap = state.map.unitsOnMap.filter(id => {
                    return id !== args.unitId
                })
                if(!state.map.unitsOnMap.includes(state.map.unitTracking)){
                    state.map.unitTracking = null
                }
            }else
            if(/*args.showOnMap &&*/ !state.map.unitsOnMap.includes(args.unitId)) {
                state.map.unitsOnMap.push(args.unitId)
            }
        },
        setUnitsShowOnMap(state, args) {
            if(!args.showOnMap){
                state.map.unitsOnMap = state.map.unitsOnMap.filter(id => {
                    return !args.unitIds.includes(id)
                })
                if(!state.map.unitsOnMap.includes(state.map.unitTracking)){
                    state.map.unitTracking = null
                }
            }else
            {
                let ids = args.unitIds.filter(id => {
                    return !state.map.unitsOnMap.includes(id)
                })
                state.map.unitsOnMap.push(...ids)
            }
        },
        setAllUnitsShowOnMap(state, unitIds) {
            state.map.unitsOnMap = unitIds
        },

        setUnitTracking(state, unitId) {
            if(!unitId) {
                // let old = state.map.unitTracking
                // state.map.unitsOnMap = state.map.unitsOnMap.filter(id => {
                //     return id !== old
                // })
            }else
            if(/*unitId &&*/!state.map.unitsOnMap.includes(unitId)) {
                state.map.unitsOnMap.push(unitId)
            }
            state.map.unitTracking = unitId
        },

    },
    getters: {
        getNotificationEventShow(state){
            return state.map.showNotificationEvent
        },
        getGeoitemEdit(state) {
            return state.map.geoitemEdit
        },

        getShowUnitTrack(state) {
            return state.map.showUnitTrack
        },
        getShowUnitTrackIndex(state) {
            return state.map.showUnitTrack_index
        },
        getShowUnitTrackColor(state) {
            return (state.map.showUnitTrack_index) ?
                state.map.showUnitTrack_index.trackColor : null
        },
        getUnitMoveOnTrack(state) {
            return state.map.unitMoveOnTrack
        },
        getUnitMoveOnTrackSpeed(state) {
            return state.map.unitMoveOnTrackSpeed
        },
        getShowMarkerOnTrack(state) {
            return state.map.showMarkerOnTrack
        },

        getMapCenter(state) {
            return state.map.center
        },
        getMapBounds(state) {
            return state.map.bounds
        },

        geoitemsOnMap(state){
            return state.map.geoitemsOnMap
        },
        unitsOnMap(state){
            return state.map.unitsOnMap
        },

        unitTracking(state){
            return state.map.unitTracking
        },

    }
}
