<template>
    <section class="section section_type_map" >

        <SectionMapInfo v-if="showMapInfo" :class_type="'section_type_map_row'" />
        <SectionMapTrackInfo v-if="showTrackInfo" />
        <SectionMapNotifEventsInfo v-if="showNotifEventsInfo" />
        <WeatherWidget class="weather-in-map"
                       :unit-id="getUnitActive"
                       :extended-size="2"
                       v-show="showWeather"
        />

        <l-map
            id="map"
            ref="map"
            style=""
            :options="mapOptions"
            :zoom.sync="zoom"
            :center.sync="center"
            :bounds.sync="bounds"
            :min-zoom="minZoom"
            :max-zoom="maxZoom"
            :noBlockingAnimations="true"
            @ready="onReady()"
            v-if="showMap"
        >
            <!-- @update:bounds="boundsUpdated"-->
            <l-control-layers
                :position="layersPosition"
                :collapsed="true"
                :sort-layers="true"
            />
            <l-tile-layer
                v-for="(tileProvider, i) in _tileProviders"
                :key="i"
                :name="tileProvider.name"
                :url="tileProvider.url+(apiKeys[tileProvider.token] ? apiKeys[tileProvider.token] : '')"
                :attribution="tileProvider.attribution"
                :subdomains="tileProvider.subdomains"
                :xoptions="tileProvider.options"
                :xtoken="apiKeys[tileProvider.token]"
                :visible="tileProvider.visible"
                layer-type="base"
            />

            <l-control :position="openGeozoneEditControlPosition">
                <button class="map-controll"  @click.stop="openGeozoneEdit">
                    <SvgIcon class="icon" name="menu__geoitem_add" />
                </button>
            </l-control>

            <l-control-zoom :position="zoomPosition" />

            <l-control-attribution
                :position="attributionPosition"
                :prefix="attributionPrefix"
            />
            <l-control-scale :imperial="imperial" />

            <!-- <l-icon-default :image-path="path" /> -->

            <l-layer-group
                :layer-type="overlay['units'].type"
                :visible.sync="overlay['units'].visible"
                :name="(overlay['units'].name)"
                ref="unitsLayer"
            >
                <l-marker-cluster
                    :options="unitsClusterOptions"
                    ref="unitsCluster"
                    id="units-cluster"
                >
                </l-marker-cluster>
            </l-layer-group>

            <l-layer-group
                :layer-type="overlay['geoitems'].type"
                :visible.sync="overlay['geoitems'].visible"
                :name="' '+(overlay['geoitems'].name)"
                ref="geoitemsLayer"
            >
            </l-layer-group>

            <l-layer-group
                :layer-type="overlay['track'].type"
                :visible.sync="overlay['track'].visible"
                :name="(overlay['track'].name)"
            >
                <l-marker-cluster
                    :options="trackClusterOptions"
                    ref="trackCluster"
                    id="track-cluster"
                >
                </l-marker-cluster>
            </l-layer-group>

        </l-map>
    </section>
</template>
<script>
    import debounce from "lodash/debounce"
    import {mapActions, mapGetters} from "vuex";
    import SectionMapInfo from "@/components/app/SectionMapInfo.vue";
    import SectionMapTrackInfo from "@/components/app/SectionMapTrackInfo.vue";
    import SectionMapNotifEventsInfo from "@/components/app/SectionMapNotifEventsInfo.vue";

    import * as L from 'leaflet'
    import {
        icon,
        Icon,
        // circle,
        // polygon,
        // latLng,
        latLngBounds
    } from 'leaflet'

    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    import {
        LControlAttribution,
        LControlLayers,
        LControlScale,
        LControlZoom,
        LLayerGroup,
        LControl,
        // LCircle,
        // LPolygon,
        // LPolyline,
        // LTooltip,
        // LPopup,
        // LIcon,
        LTileLayer,
        LMap
    } from 'vue2-leaflet';
    // import * as Vue2Leaflet from 'vue2-leaflet'
    // import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant'
    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
    //import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
    // import LMovingMarker from 'vue2-leaflet-movingmarker'
    import DriftMarker from "leaflet-drift-marker"
    import 'leaflet-draw'
    import 'leaflet-polylinedecorator'
    import './map/leaflet-corridor'
    import './map/MovingMarker'

    const unitIcon = icon({
        iconUrl: require('@/assets/img/unit/common__car_lg.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 16],
        popupAnchor: [4, -25],
    })
    // const trackDirectionIcon = icon({
    //     iconUrl: '/img/track/direction-icon.svg',//color='+el.color,
    //     iconSize:  [20, 20],
    //     iconAnchor: [10, 10],
    //     popupAnchor: [0, -15],
    //     className: 'track-direction-arrow',
    //     //style: 'color: #'+el.color
    // })

    export default {
        name: 'SectionMap',
        props: {
            'showWeather': {
                type: Boolean,
                default: () => { return false }
            }
        },
        components: {
            SectionMapInfo,
            SectionMapTrackInfo,
            SectionMapNotifEventsInfo,

            LMap,
            LTileLayer,
            LControlZoom,
            LControlAttribution,
            LControlScale,
            LControlLayers,
            LLayerGroup,
            LControl,
            // LCircle,
            // LPolygon,
            // LPolyline,
            // LIcon,
            // LTooltip,
            // LMovingMarker,
            'l-marker-cluster': Vue2LeafletMarkerCluster,
            // 'l-tile-layer-googlemutant': Vue2LeafletGoogleMutant,
        },
        data() {
            return {
            map: null,
            showMap: true,
            mapOptions: {
                zoomControl: false,
                attributionControl: false,
                zoomSnap: false,
            },
            minZoom: 1,
            maxZoom: 18,
            zoom: 12,
            // center: [25.761681, -80.191788],
            // bounds: null,

            openGeozoneEditControlPosition:'topright',
            zoomPosition: 'topright',
            layersPosition: 'topright',
            attributionPosition: 'bottomright',
            attributionPrefix: 'Vue2Leaflet',
            imperial: false,
            Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
            tileProviders: [
                {
                    name: ' OpenStreetMap',
                    visible: true,
                    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    token: '',
                },
                // {
                //     name: 'OpenTopoMap',
                //     visible: false,
                //     url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                //     attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                //     token: '',
                // },

                //h = roads only
                //m = standard roadmap
                //p = terrain
                //r = somehow altered roadmap
                //s = satellite only
                //t = terrain only
                //y = hybrid
                //https://stackoverflow.com/questions/9394190/leaflet-map-api-with-google-satellite-layer
                //Hybrid: s,h;
                //Satellite: s;
                //Streets: m;
                //Terrain: p;
                {
                    name: 'Google',
                    visible: false,
                    url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=' + this.$root.$i18n.locale + '&key=',//+ '&key=AIzaSyDPkqJnWr_oHIrgV_xj0Sk-2SyyZg0udxk',
                    subdomains:['mt0','mt1','mt2','mt3'],
                    options: {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        //accessToken: '',
                        key: () => this.apiKeys['googleKey']
                    },
                    token: 'googleKey',
                },
                {
                    name: 'Google-Hybrid',
                    visible: false,
                    url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=' + this.$root.$i18n.locale + '&key=',//+ '&key=AIzaSyDPkqJnWr_oHIrgV_xj0Sk-2SyyZg0udxk',
                    subdomains:['mt0','mt1','mt2','mt3'],
                    options: {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        //accessToken: '',
                        key: () => this.apiKeys['googleKey']
                    },
                    token: 'googleKey',
                },
                {
                    name: 'Google Traffic',
                    visible: false,
                    url: 'https://{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}&hl=' + this.$root.$i18n.locale + '&key=',//+ '&key=AIzaSyDPkqJnWr_oHIrgV_xj0Sk-2SyyZg0udxk',
                    subdomains:['mt0','mt1','mt2','mt3'],
                    options: {
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        //accessToken: '',
                        key: () => this.apiKeys['googleKey']
                    },
                    token: 'googleKey',
                },
            ],
            googleMapProps: {
                apikey: 'AIzaSyDPkqJnWr_oHIrgV_xj0Sk-2SyyZg0udxk',
                tiles: [
                    // { name: 'Google Roadmap', visible: false, options: { type: 'roadmap', } },
                    // { name: 'Google Hybrid', visible: false, options: { type: 'hybrid', } },
                    // { name: 'Google Satellite', visible: false, options: { type: 'satellite', } },
                ],
            },

            currentZoom: 11.5,
            currentCenter: {lat: 25.761681, lng: -80.191788},
            overlay: {
                // 'moving marker': {visible: true},
                'units':    {name: this.$t('map.layer.Units'),     visible: true, type: 'overlay'},
                'geoitems': {name: this.$t('map.layer.Geo items'), visible: true, type: 'overlay'},
                'track':    {name: this.$t('map.layer.Track'),     visible: true, type: '-'},
            },

            duration: 3000,
            keepAtCenter: false,

            path: "/img/",
            unitIcon: unitIcon,

            unitsLayer: null,
            //units-cluster
            unitsClusterOptions: {
                chunkedLoading: true,
                removeOutsideVisibleBounds: true,
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: true,
                disableClusteringAtZoom: 17,
                // iconCreateFunction: function(cluster) {
                //     return L.divIcon({ html: '<b>' + cluster.getChildCount() + '</b>' });
                // }
            },
            unitsMarkers: [],
            unitsLmsgsTimes: {},
            unitsCluster: null,

            notificationEventMarker: null,

            geoitemsLayer: null,
            geoitemsClusterOptions: {
                chunkedLoading: true,
                removeOutsideVisibleBounds: true,
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
                disableClusteringAtZoom: 5,
                // iconCreateFunction: function(cluster) {
                //     return L.divIcon({ html: '<b>' + cluster.getChildCount() + '</b>' });
                // }
            },
            geoitems: [],
            drawLayer: null,
            drawnItems: null,
            drawControl: null,
            draw: null,
            areaLabel: null,
            areaLayer: null,
            areaPolygon: null,

            //parked: null,
            parkedLayer: null,
            parkedMarker: null,

            //track: null,
            trackLayer: null,
            trackControlPointsLayerCluster: null,
            trackMarkers: [],
            trackUnitMarker: null,
            trackUnitMarkerInterval: null,
            trackDecorators: [], //use to show track direction
            trackControlPointsGroup: [],
            trackEventsPointsGroup: [],
            trackManeuversPointsGroup: [],
            trackIdlePointsGroup: [],
            trackControlPointsLayer_options: {
                'clustered': {
                    iconCreateFunction: (cluster) => {

                        let childCount = cluster.getChildCount(),
                            markers = cluster.getAllChildMarkers(),
                            totalSpeed = 0, pointCount = 0,
                            iconSize = [89, 52];

                        let speedUnits = (this.speedUnits);

                        let curSpeed = 0;
                        for (let i = 0; i < markers.length; i++) {
                            if (markers[i].meta.type == 'control') {
                                curSpeed = parseInt(markers[i].meta.speed);
                                if (curSpeed != 0) {
                                    totalSpeed += curSpeed;
                                    pointCount++;
                                }
                            }
                        }
                        if (totalSpeed > 0) {
                            totalSpeed = Math.round(totalSpeed / pointCount);
                            totalSpeed = totalSpeed + '&nbsp;' + speedUnits;
                        } else {
                            totalSpeed = '';//'-' + speedUnits;
                        }
                        //kmp and mph only for different length of speed string
                        let c = ' kmp-', cc = '';
                        if (totalSpeed.length > 13) {
                            c = ' mph-';
                            cc = 'large-';
                            iconSize = [101, 52];
                        }
                        if (!totalSpeed.length) {
                            c = ' ';
                        }
                        if (childCount < 10) {
                            c += 'small';
                        } else if (childCount < 100) {
                            c += 'medium';
                        } else {
                            c += 'large';
                        }
                        let speed_span = '';
                        if (totalSpeed.length) {
                            speed_span = '<span class="' + cc + 'speed">' + totalSpeed + '</span>';
                        }
                        return new L.DivIcon({
                            html: '<div><span class="marker-count">' + childCount + '</span>' + speed_span + '</div>',
                            className: 'marker-cluster-track' + c,
                            iconSize: iconSize
                        });
                    }
                },
                'detailed': {
                    iconCreateFunction: (cluster) => {

                        let //childCount = cluster.getChildCount(),
                            markers = cluster.getAllChildMarkers(),
                            options = {
                                html: '',
                                className: 'marker-cluster-track-detailed',
                                iconSize: [280, 32],
                            },
                            marker;
                        let speedUnits = (this.speedUnits);

                        marker = markers.find(function (m) {
                            return m.meta && m.meta.type == 'parking'
                        })
                        if (!marker) {
                            marker = markers.find(function (m) {
                                return m.meta && m.meta.type == 'stop'
                            })
                        }
                        if (!marker) {
                            marker = markers.find(function (m) {
                                return m.meta && m.meta.type == 'control'
                            })
                        }
                        if (!marker) {
                            marker = markers[0]
                        }

                        //console.log('iconCreateFunction', 'detailed', marker.meta.type, marker.meta, marker, markers)

                        if (marker && marker.meta) {
                            if (this.getAppUserSettings.hideMarkerTooltipForDetailedTrack) {
                                let color = '#' + marker.meta.color
                                if (marker.meta.type == 'parking') {
                                    color = '#008CE8'
                                } else if (marker.meta.type == 'stop') {
                                    color = '#FF1800'
                                }
                                //console.log(marker)
                                options.iconSize = [20, 20]
                                options.iconAnchor = [10, 10]
                                options.className += '-without-cluster-annotations'
                                options.html = '<div style="background: ' + color + ';color: ' + color + ';"></div>';

                            } else {
                                options.html =
                                    '<div>' +
                                    '<span class="marker-time">' + marker.meta.time + '</span>' +
                                    '<br/>' +
                                    '<span class="marker-speed">' + marker.meta.speed + '&nbsp;' + speedUnits + '</span>' +
                                    '</div>'
                                ;
                            }
                        }

                        return new L.DivIcon(options);
                    }
                },
            },
            trackClusterOptions: {
                chunkedLoading: true,
                removeOutsideVisibleBounds: true,
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: true,
                // maxClusterRadius: 50,
                disableClusteringAtZoom: 17,
                iconCreateFunction: (cluster) => {
                    //return L.divIcon({ html: '<b>' + cluster.getChildCount() + '</b>' });
                    let childCount = cluster.getChildCount(),
                        // markers = cluster.getAllChildMarkers(),
                        iconSize = [38, 38];

                    let c = ' kmp-'
                    if (childCount < 10) {
                        c += 'small';
                    } else if (childCount < 100) {
                        c += 'medium';
                    } else {
                        c += 'large';
                    }
                    return new L.DivIcon({
                        html: '<div><span class="marker-count">' + childCount + '</span></div>',
                        className: 'marker-cluster-track' + c,
                        iconSize: iconSize,
                        //iconAnchor: [19, 0]
                    });
                }
            },
        }},
        computed: {
            ...mapGetters([
                "getAppUser",
                "getAppUserSettings",
                "getUserStorageKey",
                "getAppUserUnits",

                "unitsCount",
                "unitsByIds",
                "unitsLmsgsByIds",
                "unitsLmsgsIds",
                // "unitsLmsgsTimeByIds",
                "unitsLmsgsLatlngByIds",
                // "getUnitsMarkerOptions",
                "getUnitsMarkers",
                "unitsOnMap",
                "getUnitActive",
                "hasUnitActiveVideo",
                "unitTracking",

                "getTracks",
                "getTracksFiltered",
                "getParkings",
                "getParkingsFiltered",
                "getStops",
                "getStopsFiltered",
                "getEvents",
                "getEventsFiltered",
                "getShowUnitTrack",
                "getShowUnitTrackIndex",
                "getShowUnitTrackColor",
                "getShowMarkerOnTrack",

                "geoitemsOnMap",
                "geozonesByIds",
                "geozonesLOptions",
                "getGeoitemEdit",

                "getUnitMoveOnTrack",
                "getUnitMoveOnTrackSpeed",

                "getNotificationEventShow",

                "getMapCenter",
                "getMapBounds",
                "showSectionInfo",
                "getSectionInfo",
                "showSectionWidget",
                "getSectionWidget",

                "showMainList",
            ]),
            center: {
                get(){
                    return this.getMapCenter
                },
                set(value){
                    this.setMapCenter(value)
                },
            },
            bounds: {
                get(){
                    let bounds = this.getMapBounds
                    if(!bounds) return null
                    //console.log('get Bounds', {...bounds})
                    if(Array.isArray(bounds)){
                        // this.map.fitBounds(latLngBounds(bounds), {
                        //     paddingTopLeft: [100, 100],
                        //     paddingBottomRight: [100, 200],
                        // })
                        // bounds = this.map.getBounds()
                        return latLngBounds(bounds).pad(0.2)
                    }
                    return latLngBounds(bounds)
                },
                set(value){
                    this.setMapBounds(value)
                },
            },
            route_name(){
                return this.$route.name
            },
            showMapInfo(){
                return this.$route.meta.showMapInfo
            },
            showTrackInfo(){
                return this.$route.meta.showTrackInfo
            },
            showNotifEventsInfo(){
                return this.$route.meta.showNotifEventsInfo
            },
            speedUnits(){
                return this.getAppUserUnits.speed
            },
            apiKeys(){
                let apikeys = this.getAppUser.dealer_apikeys || {}
                //console.log('apikeys', {...apikeys})
                return apikeys
            },
            _tileProviders(){
                let _tileProviders = this.tileProviders
                let keys = this.tileProviders.reduce((out, t) => {
                    if(t.token>'') {
                        if(!out.includes(t.token)) out.push(t.token)
                    }
                    return out
                }, [])
                keys.forEach(k => {
                    if(!this.apiKeys[k]){
                        _tileProviders = _tileProviders.filter( t => { return t.token != k })
                    }
                })
                //console.log('tileProviders', _tileProviders.map(t => t.name))
                return _tileProviders
            },
            geoitemEdit(){
                let geozoneId = this.getGeoitemEdit.geozoneId
                let item = this.geoitems.find(g => {
                    return g.options.geozoneId == geozoneId
                })
                return item
            },
            track() {
                let unitId = this.getShowUnitTrack.unitId
                let date = this.getShowUnitTrack.date

                let indx = this.getShowUnitTrackIndex
                if (!indx) return null

                let track = this.getTracksFiltered.find(t => {
                    return t.unit_id == unitId && t.date == date
                        && t.timeFrom == indx.time_start
                        && t.timeTo == indx.time_end
                })
                return track
            },
            trackLatLngs(){
                return this.track ? this.track.msgs.map(msg => msg.latlng) : []
            },
            parked() {
                let unitId = this.getShowUnitTrack.unitId
                let date = this.getShowUnitTrack.date

                let indx = this.getShowUnitTrackIndex
                if (!indx) return null

                let item = this.getParkingsFiltered.find(p => {
                    return p.unit_id == unitId && p.time_start.date == date
                        && p.time_start.utc == indx.time_start
                        && p.time_end.utc == indx.time_end
                })
                return item
            },
            stops() {
                let unitId = this.getShowUnitTrack.unitId
                let date = this.getShowUnitTrack.date

                let indx = this.getShowUnitTrackIndex
                if (!indx) return null

                let items = this.getStopsFiltered.filter(s => {
                    return s.unit_id == unitId && s.time.date == date
                        && indx.time_start <= s.time.utc && s.time.utc <= indx.time_end
                })
                .map(e => {
                    let message = e.text || ''
                    if(e.tmp_msg && e.tmp_msg.msg){
                        message = e.tmp_msg.msg.replace(/%TEXT%/i, this.$t('message.'+e.tmp_msg.TEXT))
                    }
                    return {...e, message}
                })
                return items
            },
            events() {
                let unitId = this.getShowUnitTrack.unitId
                let date = this.getShowUnitTrack.date

                let indx = this.getShowUnitTrackIndex
                if (!indx) return null

                let events = this.getEventsFiltered.filter(e => {
                    return e.unit_id == unitId && e.time.date == date
                        && indx.time_start <= e.time.utc && e.time.utc <= indx.time_end
                })
                .map(e => {
                    let message = e.text || ''
                    if(e.tmp_msg && e.tmp_msg.msg){
                        message = e.tmp_msg.msg.replace(/%TEXT%/i, this.$t('message.'+e.tmp_msg.TEXT))
                    }
                    return {...e, message}
                })
                return events
            },
            unitsLmsgsLength(){
                return this.unitsLmsgsIds.length
            },
        },
        watch: {
            unitsLmsgsLength: {
                handler: function (newVl, oldVl) {
                    //console.log('watch unitsLmsgsLength', newVl, oldVl)
                    // on first load
                    if (!oldVl && newVl) {
                        if(this.route_name == 'Units') {
                            //console.log('watch unitsLmsgsLength', [...this.unitsOnMap])
                            if (this.unitsOnMap && this.unitsOnMap.length) {
                                this.setMapBoundsByUnits(this.unitsOnMap)
                                this.setMarkers(this.unitsOnMap)
                            }
                            //console.log('watch unitsLmsgsLength', this.unitTracking, this.getUnitActive)
                            let unitId = this.unitTracking || this.getUnitActive
                            if (unitId) {
                                this.switchUnitTracking(unitId)
                            }
                        } else
                        if (this.unitsOnMap && this.unitsOnMap.length) {
                            //console.log('watch unitsLmsgsLength', [...this.unitsOnMap])
                            this.setMarkers(this.unitsOnMap)
                        }
                    }
                }
            },
            getUnitMoveOnTrackSpeed: {
                handler: function (newVl, oldVl) {
                    // console.log('getUnitMoveOnTrackSpeed', newVl, oldVl)
                    if (this.trackUnitMarker) {
                        this.trackUnitMarker.pause()
                        // console.log('getUnitMoveOnTrackSpeed', {...this.trackUnitMarker})
                        let run_time = (Date.now() - this.trackUnitMarker._startTime)
                        let duration = this.trackUnitMarker._currentDuration - run_time;
                        if(duration > 1000) {
                            duration = duration * oldVl / newVl
                            this.trackUnitMarker._currentDuration = duration + run_time
                        }
                        this.trackUnitMarker.resume()
                        // console.log('getUnitMoveOnTrackSpeed', {...this.trackUnitMarker})
                    }
                }
            },
            getShowUnitTrackColor: {
                handler: function () {
                    this.debouncedUpdateSpeedActive()
                },
                //deep: true
            },
            route_name: {
                handler: function (newVl, oldVl) {
                    // console.log('route_name', newVl, oldVl)
                    if (newVl !== oldVl) {
                        //this.debouncedAfterRouteChange(newVl, oldVl);
                        this.afterRouteChange(newVl, oldVl);
                        setTimeout(() => {
                            if(this.map && this.map.invalidateSize)
                                this.map.invalidateSize()
                        }, 200)
                    }
                }
            },
            showMainList: {
                handler: function (newVl, oldVl) {
                    if (newVl !== oldVl) {
                        //console.log('showMainList', newVl, oldVl)
                        this.debouncedInvalidateMapSize();
                    }
                }
            },
            showSectionInfo: {
                handler: function (newVl, oldVl) {
                    if (newVl !== oldVl) {
                        //console.log('showSectionInfo', newVl, oldVl)
                        this.debouncedInvalidateMapSize();
                    }
                }
            },
            geoitemsOnMap: {
                handler: function (newGeos, oldGeos) {
                    if(oldGeos.length == 0) {
                        // console.log('geoitemsOnMap', newGeos, oldGeos)
                    }
                    // let added = newGeos.filter(id => !oldGeos.includes(id));
                    // let removed = oldGeos.filter(id => !newGeos.includes(id));
                    this.setGeoitems(newGeos)
                },
                deep: true
            },
            unitsOnMap: {
                handler: function (newUnits, oldUnits) {
                    // console.log('unitsOnMap', newUnits, oldUnits)
                    if(oldUnits.length == 0) {
                        // console.log('unitsOnMap', newUnits, oldUnits)
                    }
                    // let added = newUnits.filter(id => !oldUnits.includes(id));
                    // let removed = oldUnits.filter(id => !newUnits.includes(id));
                    // if(added.length) this.addMarkers(added);
                    // if(removed.length) this.removeMarkers(removed);
                    this.setMarkers(newUnits)
                },
                deep: true
            },
            unitTracking: {
                handler: function (newUnitId, oldUnitId) {
                    // console.log('watch unitTracking', newUnitId, oldUnitId)
                    if(!oldUnitId) {
                        // console.log('unitTracking', newUnitId, oldUnitId)
                    }
                    if (newUnitId) {
                        this.switchUnitTracking(newUnitId)
                    }
                },
                // deep: true
            },
            // unitsLmsgsTimeByIds: {
            //     handler: function (newLmsgs, oldLmsgs) {
            //         if(Object.keys(oldLmsgs).length === 0)
            //             console.log('unitsLmsgsTimeByIds', Object.keys(newLmsgs).length, Object.keys(oldLmsgs).length)
            //         this.debouncedUpdateMarkersLatLng();
            //     },
            //     deep: true
            // },
            // unitsLmsgsLatlngByIds: {
            //     handler: function (newLmsgs, oldLmsgs) {
            //         if(Object.keys(oldLmsgs).length === 0)
            //             console.log('unitsLmsgsLatlngByIds', Object.keys(newLmsgs).length, Object.keys(oldLmsgs).length)
            //         this.debouncedUpdateMarkersLatLng();
            //     },
            //     deep: true
            // },
            // getUnitsMarkerOptions: {
            //     handler: function (newUnitsMarkers, oldUnitsMarkers) {
            //         if(Object.keys(oldUnitsMarkers).length === 0)
            //             console.log('getUnitsMarkerOptions', Object.keys(newUnitsMarkers).length, Object.keys(oldUnitsMarkers).length)
            //         this.debouncedUpdateMarkersOptions();
            //     },
            //     deep: true
            // },
            getUnitsMarkers: {
                handler: function (newUnitsMarkers, oldUnitsMarkers) {
                    if (Object.keys(oldUnitsMarkers).length === 0) {
                        // console.log('unitsMarkers', Object.keys(newUnitsMarkers).length, Object.keys(oldUnitsMarkers).length)
                    }
                    this.debouncedUpdateMarkers();
                },
                deep: true
            },
            geozonesLOptions: {
                handler: function (newGeoLOptions, oldGeoLOptions) {
                    if (Object.keys(oldGeoLOptions).length === 0) {
                        // console.log('geozonesLOptions', Object.keys(newGeoLOptions).length, Object.keys(oldGeoLOptions).length)
                    }
                    this.debouncedUpdateGeozones();
                },
                deep: true
            },
            // getSectionInfo: {
            //     handler: function (newSection, oldSection) {
            //         console.log('getSectionInfo', {...newSection}, {...oldSection})
            //     },
            //     deep: true
            // },
            getGeoitemEdit: {
                handler: function (newGeo, oldGeo) {
                    // console.log('getGeoitemEdit', (newGeo?{...newGeo}:newGeo), (oldGeo?{...oldGeo}:oldGeo))
                    this.updateGeoitemEdit(newGeo, oldGeo)

                    //let vueLayer =  this.$refs.geoitemsLayer
                    let overlay = this.overlay['geoitems']
                    if (newGeo && !oldGeo && this.route_name == 'Units') {
                        overlay.old = overlay.visible
                        overlay.visible = false
                        overlay.type = '-'
                    } else
                    if(!newGeo && overlay.type === '-'){
                        overlay.visible = true
                        if(!overlay.old && this.route_name !== 'Geoitems'){
                            setTimeout(() => {
                                overlay.visible = false
                            }, 300)
                        }
                        delete overlay.old
                        overlay.type = 'overlay'
                    }
                    // console.log('getGeoitemEdit', (newGeo?{...newGeo}:newGeo), {...overlay})
                },
                deep: true
            },
            parked: {
                handler: function (newTrack, oldTrack) {
                    if (oldTrack || newTrack) {
                        // console.log('track', newTrack, oldTrack)
                    }
                    this.debouncedUpdateUnitParked();
                },
            },
            track: {
                handler: function (newTrack, oldTrack) {
                    if (oldTrack || newTrack) {
                        // console.log('track', newTrack, oldTrack)
                    }
                    this.debouncedUpdateUnitTrack();
                },
                //deep: true
            },
            getUnitMoveOnTrack: {
                handler: function (newIndex, oldIndex) {
                    if (oldIndex || newIndex) {
                        // console.log('getUnitMoveOnTrack', newIndex, oldIndex)
                    }
                    if (newIndex) this.unitMoveOnTrack(newIndex)
                    else if(oldIndex) {
                        if(this.trackUnitMarkerInterval){
                            clearInterval(this.trackUnitMarkerInterval)
                            this.trackUnitMarkerInterval = null
                        }
                        if(this.trackUnitMarker) {
                            this.map.removeLayer(this.trackUnitMarker)
                            this.trackUnitMarker = null;
                        }
                    }
                },
            },
            getNotificationEventShow: {
                handler: function (newEvent, oldEvent) {
                    if (oldEvent || newEvent) {
                        // console.log('showNotificationEvent', newEvent, oldEvent)
                    }
                    //this.debouncedShowNotificationEvent();
                    this.showNotificationEvent(newEvent)
                },
                deep: true
            },
            getShowMarkerOnTrack: {
                handler: function (newVal) {
                    if(newVal){
                        let type = newVal.type ? newVal.type.split('.')[0] : ''
                        let group = this.trackEventsPointsGroup.find(gr => gr.type == 'control-' + type)
                        if(!group) {
                            console.warn('trackEventsPointsGroup - not found')
                            return
                        }
                        let marker = group.layers.find(m => m.meta.timestamp == newVal.time)
                        if(!marker) {
                            console.warn('trackEventsPointsGroup.layers.marker - not found')
                            return
                        }
                        setTimeout(() => marker.openPopup() , 500)
                    }
                },
                deep: true
            },
        },
        methods: {
            ...mapActions([
                "setMapBoundsByUnits",
                "setMapBounds",
                "setMapCenter",
                "patchGeoitemEdit",
                "setUnitMoveOnTrack",
                "setSectionInfo",
                "setSectionWidget",
                "setShowUnitTrackIndex",
                "setUnitTracking",
                "setShowMarkerOnTrack",
                "setUnitActive",
                "setUnitTracking"
            ]),
            openGeozoneEdit(){
                let units = this.unitsOnMap
                if(!units || !units.length || units.length>1) return;
                let latlng = this.unitsLmsgsLatlngByIds[units[0]]
                if(!latlng) return;
                let radius = 100
                let options = {
                    name: '#',
                    points: [[latlng.lat,latlng.lng]],
                    radius: radius,
                    area: Math.pow(radius, 2) * Math.PI,
                }
                //this.setMapBounds([-r,+r])
                this.setMapCenter(latlng)
                this.zoom = 18//this.maxZoom
                this.setSectionInfo({
                    component: 'GeozoneEdit',
                    props: {
                        geoitemId: null,
                        time: Date.now(),
                        options,
                    }
                })
            },
            displaySectionInfo(component, props) {
                props = props || {}
                props.time = Date.now()
                this.setSectionInfo({component, props})
            },
            displaySectionWidget(component, props) {
                props = props || {}
                props.time = Date.now()
                this.setSectionWidget({component, props})
            },
            onReady() {
                this.map = this.$refs.map.mapObject
                this.unitsCluster = this.$refs.unitsCluster.mapObject
                this.unitsLayer = this.$refs.unitsLayer.mapObject
                this.geoitemsLayer = this.$refs.geoitemsLayer.mapObject

                this.trackControlPointsLayerCluster = this.$refs.trackCluster.mapObject
                //let hideTooltips = false//(this.getAppUserSettings.hideMarkerTooltipForDetailedTrack && type === 'detailed')
                //this.addTrackClusterEvents(0, hideTooltips)
                this.trackControlPointsLayerCluster.on('clusterclick', (a) => {
                    a.layer.zoomToBounds();
                    // console.log('click cluster')
                    this.setShowMarkerOnTrack({})
                    // this.setShowUnitTrackIndex() //!!! no
                })

                setTimeout(() => {
                    this.map.invalidateSize()
                    // this.initLocation()
                }, 500)
            },
            getIcon(unit) {
                return icon({
                    iconUrl: unit.icon || require('@/assets/img/unit/common__car_lg.png'),
                    iconSize: [21, 31],
                    iconAnchor: [16, 16],
                    popupAnchor: [4, -25],
                })
            },
            afterRouteChange(to, from) {
                // if (from === 'Tracks'){
                // }
                if (from === 'Units') {
                    this.overlay['units'].visible = false
                }
                if (to === 'Units') {
                    this.overlay['units'].visible = true
                }
                if (to === 'Geoitems') {
                    this.overlay['geoitems'].visible = true
                }
            },
            setGeoitems(newGeos) {
                this.geoitems.forEach(geo => {
                    if (!geo.options.geozoneId) return false
                    if (newGeos.includes(geo.options.geozoneId)) {
                        // console.log('addLayer', geo.options)
                        this.geoitemsLayer.addLayer(geo)
                    } else if (this.getGeoitemEdit &&
                        //this.getGeoitemEdit.geoitemId == geo.options.geozoneId  ||
                        //this.getGeoitemEdit.geopointId == geo.options.geozoneId ||
                        this.getGeoitemEdit.geozoneId == geo.options.geozoneId
                    ) {
                        // console.log('skip getGeoitemEdit', this.getGeoitemEdit, geo.options)
                    } else {
                        // console.log('removeLayer', geo.options)
                        this.geoitemsLayer.removeLayer(geo)
                    }
                })
            },
            // hideGeoitems(geoIds){},
            // showGeoitems(geoIds){},
            updateGeozones(forceUpdateId) {
                //console.log('updateGeozones', this.geoitems.length)
                if (!this.geozonesLOptions) return false;

                //remove geozones
                let ids = this.geozonesLOptions.map(go => go.geozoneId)
                this.geoitems.forEach(geo => {
                    if (geo.options.geozoneId && !ids.includes(geo.options.geozoneId))
                        this.geoitemsLayer.removeLayer(geo)
                })

                // let geoitems = []
                this.geozonesLOptions.forEach(geozoneLOptions => {
                    if (forceUpdateId && forceUpdateId != geozoneLOptions.geozoneId) {
                        return false;
                    } else
                    if (this.getGeoitemEdit && (
                        //this.getGeoitemEdit.geoitemId == geozoneLOptions.geoitemId  ||
                        //this.getGeoitemEdit.geopointId == geozoneLOptions.geopointId ||
                        this.getGeoitemEdit.geozoneId == geozoneLOptions.geozoneId
                    )) {
                        //console.log('skip geozone: ', geozoneLOptions.geozoneId, geozoneLOptions)
                        return false;
                    }

                    let indx = this.geoitems.findIndex(geo => {
                        return geo.options.geozoneId == geozoneLOptions.geozoneId
                    })
                    if (forceUpdateId) {
                        // console.log('forceUpdate', forceUpdateId, indx)
                    }
                    let geozone = null
                    if (indx !== -1) {
                        geozone = this.geoitems[indx]
                        //if(!(_.isEqual(geozone.options, geozoneLOptions)))
                        if (JSON.stringify(geozone.options) !== JSON.stringify(geozoneLOptions)) {
                            //console.log('geozone.options was changed')
                            this.geoitemsLayer.removeLayer(geozone)//???
                            this.geoitems.splice(indx, 1)
                            indx = -1
                        }
                    }
                    if (indx === -1) {
                        geozone = this.createGeoiteme(geozoneLOptions)
                        if(!geozone) {
                            console.error('Can\'t create Geoiteme', geozoneLOptions)
                            return 
                        }
                        // geozone.bindPopup(getGeozonePopupContent(geozoneLOptions), {noHide: true});
                        geozone.bindTooltip(geozoneLOptions.name, {
                            permanent: (true),
                            direction: 'bottom',
                            className: 'geozone-tooltip',
                            offset: [0, 0],
                        });
                        //geozone.on('click',function(e){this.map.fire('click',e)});
                        try {
                            this.geoitems.push(geozone)
                            if (forceUpdateId && this.geoitemsOnMap.includes(forceUpdateId)) {
                                this.geoitemsLayer.addLayer(geozone);
                            }
                        } catch (e) {
                            console.error(e, geozoneLOptions)
                        }
                    }
                })
                //this.geoitems.push(...geoitems)
                //this.geoitemsLayer.addLayers(geoitems)
            },
            updateGeoitemEdit(newGeo, oldGeo) {
                if (
                    newGeo && oldGeo && (
                        (newGeo.geoitemId && newGeo.geoitemId == oldGeo.geoitemId) ||
                        (newGeo.geopointId && newGeo.geopointId == oldGeo.geopointId) ||
                        (newGeo.geozoneId && newGeo.geozoneId == oldGeo.geozoneId)
                    )
                ) {
                    if (newGeo.color !== oldGeo.color) {
                        let color = (newGeo.color ? newGeo.color : '#00ff00')
                        if (this.drawLayer) {
                            this.drawLayer.setStyle({
                                color: color,
                                fillColor: color,
                                fillOpacity: 0.5
                            })
                        }
                        if (this.drawControl) {
                            this.drawControl.options.draw.polygon.drawError.color =
                            this.drawControl.options.draw.polygon.shapeOptions.color =
                            this.drawControl.options.draw.polyline.shapeOptions.color =
                            this.drawControl.options.draw.circle.shapeOptions.color =
                                color;
                        }
                    }
                    //console.log('skip geoitem')
                    return;
                }

                let item = null;
                if (oldGeo) {
                    item = this.geoitems.find(g => {
                        return g.options.geozoneId == oldGeo.geozoneId
                    })
                    if (item) {
                        item.editing.disable();
                        //Ln:  //JSON.stringify(geozone.options) !== JSON.stringify(geozoneLOptions)
                        item.options.forceUpdate = 1//!!! ???
                        this.updateGeozones(oldGeo.geozoneId)
                    } else
                    if (this.drawLayer) {//???
                        this.map.removeLayer(this.drawLayer);
                    }
                    if (this.draw) {
                        this.draw.disable();
                    }
                }

                if (!newGeo) {//close
                    try { // [!] Mega hack 🤘
                        if (this.drawLayer && !item) {//???
                            this.map.removeLayer(this.drawLayer);
                        }
                        if (this.draw) {
                            this.draw.disable();
                        }
                        if (this.drawnItems) {
                            this.map.removeLayer(this.drawnItems);
                        }
                        if (this.areaLayer) {
                            this.map.removeLayer(this.areaLayer);
                        }
                        if (this.areaLabel) {
                            this.map.removeLayer(this.areaLabel);
                        }
                        if (this.areaPolygon) {
                            this.areaPolygon.disable();
                            this.areaPolygon = null;
                        }
                    } catch (error) {
                        console.error(error);
                    }
                    return;
                }

                // console.log('getGeoitemEdit', {...newGeo}, {...oldGeo}, this.geoitemEdit)
                if (!oldGeo) {
                    this.drawnItems = new L.FeatureGroup();
                    this.map.addLayer(this.drawnItems);
                    this.drawControl = new L.Control.Draw({
                        draw: {
                            position: 'topleft',
                            polygon: {
                                title: ('Draw polygon!'),
                                allowIntersection: false,
                                drawError: {
                                    color: newGeo.color, //'#00ff00',
                                    timeout: 1000
                                },
                                shapeOptions: {
                                    color: newGeo.color, //'#00ff00'
                                },
                                showArea: true
                            },
                            polyline: {
                                shapeOptions: {
                                    color: newGeo.color, //'#00ff00'
                                }
                            },
                            marker: false,
                            rectangle: false,
                            circle: {
                                shapeOptions: {
                                    color: newGeo.color, //'#00ffff'
                                }
                            }
                        },
                        edit: {
                            featureGroup: this.drawnItems
                        }
                    });
                    //this.map.addControl(this.drawControl);//???
                }
                {
                    this.map.on(L.Draw.Event.CREATED, (e) => {
                        // console.log(L.Draw.Event.CREATED, e);
                        var type = e.layerType,
                            layer = e.layer;
                        this.drawLayer = layer;
                        this.drawLayer.editing.enable();
                        this.drawnItems.addLayer(layer);

                        if (type == 'polyline') {
                            /*draw temp polygone for polyline*/
                            if (this.drawLayer._latlngs) {
                                this.drawLayer.layerType = 'polyline';
                                this.DrawPolylineOnMap(this.drawLayer._latlngs);
                            }
                        }
                        //onDrawCreatedTrigger = true;
                    });

                    this.map.on(L.Draw.Event.DRAWVERTEX, (e) => {
                        console.log(L.Draw.Event.DRAWVERTEX, e);
                    });
                    this.map.on(L.Draw.Event.EDITVERTEX, (e) => {
                        console.log(L.Draw.Event.EDITVERTEX, e);
                        if (this.drawLayer && this.drawLayer._latlngs && this.drawLayer.layerType == 'polyline') {
                            this.DrawPolylineOnMap(this.drawLayer._latlngs);
                        }
                    });
                    this.map.on(L.Draw.Event.DRAWSTOP, (e) => {
                        console.log(L.Draw.Event.DRAWSTOP, e);
                        let data = {}
                        if (this.drawLayer) {
                            data = this.getDataFromLayer(this.drawLayer)
                        }
                        // console.log(L.Draw.Event.DRAWSTOP, data);
                        this.patchGeoitemEdit(data)
                    });
                    this.map.on(L.Draw.Event.EDITMOVE, (e) => {
                        console.log(L.Draw.Event.EDITMOVE, e);
                        let data = {}
                        if (this.drawLayer) {
                            data = this.getDataFromLayer(this.drawLayer)
                        }
                        // console.log(L.Draw.Event.EDITMOVE, data);
                        this.patchGeoitemEdit(data)
                    });
                    this.map.on(L.Draw.Event.EDITRESIZE, (e) => {
                        console.log(L.Draw.Event.EDITRESIZE, e);
                        let data = {}
                        if (this.drawLayer) {
                            data = this.getDataFromLayer(this.drawLayer)
                        }
                        // console.log(L.Draw.Event.EDITRESIZE, data);
                        this.patchGeoitemEdit(data)
                    });
                    this.map.on(L.Draw.Event.EDITED, (e) => {//???
                        console.log(L.Draw.Event.EDITED, e);
                        var layers = e.layers;
                        var countOfEditedLayers = 0;
                        layers.eachLayer(function (/*layer*/) {
                            countOfEditedLayers++;
                        });
                        console.log("Edited " + countOfEditedLayers + " layers");
                    });
                }

                if (this.geoitemEdit) {
                    let geoitem = this.geoitemEdit
                    if (!this.map.hasLayer(geoitem)) {
                        this.geoitemsLayer.addLayer(geoitem)
                    }
                    let bounds = geoitem.getBounds()
                    if (bounds) {
                        this.map.fitBounds(bounds, {maxZoom: this.zoom, padding: [100, 100]})
                    }
                    if (geoitem.options.geozoneId) {
                        geoitem.editing.enable();
                        this.drawLayer = geoitem
                        if (geoitem.options.layerType == 'polyline') {
                            this.drawLayer.layerType = 'polyline';
                        }
                        let data = this.getDataFromLayer(geoitem)
                        // console.log('geoitemEdit editing', data);
                        this.patchGeoitemEdit(data)
                    }
                } else
                {
                    let geozone = this.createGeoiteme(newGeo)
                    //debugger
                    if(geozone){
                        //this.geoitemsLayer.addLayer(geozone);
                        this.drawLayer = geozone;
                        this.drawLayer.editing.enable();
                        this.drawnItems.addLayer(geozone);
                        if (newGeo.type == 'polyline') {//draw temp polygone for polyline
                            if (this.drawLayer._latlngs) {
                                this.drawLayer.layerType = 'polyline';
                                this.DrawPolylineOnMap(this.drawLayer._latlngs);
                            }
                        }
                        setTimeout(()=> {
                            let data = this.getDataFromLayer(this.drawLayer)
                            if(data) this.patchGeoitemEdit(data)
                        },100)
                    } else {
                        switch (newGeo.type) {
                            case 'circle': {
                                this.draw = new L.Draw.Circle(this.map, this.drawControl.options.draw.circle);
                            }
                            break;
                            case 'polygon': {
                                this.draw = new L.Draw.Polygon(this.map, this.drawControl.options.draw.polygon);
                            }
                            break;
                            case 'corridor': {
                                this.draw = new L.Draw.Polyline(this.map, this.drawControl.options.draw.polyline);
                            }
                            break;
                        }
                        this.draw.enable();
                    }
                }
            },
            createGeoiteme(geoitemLOptions){
                let geoitem = null
                let options = {...geoitemLOptions}
                //fillColor???
                if(!options.fillOpacity) options.fillOpacity = 0.5
                if (options.type === 'circle') {
                    if(options.points && options.points.length == 1 && options.radius) {
                        let center = options.points[0]
                        if (center.length > 2) center = center.slice(0, 2);
                        geoitem = L.circle(center, options.radius, options);
                    }
                } else
                if (options.type === 'polygon') {
                    if(options.points && options.points.length>1) {
                        geoitem = L.polygon(options.points, options);
                    }
                } else
                if (options.type === 'corridor') {
                    options.layerType='polyline'
                    options.polyline_radius=options.radius;
                    options.corridor= options.radius/2;
                    if(options.points && options.points.length>1) {
                        geoitem = L.corridor(options.points, options);
                    }
                }
                return geoitem
            },
            setMarkers(newUnits) {
                let removed = this.unitsMarkers.filter(m => {
                    return m.meta && !newUnits.includes(m.meta.id)
                }).map(m => m.meta.id)
                if (removed.length) this.removeMarkers(removed);
                this.addMarkers(newUnits);
            },
            addMarkers(unitIds) {
                let markers = []
                // let colors = []
                unitIds.forEach(id => {
                    let options = this.getUnitsMarkers[id]
                    if (!options || !options.latlng) return false

                    let marker = this.unitsMarkers.find(m => {
                        return m.meta && m.meta.id == id
                    })
                    if (!marker) {
                        marker =
                            //L.marker(options.latlng, { icon: icon })
                            new DriftMarker(options.latlng, { icon: unitIcon })
                        // L.Marker.movingMarker([options.latlng], 100, { icon: unitIcon, autostart: true})
                        // L.animatedMarker(options.latlng, { icon: unitIcon,
                        //     // distance: 500,  // meters
                        //     // interval: 1000, // milliseconds
                        // })
                        let html = this.htmlToElement(options.iconHtml)
                        marker.setIcon(L.divIcon({
                            className: 'direction-enabled' + (options.icon_has_halo ? ' has-halo' : ''),
                            iconSize: [32, 32],
                            html,
                        }));
                        marker.on('click', this.markerOnClick)
                        marker.meta = {...options}
                        markers.push(marker)
                    }
                })
                this.unitsMarkers.push(...markers)
                this.unitsCluster.addLayers(markers)
            },
            removeMarkers(unitIds) {
                // console.log('removeMarkers', this.unitsMarkers.length)
                let markers = this.unitsMarkers.filter(m => {
                    return m.meta && unitIds.includes(m.meta.id)
                })
                this.unitsCluster.removeLayers(markers)
                this.unitsMarkers = this.unitsMarkers.filter(m => {
                    return m.meta && !unitIds.includes(m.meta.id)
                })
                // console.log('removeMarkers', this.unitsMarkers.length)
            },
            switchUnitTracking(newUnitId) {
                // this.addMarkers([newUnitId])
                // let marker = this.unitsMarkers.find(m => {
                //     return m.meta && m.meta.id == newUnitId
                // })
                let lmsg = this.unitsLmsgsByIds[newUnitId]
                // console.log('switchUnitTracking', {...lmsg.latlng})
                if (lmsg && lmsg.latlng) {
                    this.setMapCenter(lmsg.latlng)
                    this.zoom = 15
                }
            },
            updateMarkers() {
                if (!this.unitsOnMap || !this.unitsOnMap.length) return false;
                // console.log('updateMarkers', this.unitsMarkers.length, this.unitsOnMap.length)

                let updated = 0
                let moved = 0
                this.unitsOnMap.forEach(id => {
                    let meta = this.getUnitsMarkers[id]
                    if (!meta || !meta.latlng) return false

                    let marker = this.unitsMarkers.find(m => {
                        return m.meta && m.meta.id == id
                    })
                    if (!marker) {
                        return false
                    }
                    if (marker.meta.iconHtml !== meta.iconHtml) {
                        //let html = this.htmlToElement(meta.iconHtml)
                        marker.setIcon(L.divIcon({
                            className: 'direction-enabled' + (meta.icon_has_halo ? ' has-halo' : ''),
                            iconSize: [32, 32],
                            html: meta.iconHtml,
                        }));
                        //marker.meta.iconHtml = meta.iconHtml
                        updated++
                    }
                    if (marker.meta.time < meta.time) {
                        let isMoveTo = (this.unitTracking === id) ||
                            (this.bounds && (this.bounds.contains(marker.getLatLng()) || this.bounds.contains(meta.latlng)))
                        if (isMoveTo) {
                            let options = {
                                duration: 3000,
                                keepAtCenter: false,
                            }
                            if (this.unitTracking === id) {
                                options.keepAtCenter = true
                            }
                            // if(options.keepAtCenter) //console.log('marker.slideTo', {...meta.latlng}, {...options}, marker.slideTo)
                            marker.slideTo(meta.latlng, options)//overloads style settings
                            if (options.keepAtCenter) {
                                marker._slideDraggingWasAllowed = true;//Hack
                            }
                            moved++
                        } else {
                            marker.setLatLng(meta.latlng)//overloads style settings
                            moved++
                        }
                        //marker.meta.time = meta.time
                    }
                    marker.meta = {...meta}
                })
                if (this.unitsMarkers.length<0) {
                    console.log('updateMarkersLatLng', this.unitsMarkers.length, 'moved=', moved, 'updated=', updated)
                }
            },
            updateMarkersLatLng() {
                if (!this.unitsOnMap || !this.unitsOnMap.length) return false;
                // console.log('updateMarkers latlng', this.unitsMarkers.length, this.unitsOnMap.length)

                let updated = 0
                let moved = 0
                this.unitsOnMap.forEach(id => {
                    let lmsg = this.unitsLmsgsByIds[id]
                    if (!lmsg || !lmsg.latlng || !lmsg.latlng.lat || !lmsg.latlng.lng) return false

                    let marker = this.unitsMarkers.find(m => {
                        return m.meta && m.meta.id == id
                    })
                    if (!marker || marker.meta.time >= lmsg.time) {
                        return false
                    }
                    {
                        let isMoveTo = (this.unitTracking === id) ||
                            (this.bounds && (this.bounds.contains(marker.getLatLng()) || this.bounds.contains(lmsg.latlng)))
                        if (isMoveTo) {
                            let options = {
                                duration: 3000,
                                keepAtCenter: false,
                            }
                            if (this.unitTracking === id) {
                                options.keepAtCenter = true
                            }
                            marker.slideTo(lmsg.latlng, options)//overloads style settings
                                ._slideDraggingWasAllowed = true;//Hack
                            moved++
                        } else {
                            marker.setLatLng(lmsg.latlng)//overloads style settings
                            updated++
                        }
                        //transform: rotate(45deg);
                        marker.getIcon().options.html
                            .getElementsByClassName('unit-marker-svg-icon')[0]
                            .style.transform = 'rotate(' + lmsg.course + 'deg)'
                        marker.meta.time = lmsg.time
                    }
                })
                if (this.unitsMarkers.length < 0)
                    console.log('updateMarkersLatLng', this.unitsMarkers.length, 'moved=' + moved, 'updated=' + updated)
            },
            updateMarkersOptions() {
                if (!this.unitsOnMap || !this.unitsOnMap.length) return false;
                // console.log('updateMarkers options', this.unitsMarkers.length, this.unitsOnMap.length)

                let updated = 0
                this.unitsOnMap.forEach(id => {
                    let options = this.getUnitsMarkerOptions[id]
                    if (!options) return false

                    let marker = this.unitsMarkers.find(m => {
                        return m.meta && m.meta.id == id
                    })
                    if (!marker) {
                        return false
                    }
                    if (marker.meta.iconHtml !== options.iconHtml) {
                        let html = this.htmlToElement(options.iconHtml)
                        marker.setIcon(L.divIcon({
                            className: 'direction-enabled' + (options.icon_has_halo ? ' has-halo' : ''),
                            iconSize: [32, 32],
                            html,
                        }));
                        updated++
                    }
                    if (marker.meta.iconHtml !== options.iconHtml || marker.meta.icon_color !== options.icon_color) {
                        let html = marker.getIcon().options.html
                        let icon = html.getElementsByClassName('unit-marker-svg-icon')[0]
                        icon.style.color = options.icon_color
                        marker.meta.icon_color = options.icon_color
                    }
                })
                if (this.unitsMarkers.length < 0)
                    console.log('updateMarkersOptions', this.unitsMarkers.length, 'updated=' + updated)
            },
            unitMoveOnTrack(index) {
                let track = this.getTracksFiltered.find(t => {
                    return t.timeFrom == index.time_start && t.timeTo == index.time_end
                })
                if (!track) {
                    console.error('track.msgs not found', index)
                    return
                }
                // track = track.msgs.reduce((out, msg) => {
                //     out.push({latlng: msg.latlng, course: msg.course})
                //     return out
                // }, [])
                let options =
                    //this.getUnitsMarkerOptions[this.track.unit_id]
                    this.getUnitsMarkers[this.track.unit_id]

                //console.log('unitMoveOnTrack', track, options)
                if(this.trackUnitMarker) {
                    if(this.trackUnitMarkerInterval) {
                        clearInterval(this.trackUnitMarkerInterval)
                        this.trackUnitMarkerInterval = null
                    }
                    this.map.removeLayer(this.trackUnitMarker)
                    this.trackUnitMarker = null
                }
                this.trackUnitMarker =
                    // new DriftMarker(track.msgs[0].latlng, { icon: unitIcon })
                    L.Marker.movingMarker([track.msgs[0].latlng], 0, {icon: unitIcon, autostart: false})
                    // L.marker(track.msgs[0].latlng, { icon: unitIcon })
                    // L.animatedMarker(track.msgs[0].latlng, { icon: unitIcon,
                    //     // distance: 500,  // meters
                    //     // interval: 1000, // milliseconds
                    // })
                let html = this.htmlToElement(options.iconHtml)
                //html.getElementsByClassName('unit-marker-label')[0].style.display = 'none'
                let svg = html.getElementsByClassName('unit-marker-svg-icon')[0]
                // let course = Math.round(parseInt(track.msgs[0].course)/5)*5
                svg.style.transform = 'rotate(0deg)'
                this.trackUnitMarker.setIcon(L.divIcon({
                    className: 'direction-enabled unit-marker-label-hide ',
                    iconSize: [32, 32],
                    html,
                }));
                this.trackUnitMarker.addTo(this.map)
                // this.trackLayer.addLayer(this.trackUnitMarker)

                let msgs = track.msgs.slice(1);
                if(this.trackUnitMarkerInterval) clearInterval(this.trackUnitMarkerInterval)
                this.trackUnitMarkerInterval = setInterval(() => {
                    if(!msgs.length || !this.trackUnitMarker) {
                        clearInterval(this.trackUnitMarkerInterval)
                        setTimeout(() => this.setUnitMoveOnTrack(null), 500)
                        return
                    }
                    if(this.trackUnitMarker.isRunning()) return;
                    if(this.trackUnitMarker.isPaused()) {
                        console.warn('trackUnitMarker.isPaused')
                        return;
                    }

                    let point = msgs.shift();
                    // let course = Math.round(parseInt(point.course)/5)*5
                    let p1 = this.trackUnitMarker.getLatLng()
                    let p2 = L.latLng(point.latlng)
                    let _lng = p2.lng-p1.lng
                    let _lat = p2.lat-p1.lat
                    let course = ((Math.atan2((_lng), (_lat)))*(180/Math.PI)/5)*5
                    course += course < 0 ? 360 : 0;
                    // console.log("course",course,point.course)
                    let html = this.trackUnitMarker.getElement()
                    // let svg = html.getElementsByClassName('unit-marker-svg-icon')[0]
                    // elem = elem.querySelector('.svg-icon-unit-marker')
                    let svg = html.getElementsByClassName('svg-icon-unit-marker')[0]
                    svg.style.transform = 'rotate(' + course + 'deg)'

                    let d =  Math.sqrt((Math.pow((_lat*100000/1.112),2)) + (Math.pow((_lng*100000/1.112),2)))
                    d = d*5/this.getUnitMoveOnTrackSpeed
                    let duration = Math.round(d/100)*100
                    this.trackUnitMarker.moveTo(point.latlng, duration)
                }, 100);
            },
            updateUnitParked() {
                // console.log('updateUnitParked', this.parked)
                if (this.parkedLayer) {//remove markers
                    this.map.removeLayer(this.parkedLayer)
                    this.parkedLayer = null
                }
                if (this.parkedMarker) {//remove markers
                    this.map.removeLayer(this.parkedMarker)
                    this.parkedMarker = null
                }
                if (!this.parked) return false;
                // let markers = this.createTrackPoints([this.parked], this.parked.unit_id, 'parking');
                // this.parkedMarker = markers[0]
                // this.map.addLayer(this.parkedMarker)
                // this.map.fitBounds([this.parked.coord_start], { padding: [50, 50] })
                // let popup = ''+
                //     '<div class="popup-content__table-content">' +
                //     '<table class="popup-content__table">' +
                //     '<tr>' +
                //     '<td colspan="2">'+'Parking'+'</td>' +
                //     '</tr>' +
                //     '<tr>' +
                //     '<td>'+this.$t('text.Time')+':</td>' +
                //     '<td>'+this.parked.time_start.formated['time-s']+'</td>' +
                //     '</tr>' +
                //     '<tr>' +
                //     '<td>'+this.$t('text.Duration')+':</td>' +
                //     '<td>' + this.parked.duration.formated + '</td>' +
                //     '</tr>' +
                //     '</table>' +
                //     '</div>'
                let label = '' +
                    this.parked.time_start.formated['time-s'] + //'<br/>' +
                    ' - '+this.$t('text.Parking')+': ' + this.parked.duration.formated;
                this.parkedMarker = this.createMarker(this.parked.coord_start, 'startFinish', '', this.parked.unit_id + '_parking', require('@/assets/img/track/map__pin-parking.png'), label);
                if (this.parkedMarker) this.map.addLayer(this.parkedMarker)
                this.setMapCenter(this.parked.coord_start)
                this.parkedMarker.openPopup()
            },
            updateUnitSpeedColor(){
                if(this.trackLayer) {
                    if(this.getShowUnitTrackColor === true) {
                        this.trackLayer.eachLayer(function (layer) {
                            layer.getElement().classList.remove('track-layer-color-none')
                            layer.getElement().classList.add('track-layer-color')
                        });
                    } else
                    if(this.getShowUnitTrackColor === false) {
                        this.trackLayer.eachLayer(function (layer) {
                            layer.getElement().classList.add('track-layer-color-none')
                            layer.getElement().classList.remove('track-layer-color')
                        });
                    }
                }
            },
            updateUnitTrack() {
                // console.log('updateUnitTrack', this.track)
                if (this.trackLayer) {//remove markers
                    this.map.removeLayer(this.trackLayer)
                    this.trackLayer = null

                    this.trackMarkers.forEach((marker) => {
                        this.map.removeLayer(marker)
                    })
                    this.trackMarkers = []

                    this.trackEventsPointsGroup.forEach((g) => {
                        this.trackControlPointsLayerCluster.removeLayers(g.layers);
                    })
                    this.trackEventsPointsGroup = []
                }
                {//remove
                    //this.clearTrack(params.unitId);
                    {
                        //this.clearMarkers('stop', false, unitId + '_');
                        //this.clearMarkers('parking', false, unitId + '_');
                        //this.clearMarkers('startFinish', false, unitId + '_');
                        // if (this.tracks[i].popup) {
                        //     this.map.removeLayer(this.tracks[i].popup.layer);
                        // }
                        // this.map.removeLayer(this.tracks[i].layer);
                        // this.clearTrackControlPoints(unitId);
                        // this.clearEventsPoints(unitId);
                        // this.clearTrackDecorator(unitId);
                        // this.methods.clearManeuverMarkers(unitId);
                        // {
                        //     this.trackManeuverLayerCluster.removeLayers(this.trackManeuversPointsGroup[i].layers);
                        // }
                        // this.methods.clearIdleMarkers(unitId);
                        // {
                        //     this.trackIdleLayerCluster.removeLayers(this.trackIdlePointsGroup[i].layers);
                        // }
                        // this.clearMarkers('startFinish', unitId, false);
                    }
                    //this.clearMarkers('parking');
                    //this.clearMarkers('stop');
                }
                if (!this.track) return false;

                //let detail = false//this.track.params.detail
                if (this.track.trackObjects.length) {
                    if (!this.track.msgs) {
                        this.$toast.error(this.$t("No messages"))
                        return false;
                    }
                    let track = this.createTrack(this.track.trackObjects[0], this.track.unit_id, false, this.track.msgs);
                    if (track === false) {
                        this.$toast.error(this.$t("Not enough points for the track"))
                        return false;
                    } else {
                        this.trackLayer = track.layer
                        this.updateUnitSpeedColor()
                        //this.map.fitBounds(track.layer.getBounds());
                    }

                    if (this.track.hasDrivingQuality) {
                        //this.lastDrivingQualityTrackData = this.track;
                        //this.methods.showDrivingQualityWindow();
                        //this.methods.processTrackDrivingQualityData(this.track);
                    }

                    // this.createTrackControlPoints(this.track.controlPoints, this.track.unit_id, (detail? 'detailed': ''));

                    if (this.stops && this.stops.length) {
                        this.createTrackPoints(this.stops, this.track.unit_id, 'stop', true);
                    }

                    if (this.events && this.events.length) {
                        this.createTrackPoints(this.events, this.track.unit_id, 'event', true);
                    }

                    if (this.track.msgs && this.track.msgs.length > 1) {
                        let marker, msg

                        //start A
                        msg = this.track.msgs[0]
                        marker = this.createMarker(msg.latlng, 'startFinish', '', this.track.unit_id + '_start', require('@/assets/img/track/map__pin-a-point.png'));
                        if (marker) this.trackMarkers.push(marker)

                        //finish B
                        msg = this.track.msgs[this.track.msgs.length - 1]
                        marker = this.createMarker(msg.latlng, 'startFinish', '', this.track.unit_id + '_finish', require('@/assets/img/track/map__pin-b-point.png'));
                        if (marker) this.trackMarkers.push(marker)

                        this.trackMarkers.forEach(m => {
                            m.on('click', () => {
                                this.setMapBounds(this.trackLatLngs)
                            })
                        })
                    }
                }
            },
            createTrack(trackObject, id, popupData, messages) {

                let trackLayers = [], decoratorsLayers = [], popup;
                trackObject.trackMessageIndexes.forEach(function (trackMessageIndex, sequentialIndex) {
                    let el = trackObject.tracks[sequentialIndex];
                    let latlon = messages.slice(trackMessageIndex.first, trackMessageIndex.last + 1).map(msg => msg.latlng);
                    if ((trackMessageIndex.last - trackMessageIndex.first) < 1) {
                        return;
                    }

                    let options = {
                        color: '#' + el.color,
                        opacity: el.opacity,
                        noClip: true,
                        smoothFactor: 3,
                        weight: el.weight,
                    }
                    if (el.dashArray) {
                        options.dashArray = '5,10'
                    }
                    let trackPoly = L.polyline(latlon, options);

                    if (trackObject.trackMessageIndexes.length < 20) {
                        let decorator = L.polylineDecorator(trackPoly, {
                            patterns: [
                                {
                                    offset: 0,
                                    repeat: 1000,
                                    symbol: L.Symbol.marker({
                                        rotate: true,
                                        markerOptions: {
                                            icon: L.icon({
                                                iconUrl: require('@/assets/img/track/direction-icon.svg'),//?color='+el.color,
                                                iconSize: [20, 20],
                                                iconAnchor: [10, 10],
                                                popupAnchor: [0, -15],
                                                className: 'track-direction-arrow'
                                            })
                                        },
                                        angleCorrection: 90
                                    })
                                }
                            ]
                        });//.addTo(this.map);
                        decoratorsLayers.push(decorator);
                    }

                    trackLayers.push(trackPoly);//ToDo ???

                    if (popupData && !popup) {
                        popup = L.popup({autoPan: false, className: 'track-popup'});
                        let middleIndex = Math.floor(el.latlon.length / 2);

                        popup.setLatLng(latlon[middleIndex])
                            .setContent(
                                '<span class="track-popup-title">' + this.$t('text.Unit') + ':' + '</span> ' + popupData.unitName +
                                '<br />' +
                                '<span class="track-popup-title">' + this.$t('text.Mileage') + ':' + '</span> ' + popupData.distance
                            );
                        //popups[trackLayers.length-1]=popup;
                    }
                });

                if (!trackLayers.length) {
                    return false;
                }

                /*
                let trackMulti = {};
                tracks.forEach(function(el,ind){

                    if(!(trackMulti[el.color] instanceof Array)){
                        trackMulti[el.color] = [];
                    }

                    trackMulti[el.color].push(el.latlon);
                });
                let multiCollection = [];
                for(let color in trackMulti){
                    let multiLine = new L.MultiPolyline(trackMulti[color], {color: '#'+color, opacity: 0.7});
                    multiCollection.push(multiLine);
                }*/
                if (1 == 0 && trackObject.trackMessageIndexes.length < 20) {//ToDo fix
                    let decoratorGroup = {
                        layer: new L.FeatureGroup(decoratorsLayers),
                        id: id
                    };
                    decoratorGroup.layer.addTo(this.map);
                    this.trackDecorators.push(decoratorGroup);
                }
                let track = {
                    layer: new L.FeatureGroup(trackLayers),
                    id: id
                };

                track.layer.addTo(this.map);

                if (popupData) {
                    track.popup = {
                        layer: new L.FeatureGroup([popup]),
                        id: id
                    };
                    track.popup.layer.addTo(this.map);
                }

                this.map.fitBounds(track.layer.getBounds(), {
                    paddingTopLeft: [100, 100],
                    paddingBottomRight: [100, 200],
                    // padding: [50, 50]
                })
                return track
            },
            createTrackControlPoints(controlPoints, id, type) {

                let hideTooltips = (this.getAppUserSettings.hideMarkerTooltipForDetailedTrack && type === 'detailed')
                let controlPointsMarkers = [];
                controlPoints = controlPoints || [];
                controlPoints.forEach(function (el) {
                    let controlPointIcon = L.icon({
                        iconUrl: require('@/assets/img/track/direction-icon.svg'),//?color='+el.color,
                        iconSize: [20, 20],
                        iconAnchor: [10, 10],
                        popupAnchor: [0, -15]
                    });
                    if (hideTooltips) {
                        controlPointIcon = L.divIcon({
                            html: '<div style="background: #' + el.color + ';color: #' + el.color + ';"></div>',
                            className: 'marker-cluster-track-detailed-without-cluster-annotations',
                            iconSize: [20, 20],
                            iconAnchor: [10, 10],
                        })
                    }

                    let speedUnits = (this.speedUnits);
                    let label = el.time + ' ' + el.speed + ' ' + speedUnits + '<br/>' + el.address,
                        sensors = el.sensors.map(s => {
                            return '<tr><td>' + (s.name) + ':</td><td>' + s.value + '</td></tr>'
                        }).join(''),
                        marker = L.Marker.movingMarker([el.latlon], 3000, {
                            icon: controlPointIcon,
                            rotationOrigin: 'center center',
                            rotationAngle: el.course + 90
                        });

                    if (!(hideTooltips)) {
                        marker.bindTooltip(label, {
                            permanent: true,
                            direction: 'right',
                            offset: [0, 0],
                            className: 'track-control-point ' + type,
                            style: "background-color:#" + el.color + ";color:" + this.invertColor('#' + el.color) + ";opacity:0.7;"
                        });
                    }
                    marker.bindPopup(
                        //el.time +'<br/>'+ el.speed + ' ' + speedUnits +'<br/>'+ el.address +'<br/>'+ this.$t('text.Mileage')+': '+el.distance +'<br/>'+ el.sensors
                        '<div class="popup-content__table-content">' +
                        '<table class="popup-content__table">' +
                        '<tr>' +
                        '<td>' + this.$t('text.Time') + ':</td>' +
                        '<td>' + el.time + '</td>' +
                        '</tr>' +
                        '<tr>' +
                        '<td>' + this.$t('text.Speed') + ':</td>' +
                        '<td>' + el.speed + ' ' + speedUnits + '</td>' +
                        '</tr>' +
                        '<tr>' +
                        '<td>' + this.$t('text.Address') + ':</td>' +
                        '<td>' + el.address + '</td>' +
                        '</tr>' +
                        '<tr>' +
                        '<td>' + this.$t('text.Mileage') + ':</td>' +
                        '<td>' + el.distance + '</td>' +
                        '</tr>' +
                        sensors +
                        '</table>' +
                        '</div>'
                    );

                    marker.meta = {
                        timestamp: el.timestamp,
                        time: el.time,
                        speed: el.speed,
                        address: el.address,
                        sensors: el.sensors,
                        course: el.course,
                        color: el.color,
                        type: 'control',
                        distance: el.distance
                    }
                    if (hideTooltips) {
                        marker.on('click', function (e) {
                            e.target.openPopup();
                        })
                    } else {
                        marker.on('mouseover', function (e) {
                            e.target.openPopup();
                        })
                    }
                    controlPointsMarkers.push(marker);
                });
                let controlPointsMarkersObj = {
                    layers: controlPointsMarkers,
                    id: id
                };

                this.trackControlPointsGroup.push(controlPointsMarkersObj);
                this.trackControlPointsLayerCluster.addLayers(controlPointsMarkers);
                // if(!this.map.hasLayer(this.trackControlPointsLayerCluster)){
                //     //this.addTrackCluster(id, hideTooltips);
                // }
            },
            createTrackPoints(points, id, type, hideTooltips = false) {

                let pointsMarkers = [];
                let pointIconOptions = ({
                    iconUrl: require('@/assets/img/track/map__pin-' + type + '.png'),
                    iconSize: [94, 56],
                    iconAnchor: [47, 56],
                    popupAnchor: [0, -47],
                    //labelAnchor: [5, -25],
                });
                let pointIcon = L.icon(pointIconOptions)
                let typeText = '';
                if (type == 'parking') {
                    typeText = this.$t('text.Parking');
                } else
                if (type == 'stop') {
                    typeText = this.$t('text.Stop');
                }
                points.forEach((el) => {
                    if (!el.latlng) {
                        console.warn('createTrackPoints - no latlng', {latlng: null, ...el})
                        return
                    }
                    if (type == 'event') {
                        typeText = '' + el['sub-type']
                        pointIcon = L.icon({
                            ...pointIconOptions,
                            iconUrl: require('@/assets/img/track/map__pin-' + typeText.toLocaleLowerCase().replace(' ', '-') + '.png')
                        })
                    }
                    let label = '' + el.time.formated['time-s'] + ' / ' + (el.message || el.text);
                    let marker = L.Marker.movingMarker([el.latlng], 3000, {icon: pointIcon});

                    if (!hideTooltips) {
                        marker.bindTooltip(label, {
                            permanent: true,
                            direction: 'bottom',
                            className: 'track-point'
                        });
                    } else {
                        marker.bindPopup(label)
                        marker.on('click', (e) => {
                            // console.log(this.getShowMarkerOnTrack)
                            e.target.openPopup()
                            this.setShowMarkerOnTrack({
                                    type: e.target.meta.type,
                                    time: e.target.meta.timestamp,
                            })
                        })
                    }
                    // marker.bindPopup(
                    //     '<div class="popup-content__table-content">' +
                    //     '<table class="popup-content__table">' +
                    //     '<tr>' +
                    //     '<td colspan="2">'+typeText+'</td>' +
                    //     '</tr>' +
                    //     '<tr>' +
                    //     '<td>'+this.$t('text.Time')+':</td>' +
                    //     '<td>'+el.time.formated['time-s']+'</td>' +
                    //     '</tr>' +
                    //     '<tr>' +
                    //     '<td colspan="2">' + el.message + '</td>' +
                    //     '</tr>' +
                    //     '</table>' +
                    //     '</div>',
                    //     {className: 'track-point-popup'}
                    // );
                    marker.meta = {
                        timestamp: el.time.utc,
                        duration: el.duration,
                        time: el.time.formated['time-s'],
                        // timeFrom: el.time_start.utc,
                        // timeTo: el.time_end.utc,
                        type: (type == 'event') ? type + '.' + el['sub-type'] : type
                    }
                    pointsMarkers.push(marker);
                });
                let pointsMarkersObj = {
                    layers: pointsMarkers,
                    type: 'control' + '-' + type,
                    id: id
                }
                this.trackEventsPointsGroup.push(pointsMarkersObj);
                this.trackControlPointsLayerCluster.addLayers(pointsMarkers);
                //return pointsMarkers//pointsMarkersObj
            },
            createMarker(latLng, type, title, sUniqueKey, icon, description) {
                if (!Array.isArray(latLng) && typeof latLng === 'string') latLng = latLng.split(',')
                let iconSize = [32, 32],
                    options = {
                        title: title,
                        minWidth: '470px'
                    },
                    popup = description || '';

                if (type === 'driver') {
                    icon = icon || require('@/assets/img/unit/common__car_lg.png');
                } else
                if (type === 'auto') {
                    icon = icon || require('@/assets/img/unit/common__car_lg.png');
                    // let exclude = [
                    //     '/img/unit/common__car_lg.png',
                    //     '/img/move_icon.png',
                    //     '/img/stay_icon.png',
                    //     '/img/signal_icon2.png',
                    //     '/img/libauto/'
                    // ];
                    // if (icon && exclude.indexOf(icon) < 0 && icon.indexOf('data:image') < 0) {
                    //     icon = '/img/libauto/' + icon;
                    // }
                } else
                if (type == 'geopoint' || type.startsWith('geopoints_group')) {
                    icon = icon || require('@/assets/img/leaflet/marker-icon.png');
                    iconSize = [25, 41];
                } else
                if (type === 'route-point') {
                    icon = icon || require('@/assets/img/leaflet/marker-icon.png');
                    iconSize = [25, 41];
                }

                if ((typeof icon) === 'string') {
                    options.icon = L.icon({
                        iconUrl: icon,
                        iconSize: iconSize
                    });
                } else {
                    options.icon = icon;
                }

                if (type === 'auto') {
                    /*options.icon = L.divIcon({
                        className: 'direction-enabled',
                        iconSize: L.point(50,50),
                        html: '<img class="direction-arrow" src="/img/munit_0.png" data-direction-id="' + sUniqueKey + '"/>' + '<img src="' + icon + '"/>'
                    });*/
                } else
                if (type === 'geopoint' || type.startsWith('geopoints_group')) {
                    icon = icon ? icon : require('@/assets/img/leaflet/marker_geopoint.png');
                    options.icon = L.icon({
                        iconUrl: icon,
                        iconSize: [44, 40],
                        iconAnchor: [22, 40],
                        popupAnchor: [0, -45],
                    });
                } else
                if (type === 'startFinish') {
                    options.icon = L.icon({
                        iconUrl: icon,
                        iconSize: [94, 56],
                        iconAnchor: [47, 56],
                        popupAnchor: [0, -47],
                    });
                }

                if (type == 'route-waypoint') {
                    options.draggable = true;
                }

                if (type == 'point') {
                    options.icon = L.icon({
                        iconUrl: icon,
                        iconSize: [32, 32],
                        iconAnchor: [16, 32],
                        popupAnchor: [0, -32],
                    });
                }
                let marker = L.Marker.movingMarker([latLng], 3000, options);
                // if (type==='auto' && (unitsNotGroup==1)) {
                //     //marker = L.Marker.unitMarker([latLng], 3000, options);
                // } else {
                //     //marker = L.Marker.movingMarker([latLng], 3000, options);
                // }

                if (title > '') {
                    let offset = [0, 0]//[ -(title.length * 3), 25 ];
                    if (type === 'geopoint' || type.startsWith('geopoints_group')) {
                        offset = [0, 0];
                    }
                    let className = ((typeof type) === 'string' && type.length > 0) ? type + '-tooltip' : '';
                    marker.bindTooltip(title, {
                        permanent: true,
                        direction: 'bottom',
                        className: className,
                        offset: offset
                    });
                }
                if ((type != 'auto' && type != 'geopoint' && !type.startsWith('geopoints_group'))) {
                    marker.addTo(this.map);//this.map.addLayer(marker);
                }

                if (type == 'route-waypoint') {
                    //marker.on('dragend', routeWaypointDragEnd);//ToDo enable
                }

                if (popup && type != 'auto') {
                    marker.bindPopup(popup, {className: type + '-popup'});
                    //marker.on('mouseover', function() {
                    //    if(yii_env_dev) console.log('update marker');
                    //});
                }

                marker.meta = {
                    key: sUniqueKey,
                    type: type,
                    course: null,
                    speed: 0
                };

                /*
                if(type=='auto' && map === this.map)
                marker.on('move', function(e){
                    if(this.meta.speed != 0){
                        //$('img[data-direction-id="'+this.meta.key+'"]').css('display', 'block');
                    }else if(this.meta.speed == 0){
                        //$('img[data-direction-id="'+this.meta.key+'"]').css('display', 'none');
                    }
                    //$('img[data-direction-id="'+this.meta.key+'"]').rotate({animateTo: this.meta.course});
                });
                */

                if (type == 'driver') {
                    //ToDo enable
                    // marker.on('click', function(e){
                    //     let infoWin = //$('#info_window_driver');
                    //     let show = populateInfoWindowDriver(infoWin, this.meta.key);
                    //     if(show === false) return false;
                    //     e.target._popup = L.popup({offset:[0, -10]}).setContent(infoWin.html());
                    //     e.target.openPopup();
                    // });
                    // this.map.addLayer(marker);
                } else
                if (type == 'auto') {
                    //ToDo enable
                    // marker.on('click', function(e){
                    //     let infoWin = //$('#info_window');
                    //     populateInfoWindow(infoWin, this.meta.key);
                    //     e.target._popup = L.popup({offset:[0, -10]}).setContent(infoWin.html());
                    //     e.target.openPopup();
                    // });

                    // this.markerCluster.on('animationend', function(e) {
                    //     for (let i = 0; i < this.markers.length; i++) {
                    //         if (this.markers[i].meta.rotate === true) {
                    //             //$('img[data-direction-id="' + this.markers[i].meta.key + '"]').rotate({angle: this.markers[i].meta.course});
                    //         }
                    //     }
                    // });

                    // if (!unitsNotGroup) {
                    //     let clusterMarkers,
                    //         addToCluster = true;
                    //
                    //     clusterMarkers = this.markerCluster.getLayers();
                    //     for (let i = 0; i < clusterMarkers.length; i++) {
                    //         if (clusterMarkers[i].meta.key == marker.meta.key) {
                    //             addToCluster = false;
                    //             break;
                    //         }
                    //     }
                    //
                    //     if (addToCluster) {
                    //         //if(yii_env_dev) console.log('add to cluster', marker.meta.key);
                    //         this.markerCluster.removeLayer(marker);
                    //         this.markerCluster.addLayer(marker);
                    //     }
                    // }else{
                    //     this.map.addLayer(marker);
                    // }
                } else
                if (type == 'geopoint') {
                    //ToDo enable
                    // let clusterMarkers,
                    //     addToCluster = true;
                    // clusterMarkers = this.geopointCluster.getLayers();
                    // for (let i = 0; i < clusterMarkers.length; i++) {
                    //     if (clusterMarkers[i].meta.key == marker.meta.key) {
                    //         console.warn();
                    //         addToCluster = false;
                    //         break;
                    //     }
                    // }
                    //
                    // if (addToCluster) {
                    //     this.geopointCluster.removeLayer(marker);
                    //     this.geopointCluster.addLayer(marker);
                    // }
                }
                //this.markers.push(marker);
                return marker;
            },
            addTrackClusterEvents(id, hideTooltips = false) {

                this.trackControlPointsLayerCluster.on('clusterclick', function (a) {
                    let markers = a.layer.getAllChildMarkers()
                    let speedUnits = (this.speedUnits)

                    markers.sort(function (a, b) {
                        if (a.meta.timestamp > b.meta.timestamp) {
                            return 1;
                        }
                        if (a.meta.timestamp < b.meta.timestamp) {
                            return -1;
                        }
                        return 0;
                    });

                    if (hideTooltips) {
                        let
                            marker = markers.find(function (m) {
                                return m.meta && m.meta.type == 'parking'
                            })
                        if (!marker) {
                            marker = markers.find(function (m) {
                                return m.meta && m.meta.type == 'stop'
                            })
                        }
                        if (!marker) {
                            marker = markers.find(function (m) {
                                return m.meta && m.meta.type == 'control'
                            })
                        }
                        if (!marker) {
                            marker = markers[0]
                        }

                        let content = marker.getPopup().getContent()
                        a.layer.bindPopup(content).openPopup();
                        return false;
                    }

                    let content = '<div class="leaflet-popup-content-container " data-mcs-theme="dark">' +
                        '<table class="track-control-points-popup-content" style="">';

                    markers.forEach(function (el, ind) {
                        if (el.meta.type == 'control') {
                            content = content +
                                '<tr>' +
                                '<td><img style="width:20px;height20px;transform-origin: center center;transform: rotate(' + (el.meta.course + 90) + 'deg)" src="'+ require('@/assets/img/track/direction-icon.svg?color=' + el.meta.color) + '"></td>' +
                                '<td>' + el.meta.time + '</td>' +
                                '<td>' + el.meta.speed + ' ' + speedUnits + '</td>' +
                                '<td>' + this.formatUnitAddress(el.meta.address) + '</td>' +
                                '<td>' + this.$t('text.Mileage') + ': ' + el.meta.distance + '</td>' +
                                '<td>' + '<a class="full-info" onclick="this.showTrackClusterPointPopup(' + id + ',' + el.meta.timestamp + ',' + ind + ');return false;">' + this.$t('text.Full info') + '</a>' + '</td>' +
                                '</tr>';
                        } else
                        if (el.meta.type == 'parking') {
                            content = content +
                                '<tr>' +
                                '<td><img style="max-height20px;" src="' + require('@/assets/img/track/parking.png') +'"></td>' +
                                '<td colspan="4">' + el.meta.time + '</td>' +
                                '<td>' + el.meta.duration.formated + '</td>' +
                                '</tr>';
                        } else
                        if (el.meta.type == 'stop') {
                            content = content +
                                '<tr>' +
                                '<td><img style="max-height20px;" src="' + require('@/assets/img/track/stop.png') + '"></td>' +
                                '<td colspan="4">' + el.meta.time + '</td>' +
                                '<td>' + el.meta.duration.formated + '</td>' +
                                '</tr>';
                        }
                    });
                    content = content + '</table></div>';
                    a.layer.bindPopup(content, {
                        maxWidth: 500,
                        maxHeight: 300,
                        offset: [0, -12],
                        className: 'track-control-point-popup'
                    }).openPopup();
                    //$('.leaflet-popup-content').find('.mCustomScrollbar').mCustomScrollbar();
                });
                this.trackControlPointsLayerCluster.addTo(this.map);
            },
            formatUnitAddress(address) {
                return address;
            },
            invertColor(hexTripletColor) {
                let color = hexTripletColor;
                color = color.substring(1);           // remove #
                color = parseInt(color, 16);          // convert to integer
                color = 0xFFFFFF ^ color;             // invert three bytes
                color = color.toString(16);           // convert to hex
                color = ("000000" + color).slice(-6); // pad with leading zeros
                color = "#" + color;                  // prepend #
                return color;
            },
            DrawPolylineOnMap() {
                return false;
            },
            invalidateMapSize() {
                let bounds = this.getMapBounds
                this.map.invalidateSize()
                if (bounds) this.setMapBounds(bounds)
            },
            getDataFromLayer(layer) {
                let data = null
                if (layer.getRadius) {
                    data = {}
                    data.radius = layer.getRadius();
                    data.area = Math.pow(data.radius, 2) * Math.PI;
                    data.points = [['' + layer.getLatLng().lat, '' + layer.getLatLng().lng, '0']];
                } else
                if (layer.polyline_geozone) {
                    data = {}
                    data.radius = layer.radius_polyline;
                    data.area = L.GeometryUtil.geodesicArea(layer.polyline_geozone);
                    data.points = this.getPointsFromLayer(layer.polyline_geozone);
                    data.pointsPolyLine = this.getPointsFromLayer(layer);
                } else
                {
                    data = {}
                    data.area = L.GeometryUtil.geodesicArea(layer.getLatLngs());
                    data.points = this.getPointsFromLayer(layer);
                }
                return data
            },
            getPointsFromLayer(layer) {
                var latLngs = layer.getLatLngs(),
                    points = [];
                if (latLngs[0]) {
                    if (Array.isArray(latLngs[0])) {
                        latLngs = latLngs[0];
                    }
                }
                latLngs.forEach(function (latLng) {
                    points.push([latLng.lat, latLng.lng]);
                });
                return points;
            },
            showNotificationEvent(event) {
                if (!event) {
                    this.map.removeLayer(this.notificationEventMarker)
                    this.notificationEventMarker = null
                    return;
                }
                //console.log('showNotificationEvent', event)
                if (!this.notificationEventMarker) {
                    this.notificationEventMarker = L.marker(event.latlng)
                    this.notificationEventMarker.bindPopup('')
                    this.map.addLayer(this.notificationEventMarker)
                } else {
                    this.notificationEventMarker.setLatLng(event.latlng)
                }
                let imageName = require('@/assets/img/events/map__pin-' + event.image + '.png')
                let html = '' +
                    // '<div class="notification-event-marker-svg-icon" style="">' +
                    // '<svg class="svg-icon-notification-event-marker" xmlns="http://www.w3.org/2000/svg">' +
                    // '<use xlink:href="/img/icons.svg#notifications__'+event.image+'"/>' +
                    // '</svg>' +
                    // '</div>'+
                    '<div class="notification-event-marker">' +
                    '<img class="" src="' + imageName + '">' +
                    '</div>'
                this.notificationEventMarker
                    .setIcon(L.divIcon({
                        className: 'notification-event-marker',
                        iconSize: [94, 56],
                        iconAnchor: [47, 56],
                        popupAnchor: [0, -47],
                        html: html,
                    }))
                    .setPopupContent(event.message || event.text)
                    .openPopup()
            },
            markerOnClick(event) {
                //console.log('markerOnClick', event)//target.meta.id
                this.displaySectionInfo('UnitInfo', { unitId: event.target.meta.id })
                this.setUnitActive(event.target.meta.id)
                this.setUnitTracking(event.target.meta.id)
            },
            htmlToElement(html) {
                var template = document.createElement('template');
                html = html.trim(); // Never return a text node of whitespace as the result
                template.innerHTML = html;
                return template.content.firstChild;
            },
            htmlToElements(html) {
                let template = document.createElement('template');
                template.innerHTML = html;
                return template.content.childNodes;
            }
        },
        created: function () {
            this.debouncedAfterRouteChange = debounce(this.afterRouteChange, 300)
            this.debouncedInvalidateMapSize = debounce(this.invalidateMapSize, 500)
            this.debouncedUpdateMarkers = debounce(this.updateMarkers, 300)
            this.debouncedUpdateMarkersLatLng = debounce(this.updateMarkersLatLng, 300)
            this.debouncedUpdateMarkersOptions = debounce(this.updateMarkersOptions, 300)
            this.debouncedUpdateGeozones = debounce(this.updateGeozones, 300)
            this.debouncedUpdateUnitTrack = debounce(this.updateUnitTrack, 300)
            this.debouncedUpdateUnitParked = debounce(this.updateUnitParked, 300)
            this.debouncedShowNotificationEvent = debounce(this.showNotificationEvent, 300)
            this.debouncedUpdateSpeedActive = debounce(this.updateUnitSpeedColor, 300)

            // console.log(`SectionMap created`);
        },
        mounted() {
            // console.log(`SectionMap mounted`);
            this.overlay['units'].visible = (this.route_name == 'Units')
        },
    }
</script>

<style scoped>
    .map-controll{
        background: rgba(var(--color-white-rgb), .75);
        border: none;
        padding: 0;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        margin-top: 10px;
        margin-right: 1px;
        box-shadow: 0 0 12px 0 rgba(var(--color-neutral-700-rgb), 0.3);
        transition: .2s;
    }
    .map-controll:hover {
        background: rgba(var(--color-white-rgb), .85);
        transition: .2s;
    }
    .map-controll:active{
        background: rgba(var(--color-white-rgb), 1);
    }
    .map-controll .icon {
        color: #26588d;
    }
    .marker-cluster-track {

    }
</style>