import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {bodyGrid: "1X1", isGuest: true},
    component: () => import('./views/LoginPage.vue')
  },
  {
    path: '/requestPasswordReset',
    name: 'RequestPasswordReset',
    meta: {bodyGrid: "1X1", isGuest: true},
    component: () => import('./views/RequestPasswordResetPage.vue')
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    meta: {bodyGrid: "1X1", isGuest: true},
    component: () => import('./views/ResetPasswordPage.vue')
  },
  // {
  //   path: '/signup',
  //   name: 'Signup',
  //   meta: {bodyGrid: "1X1", isGuest: true},
  //   component: () => import('./views/SignupPage.vue')
  // },
  // {
  //   path: '/verifyEmail',
  //   name: 'VerifyEmail',
  //   meta: {bodyGrid: "1X1", isGuest: true},
  //   component: () => import('./views/VerifyEmailPage.vue')
  // },
  // {
  //   path: '/resendVerificationEmail',
  //   name: 'ResendVerificationEmail',
  //   meta: {bodyGrid: "1X1", isGuest: true},
  //   component: () => import('./views/ResendVerificationEmailPage.vue')
  // },

  // {
  //   path: '/test',
  //   name: 'TestPage',
  //   meta: { menu_icon: "help", bodyGrid: "1X1", },
  //   component: () => import('./views/test/TestPage.vue')
  // },

  {
    path: '/dealer',
    name: 'Dealer',
    meta: { menu_icon: "dealer", bodyGrid: "1X1", },
    component: () => import('./views/portal/DealerPage.vue')
  },
  {
    path: '/help',
    name: 'Help',
    meta: { menu_icon: "help", bodyGrid: "1X1", },
    component: () => import('./views/portal/HelpPage.vue')
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {bodyGrid: "1X1"},
    component: () => import('./views/portal/DashboardPage.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: {list: 'ReportsList', menu_icon: "reports", bodyGrid: "2X2(1__1+M)", },
    component: () => import('./views/portal/ReportsPage.vue')
  },
  {
    path: '/notifications',
    name: 'Notifications',
    meta: {list: 'NotificationsList', menu_icon: "notifications", },
    component: () => import('./views/portal/NotificationsPage.vue')
  },
  {
    path: '/routes',
    name: 'Routes',
    meta: {list: 'RoutesList', menu_icon: "routes", },
    component: () => import('./views/portal/RoutesPage.vue')
  },
  {
    path: '/personnel',
    name: 'Personnel',
    meta: {list: 'PersonnelList', menu_icon: "personnel", },
    component: () => import('./views/portal/PersonnelPage.vue')
  },
  {
    path: '/passengers',
    name: 'Passengers',
    meta: {list: 'PassengersList', menu_icon: "passengers", },
    component: () => import('./views/portal/PassengersPage.vue')
  },
  {
    path: '/fuel',
    name: 'Fuel',
    meta: {list: 'FuelList', menu_icon: "fuel", },
    component: () => import('./views/portal/FuelPage.vue')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    meta: {list: 'MaintenanceList', menu_icon: "maintenance", },
    component: () => import('./views/portal/MaintenancePage.vue')
  },
  {
    path: '/alarms',
    name: 'Alarms',
    meta: {list: 'AlarmsList', menu_icon: "alarm", },
    component: () => import('./views/portal/AlarmsPage.vue')
  },
  {
    path: '/video',
    name: 'Video',
    meta: {list: 'VideoList', menu_icon: "video", },
    component: () => import('./views/portal/VideoPage.vue')
  },
  {
    path: '/fuel_cards',
    name: 'FuelCards',
    meta: {list: 'FuelCardsList', menu_icon: "fuel_cards", },
    component: () => import('./views/portal/FuelCardsPage.vue')
  },
  {
    path: '/dashboard-gauges',
    name: 'DashboardGauges',
    meta: {list: 'UnitsList', menu_icon: "gauges", },
    component: () => import('./views/portal/DashboardGaugesPage.vue')
  },

  {
    path: '/tracks',
    name: 'Tracks',
    meta: {
      list: (process.env.VUE_APP_PACKAGE === "lite")? 'TracksList_lite' : 'TracksList',
      menu_icon: "tracks", showTrackInfo: true,
    },
    component: () => import('./views/portal/TracksPage.vue')
  },
  {
    path: '/geoitems',
    name: 'Geoitems',
    meta: {
      list: (process.env.VUE_APP_PACKAGE === "lite")? 'GeoitemsList_lite' : 'GeoitemsList',
      menu_icon: "geoitems",
    },
    component: () => import('./views/portal/GeoitemsPage.vue')
  },
  {
    path: '/notificationsevents',
    name: 'NotificationsEvents',
    meta: {
      list: 'NotificationsEventsList',
      menu_icon: "notifications", showNotifEventsInfo: true,
    },
    component: () => import('./views/portal/NotificationsEventsPage.vue')
  },
  {
    path: '*',
    name: 'Units',
    meta: {
      list: (process.env.VUE_APP_PACKAGE === "lite")? 'UnitsList_lite':'UnitsList',
      menu_icon: "car", bodyGrid: "2x2(M__1_v)", showMapInfo: true,
    },
    component: () => import('./views/portal/UnitsPage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// function isAuthenticated(){
//   let token = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.authtoken')
//   return (token && token>'')
// }
// router.beforeEach((to, from, next) => {
//   console.log(to, from)
//   if (to.name !== 'Login' && !isAuthenticated()) {
//     next({ name: 'Login' })
//   } else {
//     next()
//   }
// })

export default router
