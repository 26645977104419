<template>
    <div class="info__connect connect"
         :class="{'connect_status_no-connection': no_connection}"
    >
        <span class="icon icon_size_32 icon_no-connection">
            <span class="icon__symbol"><SvgIcon class="icon" name="common__server-no-connection"/></span>
        </span>
        <span class="icon icon_size_32 icon_connection">
            <span class="icon__symbol"><SvgIcon class="icon" name="common__server-connection"/></span>
        </span>
    </div>
</template>
<script>
export default {
    name: 'SectionConnection',
    props: [],
    data() {
        return {
            no_connection: false,
        }
    },
    components: {},
    computed: {},
}
</script>
