import {objectComparison} from "@/lib/lib";

const geopointsExpand = ['access_right']//'points',
//const geopointsLiteFields = ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'];

export default {
    state: {
        geopointsGroups: [],
        geopointsGroups_index: [],
        geopointsObj: {},
        geopoints: [],
        geopoint_active: null,
        geopoints_index: [],
        geopoints_filter: {
            type: false,
            name: '',
        },
    },
    actions: {
        async setGeopointActive({ commit } , id){
            commit('setGeopointActive' , id)
        },
        async fetchGeopointsGroups({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {}
            this.$api.geopointsgroups.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeopointsGroups', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchGeopoints({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: geopointsExpand.join()}
            this.$api.geopoints.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeopoints', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchGeopointsLite({ commit, getters, dispatch }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: geopointsExpand.join()}//, fields: geopointsLiteFields.join()}
            this.$api.geopoints.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeopoints', response.data)
                        dispatch('fetchGeopointsPoints')
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchGeopointsPoints({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {fields: 'id', expand: 'points'}
            this.$api.geopoints.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeopoints', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async saveGeopoint({ dispatch }, geopoint) {
            let fn = (geopoint.id) ? 'updateGeopoint' : 'createGeopoint'
            return dispatch(fn, geopoint);
        },
        async createGeopoint({ commit, dispatch }, geopoint) {
            return new Promise((resolve, reject) => {
                const params = {expand: geopointsExpand.join()}
                this.$api.geopoints.create(geopoint, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeopoint', {...response.data, points: geopoint.points})
                            dispatch('fetchGeopoints', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateGeopoint({ commit, dispatch }, geopoint) {
            return new Promise((resolve, reject) => {
                const params = {expand: geopointsExpand.join()}
                this.$api.geopoints.update(geopoint.id, geopoint, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeopoint', {...response.data, points: geopoint.points})
                            dispatch('fetchGeopoints', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteGeopoint({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.geopoints.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteGeopoint', id)
                            dispatch('fetchGeopoints', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setGeopointActive(state , id){
            state.geopoint_active = id
        },
        updateGeopointProp(state, arg) {
            let geopoint = state.geopoints.find(g => {return g.id == arg.geopointId})
            if(!geopoint){
                console.error('no geopoint', arg.geopointId)
            }else
            if(arg.prop) {
                geopoint[arg.prop.name] = arg.prop.value
            }
        },
        updateGeopointsGroups(state, nGroups) {
            state.geopointsGroups = nGroups
            // if(!state.geopointsGroups.length) {
            //     state.geopointsGroups = nGroups
            // } else {
            //     let nId = nGroups.map(ng=> ng.id)
            //     let groups = state.geopointsGroups.filter( g => nId.includes(g.id) )
            //     if(groups.length !== state.geopointsGroups.length){
            //         state.geopointsGroups = groups
            //     }
            //     nGroups.forEach(function (nGroup) {
            //         let i = state.geopointsGroups.findIndex(g => {return g.id == nGroup.id})
            //         if(i<0) {
            //             state.geopointsGroups.push(nGroup)
            //         }else{
            //             let oGroup = state.geopointsGroups[i]
            //             let cmp = objectComparison(nGroup, oGroup, ['changed__time',...geopointsExpand])
            //             if(!cmp){
            //                 let group = Object.assign({},state.geopointsGroups[i],nGroup)
            //                 state.geopointsGroups.splice(i, 1, group)
            //             }
            //         }
            //     })
            // }
            state.geopointsGroups_index = nGroups.map(g => g.id)
        },
        updateGeopoints(state, nGeopoints) {
            if(!state.geopoints.length) {
                state.geopoints = nGeopoints
            }else{
                let nId = nGeopoints.map(ng=> ng.id)
                let geopoints = state.geopoints.filter( g => nId.includes(g.id) )
                if(geopoints.length !== state.geopoints.length){
                    state.geopoints = geopoints
                }
                nGeopoints.forEach(function (nGeopoint) {
                    let i = state.geopoints.findIndex(g => {return g.id == nGeopoint.id})
                    if(i<0) {
                        state.geopoints.push(nGeopoint)
                    }else{
                        let oGeopoint = state.geopoints[i]
                        let cmp = objectComparison(nGeopoint, oGeopoint, ['changed__time',...geopointsExpand])
                        if(!cmp){
                            let geopoint = Object.assign({},state.geopoints[i],nGeopoint)
                            state.geopoints.splice(i, 1, geopoint)
                        }
                    }
                })
            }
            state.geopoints_index = state.geopoints.map(g => g.id)
        },
        updateGeopoint(state, nGeopoint) {
            let i = state.geopoints.findIndex(g => {return g.id == nGeopoint.id})
            if(i<0) {
                state.geopoints.push(nGeopoint)
            }else{
                let oGeopoint = state.geopoints[i]
                let cmp = objectComparison(nGeopoint, oGeopoint, ['changed__time',...geopointsExpand])
                if(!cmp){
                    let geopoint = Object.assign({},state.geopoints[i],nGeopoint)
                    state.geopoints.splice(i, 1, geopoint)
                }
            }
        },
        deleteGeopoint(state, id) {
            state.geopoints = state.geopoints.filter(g => {return g.id != id})
        },

        setGeopointsFilter(state, filter) {
            state.geopoints_filter = filter
        },
    },
    getters: {
        getGeopointActive(state){
            return state.geopoint_active
        },
        geopointsGroups(state){
            return state.geopointsGroups.filter(g => {
                return g.geopoints && g.geopoints.length
            })
        },
        geopointsGroupsByIds(state){
            return state.geopointsGroups.reduce((out, g) => {
                out[g.id] = g
                return out
            }, {})
        },
        geopoints(state){
            return state.geopoints
        },
        geopointsByIds(state){
            // return state.geopointsObj
            return state.geopoints.reduce((out, g) => {
                out[g.id] = g
                return out
            }, {})
        },
        geopointsFilter(state){
            return state.geopoints_filter
        },

        filteredGeopoints(state) {
            let filter = state.geopoints_filter
            let name = filter.name.toLocaleLowerCase()

            let geopoints = state.geopoints
            if(filter.type > '') {
                geopoints = geopoints.filter(g => {
                    return g.type === filter.type
                })
            }
            if(filter.name > '') {
                geopoints = geopoints.filter(g => {
                    return g.name.toLocaleLowerCase().includes(name)
                })
            }

            return geopoints.map(g => g.id)
        },
        geopointsCount(state) {
            return state.geopoints.length
        },
        geopointsGroupsFiltered(state, getters){
            return getters.geopointsGroups.reduce((out, g) => {
                let geopoints = g.geopoints.filter(id => {
                    return getters.filteredGeopoints.includes(id)
                })
                out.push({
                    ...g,
                    geopoints: geopoints,
                })
                return out
            }, [])
        },
    }
}
