export default axios => ({
    login(data) {
        return axios.post("/login", data);
    },
    loginAs(data) {
        return axios.post("/login-as", data);
    },
    check() {
        return axios.get("/");
    },
    refreshTokens() {
        return axios.get("/refresh-token");
    },
    logout(data) {
        return axios.post("/logout", data);
    }
});