<template>
    <!--    <section class="section section_type_content is_new-design">-->
    <!--    <router-view />-->
    <!--    </section>-->
    <section class="section section_type_content is_new-design" style="position: relative">
        <!--
        <section class="section__head head is_new-design status_hidden-" v-if="!isGuest" v-show="showHead">
            <h1 class="head__title ptb__8 mr__32">
                <span class="head__main-title">Main title</span>&nbsp;
                <span class="head__sub-title"> /&nbsp;Sub-title</span>
            </h1>
        </section>
        -->
        <div class="aside-menu_mobile-control">
            <button class="button button_view_icon button_view_icon-sm button_theme_primary mtb__-4" @click="setMobileMenuOpen(!getMobileMenuOpen)">
                <span class="icon">
                    <span class="icon__symbol">
                        <SvgIcon class="icon" :name="iconName" />
                    </span>
                </span>
                <!--      common__close   menu__hamburger       -->
            </button>
        </div>

        <section class="section__body body" :class="{'hide-map': !mapControl}" :data-grid="bodyGrid">
            <SectionMap v-if="!isGuest" v-show="route_name != 'Units' || (mapControl && route_name == 'Units')" :showWeather="isShowWeather"/>
            <router-view :show-map.sync="mapControl"  :showWeather.sync="showWeather"/> <!--  :show-head.sync="headControl" -->
        </section>
    </section>

</template>

<script>
import {mapGetters, mapMutations} from "vuex";
    import SectionMap from "@/components/app/SectionMap.vue";

    export default {
        name: "SectionContent",
        props: [],
        data() {
            return {
                showWeather: false,
                mapControl: true,
                headControl: false,
            }
        },
        components: {
            SectionMap
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isGuest",
                "hasUnitActiveVideo",
                "getMobileMenuOpen"
            ]),
            showHead() {
                return !this.isGuest && this.$route.meta.showHead
            },
            bodyGrid() {
                return this.$route.meta.bodyGrid || "1X1(M)"
                // "1X1" "1X2" "1X3" "1X1+1" "2X2" "2X1" "1+1X1"
            },
            route_name(){
                return this.$route.name
            },
            isShowWeather(){
                return (this.route_name == 'Units' && this.hasUnitActiveVideo) || (this.showWeather && this.route_name == 'Units')
            },
            iconName(){
                return this.getMobileMenuOpen ? 'common__chevron_right_double' :  'common__chevron_left_double'
            }
        },
        methods:{
            ...mapMutations([
                'setMobileMenuOpen'
            ]),

        }
    }
</script>

