import {objectComparison} from "@/lib/lib";

const geozonesExpand = ['access_right']//'points',
//const geozonesLiteFields = ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'];

export default {
    state: {
        geozonesGroups: [],
        geozonesGroups_index: [],
        geozonesObj: {},
        geozones: [],
        geozone_active: null,
        geozones_index: [],
        geozones_filter: {
            type: false,
            name: '',
        },
    },
    actions: {
        async setGeozoneActive({ commit } , id){
            commit('setGeozoneActive' , id)
        },
        async fetchGeozonesGroups({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {}
            this.$api.geozonesgroups.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeozonesGroups', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchGeozones({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: geozonesExpand.join()}
            this.$api.geozones.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeozones', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async fetchGeozonesLite({ commit, getters, dispatch }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: geozonesExpand.join()}//, fields: geozonesLiteFields.join()}
            this.$api.geozones.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeozones', response.data)
                        dispatch('fetchGeozonesPoints')
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchGeozonesPoints({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {fields: 'id', expand: 'points'}
            this.$api.geozones.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateGeozones', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async saveGeozone({ dispatch }, geozone) {
            let fn = (geozone.id) ? 'updateGeozone' : 'createGeozone'
            return dispatch(fn, geozone);
        },
        async createGeozone({ commit, dispatch }, geozone) {
            return new Promise((resolve, reject) => {
                const params = {expand: geozonesExpand.join()}
                this.$api.geozones.create(geozone, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeozone', {...response.data, points: geozone.points})
                            dispatch('fetchGeozones', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateGeozone({ commit, dispatch }, geozone) {
            return new Promise((resolve, reject) => {
                const params = {expand: geozonesExpand.join()}
                this.$api.geozones.update(geozone.id, geozone, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateGeozone', {...response.data, points: geozone.points})
                            dispatch('fetchGeozones', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteGeozone({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.geozones.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteGeozone', id)
                            dispatch('fetchGeozones', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        setGeozoneActive(state , id){
            state.geozone_active = id
        },
        updateGeozoneProp(state, arg) {
            let geozone = state.geozones.find(g => {return g.id == arg.geozoneId})
            if(!geozone){
                console.error('no geozone', arg.geozoneId)
            }else
            if(arg.prop) {
                geozone[arg.prop.name] = arg.prop.value
            }
        },
        updateGeozonesGroups(state, nGroups) {
            state.geozonesGroups = nGroups
            // if(!state.geozonesGroups.length) {
            //     state.geozonesGroups = nGroups
            // } else {
            //     let nId = nGroups.map(ng=> ng.id)
            //     let groups = state.geozonesGroups.filter( g => nId.includes(g.id) )
            //     if(groups.length !== state.geozonesGroups.length){
            //         state.geozonesGroups = groups
            //     }
            //     nGroups.forEach(function (nGroup) {
            //         let i = state.geozonesGroups.findIndex(g => {return g.id == nGroup.id})
            //         if(i<0) {
            //             state.geozonesGroups.push(nGroup)
            //         }else{
            //             let oGroup = state.geozonesGroups[i]
            //             let cmp = objectComparison(nGroup, oGroup, ['changed__time',...geozonesExpand])
            //             if(!cmp){
            //                 let group = Object.assign({},state.geozonesGroups[i],nGroup)
            //                 state.geozonesGroups.splice(i, 1, group)
            //             }
            //         }
            //     })
            // }
            state.geozonesGroups_index = nGroups.map(g => g.id)
        },
        updateGeozones(state, nGeozones) {
            if(!state.geozones.length) {
                state.geozones = nGeozones
            }else{
                let nId = nGeozones.map(ng=> ng.id)
                let geozones = state.geozones.filter( g => nId.includes(g.id) )
                if(geozones.length !== state.geozones.length){
                    state.geozones = geozones
                }
                nGeozones.forEach(function (nGeozone) {
                    let i = state.geozones.findIndex(g => {return g.id == nGeozone.id})
                    if(i<0) {
                        state.geozones.push(nGeozone)
                    }else{
                        let oGeozone = state.geozones[i]
                        let cmp = objectComparison(nGeozone, oGeozone, ['changed__time',...geozonesExpand])
                        if(!cmp){
                            let geozone = Object.assign({},state.geozones[i],nGeozone)
                            state.geozones.splice(i, 1, geozone)
                        }
                    }
                })
            }
            state.geozones_index = state.geozones.map(g => g.id)
        },
        updateGeozone(state, nGeozone) {
            let i = state.geozones.findIndex(g => {return g.id == nGeozone.id})
            if(i<0) {
                state.geozones.push(nGeozone)
            }else{
                let oGeozone = state.geozones[i]
                let cmp = objectComparison(nGeozone, oGeozone, ['changed__time',...geozonesExpand])
                if(!cmp){
                    let geozone = Object.assign({},state.geozones[i],nGeozone)
                    state.geozones.splice(i, 1, geozone)
                }
            }
        },
        deleteGeozone(state, id) {
            state.geozones = state.geozones.filter(g => {return g.id != id})
        },

        setGeozonesFilter(state, filter) {
            state.geozones_filter = filter
        },
    },
    getters: {
        getGeozoneActive(state){
            return state.geozone_active
        },
        geozonesGroups(state){
            return state.geozonesGroups.filter(g => {
                return g.geozones && g.geozones.length
            })
        },
        geozonesGroupsByIds(state){
            return state.geozonesGroups.reduce((out, g) => {
                out[g.id] = g
                return out
            }, {})
        },
        geozones(state){
            return state.geozones
        },
        geozonesByIds(state){
            // return state.geozonesObj
            return state.geozones.reduce((out, g) => {
                out[g.id] = g
                return out
            }, {})
        },
        geozonesFilter(state){
            return state.geozones_filter
        },

        filteredGeozones(state) {
            let filter = state.geozones_filter
            let name = filter.name.toLocaleLowerCase()

            let geozones = state.geozones
            if(filter.type > '') {
                geozones = geozones.filter(g => {
                    return g.type === filter.type
                })
            }
            if(filter.name > '') {
                geozones = geozones.filter(g => {
                    return g.name.toLocaleLowerCase().includes(name)
                })
            }

            return geozones.map(g => g.id)
        },
        geozonesCount(state) {
            return state.geozones.length
        },
        geozonesGroupsFiltered(state, getters){
            return getters.geozonesGroups.reduce((out, g) => {
                let geozones = g.geozones.filter(id => {
                    return getters.filteredGeozones.includes(id)
                })
                out.push({
                    ...g,
                    geozones: geozones,
                })
                return out
            }, [])
        },
        geozonesLOptions(state){
            return state.geozones.reduce((out, geozone) => {
                if (geozone.points && geozone.points.length)
                {
                    let color = (geozone.color ? geozone.color : '#00ff00')
                    let options = {
                        geozoneId: geozone.id,
                        type: geozone.type,
                        name: geozone.name,
                        description: geozone.descr,
                        maxSpeed: geozone.maxSpeed,
                        points: geozone.points,
                        radius: geozone.radius,
                        area: geozone.surface_area,
                        color: color,
                        fillColor: color,
                        fillOpacity: 0.5
                    }
                    if(geozone.type === 'corridor'){
                        options.layerType='polyline'
                        options.polyline_radius=geozone.radius;
                        options.corridor= geozone.radius/2;
                    }
                    out.push(options)
                }
                return out
            }, [])
        },
    }
}
