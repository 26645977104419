import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const locale = 'en' // default locale
import en from "@/lang/en.json";
import es from "@/lang/es.json";
import pt from "@/lang/pt.json";

const messages = {
    es,
    pt,
    en
}

export default new VueI18n({
    locale,
    fallbackLocale: 'en',
    formatFallbackMessages: true,
    messages
})