// import {objectComparison} from "@/lib/lib";

const notificationsExpand = ['units','units-groups','geozones','geopoints']

export default {
    state: {
        notificationsGroups: [],
        notificationsGroups_: [],//without notifications
        notificationsGroups_index: [],
        
        notifications: [],
        notifications_index: [],
        notifications_filter: {
            type: false,
            name: '',
        },
    },
    actions: {
        async fetchNotificationsGroups({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            this.$api.notifications.getAllGroups()
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateNotificationsGroups', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        
        async fetchNotifications({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            this.$api.notifications.getAll()
            .then((response) => {
                if(response.status < 400 && !response.data.error) {
                    commit('updateNotifications', response.data)
                }
            })
            .catch((error) => {
                console.error(error);
            });
        },
        async fetchNotificationsWith({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }

            let params = {expand: notificationsExpand.join()}
            this.$api.notifications.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('updateNotifications', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async saveNotificationGeozone(context, args) {
            let action = (args.enabled === true) ? 'add' : 'remove'
            return new Promise((resolve, reject) => {
                this.$api.notifications.patchGeozones(args.notifId, action, {id_geozone: args.id_geozone})
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            //???
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

        //sayHello() {}
    },
    mutations: {
        updateNotificationsGroups(state, groups) {
            let collator = new Intl.Collator();
            //ToDo remove
            groups.sort(function (a, b) {
                let cmp = collator.compare(a.name, b.name)
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })

            state.notificationsGroups = groups
            state.notificationsGroups_ = groups
            state.notificationsGroups_index = groups.map(g => g.id)
        },
        updateNotifications(state, notifications) {
            let collator = new Intl.Collator();
            //ToDo remove
            notifications.sort(function (a, b) {
                let cmp = collator.compare(a.name, b.name)
                if (cmp) {
                    return cmp;
                }
                return a.id - b.id;
            })
            state.notifications = notifications
            state.notifications_index = notifications.map(n => n.id)

            let ns = notifications.reduce((out, n) => {
                let type = n["type0"]
                if(type>'') {
                    if (!out[type]) out[type] = []
                    out[type].push(n.id)
                }
                return out
            }, {})

            state.notificationsGroups = state.notificationsGroups_.map( g => {
                g.notifications = ns[g.id] || []
                return g
            })
        },

        setNotificationsFilter(state, filter) {
            state.notifications_filter = filter
        },
    },
    getters: {
        notificationsGroups(state){
            return state.notificationsGroups.filter(g => {
                return g.notifications && g.notifications.length
            })
        },
        notificationsGroupsByIds(state){
            return state.notificationsGroups.reduce((out, g) => {
                out[g.id] = g
                return out
            }, {})
        },
        notifications(state){
            return state.notifications
        },
        notificationsByIds(state){
            return state.notifications.reduce((out, notification) => {
                out[notification.id] = notification
                return out
            }, {})
        },
        notificationsFilter(state){
            return state.notifications_filter
        },
        notificationsGroupsFiltered(state, getters){
            return getters.notificationsGroups.reduce((out, g) => {
                let notifications = g.notifications.filter(id => {
                    return getters.filteredNotifications.includes(id)
                })
                out.push({
                    ...g,
                    notifications: notifications,
                })
                return out
            }, [])
        },
        filteredNotifications(state) {
            let filter = state.notifications_filter
            let name = filter.name.toLocaleLowerCase()

            let notifications = state.notifications
            if(filter.type > '') {
                notifications = notifications.filter(n => {
                    return n.type === filter.type
                })
            }
            if(filter.name > '') {
                notifications = notifications.filter(n => {
                    return n.name.toLocaleLowerCase().includes(name)
                })
            }

            return notifications.map(n => n.id)
        },
        notificationsCount(state, getters) {
            return getters.filteredNotifications.length
        },
    }
}
