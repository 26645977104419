// import Vue from 'vue'
import {objectComparison} from "@/lib/lib";

const unitsHwTypesExpand = [];
//const unitsHwTypesFields = ['id',]

export default {
    state: {
        unitsHwTypes: [],
        unitsHwTypes_index:[]
    },
    actions: {
        async fetchUnitsHwTypes({ commit, getters }) {
            if(!getters.apiToken) {
                return
            }
            const params = {expand: unitsHwTypesExpand.join()}//, fields: unitsHwTypesFields.join()}
            this.$api.unitshwtypes.getAll(params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error){
                        commit('updateUnitsHwTypes', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async saveUnitsHwType({ dispatch }, unitsHwType) {
            let fn = (unitsHwType.id) ? 'updateUnitsHwType' : 'createUnitsHwType'
            return dispatch(fn, unitsHwType);
        },
        async createUnitsHwType({ commit, dispatch }, unitsHwType) {
            return new Promise((resolve, reject) => {
                const params = {expand: unitsHwTypesExpand.join()}//, fields: unitsHwTypesFields.join()}
                this.$api.unitshwtypes.create(unitsHwType, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsHwType', response.data)
                            dispatch('fetchUnitsHwTypes', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async updateUnitsHwType({ commit, dispatch }, unitsHwType) {
            return new Promise((resolve, reject) => {
                const params = {expand: unitsHwTypesExpand.join()}//, fields: unitsHwTypesFields.join()}
                this.$api.unitshwtypes.update(unitsHwType.id, unitsHwType, params)
                    .then((response) => {
                        if(response.status < 400 && !response.data.error) {
                            commit('updateUnitsHwType', response.data)
                            dispatch('fetchUnitsHwTypes', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        async deleteUnitsHwType({ commit, dispatch }, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitshwtypes.delete(id)
                    .then((response) => {
                        if(response.status < 400 && (!response.data || !response.data.error)) {
                            commit('deleteUnitsHwType', id)
                            dispatch('fetchUnitsHwTypes', {})
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations:{
        updateUnitsHwTypes(state, nUnitsHwTypes) {
            if(!state.unitsHwTypes.length) {
                state.unitsHwTypes = nUnitsHwTypes
            }else{
                let nId = nUnitsHwTypes.map(np=> np.id)
                let unitsHwTypes = state.unitsHwTypes.filter( p => nId.includes(p.id) )
                if(unitsHwTypes.length !== state.unitsHwTypes.length){
                    state.unitsHwTypes = unitsHwTypes
                }
                nUnitsHwTypes.forEach(function (nUnitsHwType) {
                    let i = state.unitsHwTypes.findIndex(p => {return p.id == nUnitsHwType.id})
                    if(i<0) {
                        state.unitsHwTypes.push(nUnitsHwType)
                    }else{
                        let oUnitsHwType = state.unitsHwTypes[i]
                        let cmp = objectComparison(nUnitsHwType, oUnitsHwType, ['changed__time',...unitsHwTypesExpand])
                        if(!cmp){
                            let unitsHwType = Object.assign({},state.unitsHwTypes[i],nUnitsHwType)
                            state.unitsHwTypes.splice(i, 1, unitsHwType)
                        }
                    }
                })
            }
            // state.unitsHwTypes_index = state.unitsHwTypes.map(p => p.id)
            // state.units_sorted = state.unitsHwTypes.map(p => p.id)
        },
        updateUnitsHwType(state, nUnitsHwType) {
            let i = state.unitsHwTypes.findIndex(u => {return u.id == nUnitsHwType.id})
            if(i<0) {
                state.unitsHwTypes.push(nUnitsHwType)
            }else{
                let oUnitsHwType = state.unitsHwTypes[i]
                let cmp = objectComparison(nUnitsHwType, oUnitsHwType, ['changed__time',...unitsHwTypesExpand])
                if(!cmp){
                    let unitsHwType = Object.assign({},state.unitsHwTypes[i],nUnitsHwType)
                    state.unitsHwTypes.splice(i, 1, unitsHwType)
                }
            }
        },
        deleteUnit(state, id) {
            state.unitsHwTypes = state.unitsHwTypes.filter(p => {return p.id != id})
        },
    },
    getters:{
        getUnitsHwTypes(state){
            return state.unitsHwTypes
        }

    }
}