export default {

    state: {
        stops: [],
        stops_filter: {
            unitId: '',
            date: '',
        }
    },
    actions: {
        async fetchStops4Date({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.date) {
                return
            }
            let stops = getters.getStops.filter(s => {
                return s.unit_id == args.unitId && s.time.date == args.date
            })
            if(stops.length){
                return
            }
            this.$api.stops.get4Date(args.unitId, args.date, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeStops', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async fetchStops4Period({ commit, getters }, args) {
            if(!getters.apiToken || !args.unitId || !args.from || !args.to) {
                return
            }
            let stops = getters.getStops.filter(s => {
                return s.unit_id == args.unitId && s.time.utc >= args.from && s.time.utc <= args.to
            })
            if(stops.length){
                return
            }
            this.$api.stops.get4Period(args.unitId, args.from, args.to, args.params)
                .then((response) => {
                    if(response.status < 400 && !response.data.error) {
                        commit('storeStops', response.data)
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        //sayHello() {}
    },
    mutations: {
        storeStops(state, stops) {
            // state.stops = stops
            stops = stops.filter(s => {
                let stop = state.stops.find(st => {
                    return st.unit_id == s.unit_id
                        && st.time_start.utc == s.time_start.utc
                        && st.time_end.utc == s.time_end.utc
                })
                return !stop
            })
            if(stops.length) state.stops.push(...stops)
        },
        clearStops(state){
            state.stops = [];
            //state.index = [];
        },
        setStopsFilter(state, filter) {
            state.stops_filter = filter
        },
    },
    getters: {
        getStopsFilter(state){
            return state.stops_filter
        },
        getStops(state){
            return state.stops
        },
        getStopsByIds(state){
            return state.stops.reduce((out, stop) => {
                out[stop.id] = stop
                return out
            }, {})
        },
        getStopsFiltered(state){
            let filter = state.stops_filter

            let stops = state.stops
            if(filter.date > '') {
                stops = stops.filter(s => {
                    return s.time.date == filter.date
                })
            }
            if(filter.unitId > '') {
                stops = stops.filter(s => {
                    return s.unit_id == filter.unitId
                })
            }
            return stops
        },
    }
}
