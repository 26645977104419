<template>
    <div>
        <template v-for="item in items"><!-- ToDo fix: use list -->
            <CheckboxControl
                :key="item.id"
                :label="item.name"
                :control-class="controlClass"
                :disabled="item.disabled"
                :value="valueNegative ? item.value : !item.value"
                @input="(vl) => setValue(item, vl)"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: "CheckboxControlsForArray",
    props:{
        'controlClass': {
            type: [String, Array, Object],
            default: () => 'mb__12'
        },
        'items': {
            type: [Array, Object],
            default: () => []
        },
        'field_id': {
            type: [String],
            default: () => 'id'
        },
        'field_name': {
            type: [String],
            default: () => 'name'
        },
        'sortBy': {
            type: [String, Function],
            default: () => 'name'
        },
        'disableSortBy': {
            type: Boolean,
            default: () => true
        },
        'value': [Array],
        'valueNegative': {
            type: [Boolean],
            default: () => false
        },
    },
    data() {
        return {}
    },
    computed:{
        list() {
            let field_id = this.field_id
            let field_name = this.field_name
            let field_sortBy = (typeof this.sortBy === 'string') ? this.sortBy : ''
            let addFieldSortBy = (!this.disableSortBy && (typeof this.sortBy === 'string'))
            let list = []
            if (Array.isArray(this.items)) {
                list = this.items.map(item => {
                    let listItem = {...item, id: item[field_id], name: item[field_name]}
                    if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                    return listItem
                })
            } else {
                //console.warn('items isn\'t array', (typeof this.items))
                const objectArray = Object.entries(this.items);
                objectArray.forEach(([id, item]) => {
                    let name = item;
                    if (typeof item == 'object' && item[field_name] !== undefined) {//.hasOwnProperty
                        name = item[field_name]
                    }
                    if (typeof name == 'string') {
                        let listItem = {...item, id, name}
                        if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                        list.push(listItem)
                    } else {
                        console.warn('item isn\'t string', id, item)
                    }
                });
            }
            let sortBy = (typeof this.sortBy !== 'string') ? this.sortBy : 'sortBy'
            if (!this.disableSortBy) {
                if (typeof sortBy === 'string') {
                    let field = '' + sortBy
                    sortBy = (a, b) => {
                        return (a[field] == b[field]) ? 0 :
                            (a[field] > b[field]) ? 1 : -1;
                    }
                }
                if (typeof sortBy === 'function') {
                    list.sort(sortBy);
                } else {
                    console.warn('unknown method sortBy: ', typeof sortBy, sortBy)
                }
            }
            return list
        },
        selected:{
            get(){return this.value},
            set(val){
                this.$emit('input', val)
            }
        },
        filterValue(){
            return this.items.filter(i => i.value && !i.disabled).map(i => i.id)
        },
    },
    methods:{
        setValue(item, vl){
            if(item.desabled) return
            let items = [...this.selected]
            let inArray = !!items.includes(item.id)
            //if(!this.valueNegative) vl = !vl
            if(this.valueNegative){
                item.value = vl
                if(!vl && inArray){
                    items = items.filter(i => i != item.id)
                } else {
                    items.push(item.id)
                }
            } else {
                item.value = !vl
                if(vl && inArray){
                    items = items.filter(i => i != item.id)
                } else {
                    items.push(item.id)
                }
            }
            this.$emit('input', items)
        }
    },
    mounted() {
        //this.selected = this.filterValue
    }
}
</script>

<style scoped>

</style>