//import http from "./api/http-common";
import httpAuth from "./api/http-auth";

import Auth from "./api/auth";
import Site from './api/site';
import Customize from './api/customize';
import User from './api/user';
import Users from './api/users';
import Units from './api/units';
import UnitsGroups from './api/units-groups';
import UnitsKeys from './api/units-keys';
import UnitsKeysOrders from './api/units-keys-orders';
import Geozones from './api/geozones';
import GeozonesGroups from './api/geozones-groups';
import Geopoints from './api/geopoints';
import GeopointsGroups from './api/geopoints-groups';

import Notifications from './api/notifications';
import NotificationsEvents from './api/notifications-events';

import Trips from './api/trips';
import Parkings from './api/parkings';
import Events from './api/events';
import Stops from './api/stops';
import Tracks from './api/tracks';

import Products from './api/products'
import UnitsHwTypes from './api/units-hw-types'
import Exports from './api/exports';
import Weather from './api/weather'

import development from "@/config/development.json";
import production from "@/config/production.json";
let config = (process.env.NODE_ENV === "production") ? production : development;

// console.log(httpAuth.defaults)
httpAuth.defaults.baseURL = config.api.baseURL;

// Initialize all or API factories
const factories = {
    auth: Auth(httpAuth),
    customize: Customize(httpAuth),
    site: Site(httpAuth),
    user: User(httpAuth),

    exports: Exports(httpAuth),
    products: Products(httpAuth),
    unitshwtypes: UnitsHwTypes(httpAuth),
    weather: Weather(httpAuth),

    users: Users(httpAuth),
    units: Units(httpAuth),
    unitsgroups: UnitsGroups(httpAuth),
    geozones: Geozones(httpAuth),
    geozonesgroups: GeozonesGroups(httpAuth),
    geopoints: Geopoints(httpAuth),
    geopointsgroups: GeopointsGroups(httpAuth),

    notifications: Notifications(httpAuth),

    trips: Trips(httpAuth),
    parkings: Parkings(httpAuth),
    events: Events(httpAuth),
    stops: Stops(httpAuth),
    tracks: Tracks(httpAuth),
    notificationsevents: NotificationsEvents(httpAuth),

    unitskeysorders: UnitsKeysOrders(httpAuth),
    unitskeys: UnitsKeys(httpAuth),
};

// Vue.$api = factories;
// store.$api = factories

export default factories;
