const RESOURCE_NAME = '/';//'/auth/';

export default axios => ({
    login(email, password) {
        return axios.post(`${RESOURCE_NAME}login`, { email, password });
    },
    logout() {
        return axios.get(`${RESOURCE_NAME}logout`);
    },
    register(payload) {
        return axios.post(`${RESOURCE_NAME}register`, payload);
    },
    forgotPassword(email) {
        return axios.post(`${RESOURCE_NAME}password/forgot`, { email });
    },
    resetPassword(password, passwordConfirmation, resetToken) {
        return axios.post(`${RESOURCE_NAME}password/reset`, {
            password: password,
            password_confirmation: passwordConfirmation,
            token: resetToken
        });
    }
});