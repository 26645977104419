const RESOURCE_NAME = '/v1/units-keys-orders';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    getStatistics(params) {
        return axios.get(`${RESOURCE_NAME}/statistics`, {params});
    },
    transfer(data, params) {
        return axios.post(`${RESOURCE_NAME}/transfer`, data, {params});
    },
});